import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import "../../App.css";
import AmazonAd from "../../Components/AmazonAd";
import Footer from "../../Components/Footer";
import NavApp from "../../Components/NavApp";

import { UseUser } from "../../Context/userContext";
import Ajustes from "./Ajustes";
import Artista from "./Artista";
import Favoritos from "./Favoritos";
import Soporte from "./Soporte";

function App() {
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState("artista");
  const { user, setUserContext } = UseUser();
  var width = window.innerWidth;
  useEffect(() => {
    var uu = user;
    firebase.auth().onAuthStateChanged((authUser) => {
      if (!authUser) {
        // console.log("sin usuario");
        uu.isUser = false;
        setUserContext(uu);
        window.location = "/landing";
      } else {
        console.log("ACCESS");

        uu.isUser = true;
        setUserContext(uu);
        setLoading(false);
      }
    });

    var urll = window.location.href;
    if (urll.includes("account#artista")) {
      setContent("artista");
    } else if (urll.includes("account#ajustes")) {
      setContent("ajustes");
    } else if (urll.includes("account#favoritos")) {
      setContent("favoritos");
    } else if (urll.includes("account#soporte")) {
      setContent("soporte");
    }
  }, [window.location.href]);

  useEffect(() => {
    if (!loading) {
      document.getElementById("topp").scrollIntoView();
    }
  }, [loading]);

  return (
    <div>
      {loading ? (
        <div
          className="bg-light d-flex align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <div>
          <NavApp />
          <br id="topp" />

          <Container fluid style={{ marginTop: "3.4rem", width: "100%" }}>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={3}
                lg={2}
                className="mb-0 pb-0 px-0 p-0"
                style={{
                  minHeight: "10rem",
                  // backgroundColor: "#F68D1E",
                }}
              >
                {" "}
                {width > 750 ? (
                  <div
                    className="h-100 w-100 px-3 pt-3 mb-0 pb-0"
                    style={{
                      width: "100%",
                      backgroundColor: "#F68D1E",
                    }}
                  >
                    <div className=" d-flex flex-column align-items-start justify-content-center leftMenu">
                      <Button
                        variant="link"
                        className={`btn btn-link w-100 text-left font-weight-medium p-0 mb-0`}
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.assign("/account#artista");
                          setContent("artista");
                          document.querySelector("title").innerHTML =
                            "Mariiachi.com | Crea tu página de artista";
                          document.getElementById("topp").scrollIntoView();
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <p
                          className={`border-bottom w-100 pb-1 cubic d-flex justify-content-between align-items-center ${
                            content == "artista"
                              ? "shadow-lg text-warning bg-white p-2 br-10"
                              : "text-white"
                          }`}
                        >
                          Página de Artista{" "}
                          {content == "artista" && width > 750 && (
                            <i className="fa-solid fa-chevron-right"></i>
                          )}
                        </p>
                      </Button>
                      <Button
                        variant="link"
                        className={`btn btn-link w-100 text-left font-weight-medium p-0 mb-0`}
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.assign("/account#ajustes");
                          setContent("ajustes");
                          document.querySelector("title").innerHTML = "Mariiachi.com | Ajustes";
                          document.getElementById("topp").scrollIntoView();
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <p
                          className={`border-bottom w-100 pb-1 cubic d-flex justify-content-between align-items-center ${
                            content == "ajustes"
                              ? "shadow-lg text-warning bg-white p-2 br-10"
                              : "text-white"
                          }`}
                        >
                          Ajustes{" "}
                          {content == "ajustes" && width > 750 && (
                            <i className="fa-solid fa-chevron-right"></i>
                          )}
                        </p>
                      </Button>

                      <Button
                        variant="link"
                        className={`btn btn-link w-100 text-left font-weight-medium p-0 mb-0`}
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.assign("/account#favoritos");
                          setContent("favoritos");
                          document.querySelector("title").innerHTML = "Mariiachi.com | Favoritos";
                          document.getElementById("topp").scrollIntoView();
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <p
                          className={`border-bottom w-100 pb-1 cubic d-flex justify-content-between align-items-center ${
                            content == "favoritos"
                              ? "shadow-lg text-warning bg-white p-2 br-10"
                              : "text-white"
                          }`}
                        >
                          Favoritos{" "}
                          {content == "favoritos" && width > 750 && (
                            <i className="fa-solid fa-chevron-right"></i>
                          )}
                        </p>
                      </Button>

                      <Button
                        variant="link"
                        className={`btn btn-link w-100 text-left font-weight-medium p-0 mb-0`}
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.assign("/account#soporte");
                          setContent("soporte");
                          document.querySelector("title").innerHTML = "Mariiachi.com | Soporte";
                          document.getElementById("topp").scrollIntoView();
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <p
                          className={`border-bottom w-100 pb-1 cubic d-flex justify-content-between align-items-center ${
                            content == "soporte"
                              ? "shadow-lg text-warning bg-white p-2 br-10"
                              : "text-white"
                          }`}
                        >
                          Soporte{" "}
                          {content == "soporte" && width > 750 && (
                            <i className="fa-solid fa-chevron-right"></i>
                          )}
                        </p>
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div
                    className="h-100 w-100 px-3 pt-3 mb-0 pb-0"
                    style={{
                      width: "100%",
                      // backgroundColor: "#F68D1E",
                    }}
                  >
                    <div className="w-100 br-10 p-0">
                      <Row className="p-0 m-0">
                        <Col xs={6} className="p-1 m-0 ccenter text-center">
                          <Button
                            variant="link"
                            className={`btn btn-link text-warning w-100 td-none text-center font-weight-medium ccenter p-0 mb-0`}
                            onClick={(e) => {
                              e.preventDefault();
                              window.location.assign("/account#artista");
                              setContent("artista");
                              document.querySelector("title").innerHTML =
                                "Mariiachi.com | Crea tu página de artista";
                              document.getElementById("topp").scrollIntoView();
                            }}
                          >
                            <div className="w-100 ccenter">
                              <p
                                className={`text-center br-30 shadow cubic d-flex flex-column ccenter justify-content-center menuIcon align-items-center mb-1 ${
                                  content == "artista"
                                    ? "text-warning bg-white br-10"
                                    : "text-secondary"
                                }`}
                              >
                                <i className="fa-solid fa-user-music fs-3"></i>
                              </p>
                            </div>
                            Página de Artista
                          </Button>
                        </Col>

                        <Col xs={6} className="p-1 m-0 ccenter text-center">
                          <Button
                            variant="link"
                            className={`btn btn-link text-warning w-100 td-none text-center font-weight-medium ccenter p-0 mb-0`}
                            onClick={(e) => {
                              e.preventDefault();
                              window.location.assign("/account#ajustes");
                              setContent("ajustes");
                              document.querySelector("title").innerHTML = "Mariiachi.com | Ajustes";
                              document.getElementById("topp").scrollIntoView();
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <div className="w-100 ccenter">
                              <p
                                className={`text-center br-30 shadow cubic d-flex flex-column ccenter justify-content-center menuIcon align-items-center mb-1  ${
                                  content == "ajustes"
                                    ? "text-warning bg-white br-10"
                                    : "text-secondary"
                                }`}
                              >
                                <i className="fa-solid fa-gear fs-3 "></i>
                              </p>
                            </div>
                            Ajustes
                          </Button>
                        </Col>

                        <Col xs={6} className="p-1 m-0 ccenter text-center">
                          <Button
                            variant="link"
                            className={`btn btn-link text-warning w-100 td-none text-center font-weight-medium ccenter p-0 mb-0`}
                            onClick={(e) => {
                              e.preventDefault();
                              window.location.assign("/account#favoritos");
                              setContent("favoritos");
                              document.querySelector("title").innerHTML =
                                "Mariiachi.com | Favoritos";
                              document.getElementById("topp").scrollIntoView();
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <div className="w-100 ccenter">
                              <p
                                className={`text-center br-30 shadow cubic d-flex flex-column ccenter justify-content-center menuIcon align-items-center mb-1 ${
                                  content == "favoritos"
                                    ? " text-warning bg-white  br-10"
                                    : "text-secondary"
                                }`}
                              >
                                <i className="fa-solid fa-circle-star fs-3 "></i>
                              </p>
                            </div>
                            Favoritos
                          </Button>
                        </Col>

                        <Col xs={6} className="p-1 m-0 ccenter text-center">
                          <Button
                            variant="link"
                            className={`btn btn-link text-warning w-100 td-none text-center font-weight-medium ccenter p-0 mb-0`}
                            onClick={(e) => {
                              e.preventDefault();
                              window.location.assign("/account#soporte");
                              setContent("soporte");
                              document.querySelector("title").innerHTML = "Mariiachi.com | Soporte";
                              document.getElementById("topp").scrollIntoView();
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <div className="w-100 ccenter">
                              <p
                                className={`text-center br-30 shadow cubic d-flex flex-column ccenter justify-content-center menuIcon align-items-center mb-1 ${
                                  content == "soporte"
                                    ? "text-warning bg-white br-10"
                                    : "text-secondary"
                                }`}
                              >
                                <i className="fa-solid fa-headset fs-3"></i>
                              </p>
                            </div>
                            Soporte
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
              </Col>

              <Col xs={12} sm={12} md={9} lg={8} className="mh-100 px-0 p-0 pt-0 br-10">
                <br />
                {content == "ajustes" && (
                  // <Card className="border-none shadow-lg px-2 py-4 br-15 mb-3">
                  <Ajustes />
                  // </Card>
                )}
                {content == "artista" && (
                  // <Card className="border-none shadow-lg px-1 py-4 br-15 mb-3">
                  <Artista />
                  // </Card>
                )}
                {content == "favoritos" && (
                  // <Card className="border-none shadow-lg px-1 py-4 br-15 mb-3">
                  <Favoritos />
                  // {/* </Card> */}
                )}
                {content == "soporte" && (
                  // <Card className="border-none shadow-lg px-2 py-4 br-15 mb-3">
                  <Soporte />
                  // </Card>
                )}
              </Col>

              <Col xs={12} sm={12} md={12} lg={2} style={{ minHeight: "15rem" }}>
                <AmazonAd />
                {/* <AmazonAd /> */}

                {/* <script
                  async
                  src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7829138792197434"
                  crossorigin="anonymous"
                ></script>
                 <!-- square --> 
                <ins
                  className="adsbygoogle"
                  style={{display: "block"}}
                  data-ad-client="ca-pub-7829138792197434"
                  data-ad-slot="2083949694"
                  data-ad-format="auto"
                  data-full-width-responsive="true"
                ></ins>
                <script>
                  (adsbygoogle = window.adsbygoogle || []).push({});
                </script>

                <script
                  async
                  src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7829138792197434"
                  crossorigin="anonymous"
                ></script>
                 <!-- square --> 
                <ins
                  className="adsbygoogle"
                  style={{display: "block"}}
                  data-ad-client="ca-pub-7829138792197434"
                  data-ad-slot="2083949694"
                  data-ad-format="auto"
                  data-full-width-responsive="true"
                ></ins>
                <script>
                  (adsbygoogle = window.adsbygoogle || []).push({});
                </script> */}
              </Col>
            </Row>
          </Container>

          <Footer />
        </div>
      )}
    </div>
  );
}

export default App;
