import Compressor from "compressorjs";
import ReactECharts from "echarts-for-react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import html2canvas from "html2canvas";

import { QRCodeSVG } from "qrcode.react";
import { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Link } from "react-router-dom";
import "../../App.css";
import card from "../../assets/img/card.jpg";
import saveAs from "../../assets/img/guarda.jpeg";
import logo from "../../assets/img/logo.png";

import { Country, State } from "country-state-city";
import {
  Badge,
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Modal,
  Nav,
  Overlay,
  Row,
  Spinner,
  Tab,
  Tooltip,
} from "react-bootstrap";

function Artista() {
  const [downloaded, setDownloaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [instruments, setInstruments] = useState([]);
  const [genres, setGenres] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [countriesValues, setCountriesValues] = useState([]);
  const [statesValues, setStatesValues] = useState([]);
  const [currency, setCurrency] = useState("");
  const [cCode, setCcode] = useState("");
  const [cName, setCname] = useState("");
  const [sName, setSname] = useState("");
  const [convenir, setConvenir] = useState(true);
  const [price, setPrice] = useState(0);
  const [fans, setFans] = useState([]);
  const [artistName, setArtistName] = useState("");
  const [desc, setDesc] = useState("");
  const [tel, settel] = useState("");
  const [emailcont, setEmailCont] = useState("");
  const [fb, setFb] = useState("");
  const [ig, setIg] = useState("");
  const [yt, setYt] = useState("");
  const [sp, setSp] = useState("");
  const [sc, setSc] = useState("");
  var width = window.innerWidth;
  const [ytVid, setYtVid] = useState("");
  const [artistUid, setArtistUid] = useState("");
  const [active, setActive] = useState();
  const [activeLoading, setActiveLoading] = useState(false);
  const [picUrl, setPicUrl] = useState();
  const [change, setChange] = useState(false);
  const [artDis, setArtDis] = useState(true);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const target = useRef(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [cardOpen, setCardOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [rev, setReverse] = useState(true);
  const [fron, setFront] = useState(true);
  const [withPic, setWithPic] = useState(true);
  const [visitData, setVisitData] = useState(null);
  const [labelData, setLabelData] = useState(null);
  const [shareData, setShareData] = useState(null);
  const [contactData, setContactData] = useState(null);
  const [shortUrl, setShortUrl] = useState("");
  const options = {
    legend: {
      data: ["Visitas", "Contactos", "Compartidos"],
    },
    grid: { top: 36, right: 8, bottom: 24, left: 36 },
    xAxis: {
      type: "category",
      data: labelData,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "Visitas",
        data: visitData,
        type: "line",
        smooth: true,
      },
      {
        name: "Contactos",
        data: contactData,
        type: "line",
        smooth: true,
      },
      {
        name: "Compartidos",
        data: shareData,
        type: "line",
        smooth: true,
      },
    ],
    tooltip: {
      trigger: "axis",
    },
  };

  function copyLink() {
    setArtDis(false);
    const elem = document.querySelector("#artistId");
    elem.select();
    document.execCommand("copy");
    setTooltipOpen(true);
    setArtDis(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 2500);
  }

  const removeHttpIg = (link1) => {
    var newLink = "";

    if (link1.includes("instagram.com")) {
      if (link1.includes("https://")) {
        // console.log("ssss");
        newLink = link1.replace("https://", "");
        // console.log(newLink);
        return newLink;
      } else if (link1.includes("http://")) {
        newLink = link1.replace("http://", "");
        return newLink;
      } else {
        return link1;
      }
    } else {
      setIg("");
      setAlertMsg("El link de la página de instagram, no es de instagram");
      setAlertOpen(true);
      return null;
    }
  };
  const removeHttpFb = (link1) => {
    var newLink = "";

    if (link1.includes("facebook.com")) {
      if (link1.includes("https://")) {
        // console.log("ssss");
        newLink = link1.replace("https://", "");
        // console.log(newLink);
        return newLink;
      } else if (link1.includes("http://")) {
        newLink = link1.replace("http://", "");
        return newLink;
      } else if (link1.includes("https://m.")) {
        newLink = link1.replace("https://m.", "");
        return newLink;
      } else {
        return link1;
      }
    } else {
      setFb("");
      setAlertMsg("El link de la página de facebook, no es de facebook");
      setAlertOpen(true);
      return null;
    }
  };

  const removeHttpSp = (link1) => {
    var newLink = "";

    if (link1.includes("spotify.com")) {
      if (link1.includes("https://")) {
        // console.log("ssss");
        newLink = link1.replace("https://", "");
        // console.log(newLink);
        return newLink;
      } else if (link1.includes("http://")) {
        newLink = link1.replace("http://", "");
        return newLink;
      } else {
        return link1;
      }
    } else {
      setFb("");
      setAlertMsg("El link de la página de spotify, no es de spotify");
      setAlertOpen(true);
      return null;
    }
  };

  const removeHttpSc = (link1) => {
    var newLink = "";

    if (link1.includes("soundcloud.com")) {
      if (link1.includes("https://")) {
        // console.log("ssss");
        newLink = link1.replace("https://", "");
        // console.log(newLink);
        return newLink;
      } else if (link1.includes("http://")) {
        newLink = link1.replace("http://", "");
        return newLink;
      } else {
        return link1;
      }
    } else {
      setFb("");
      setAlertMsg("El link de la página de soundcloud, no es de soundcloud");
      setAlertOpen(true);
      return null;
    }
  };

  const removeHttpYt = (link1) => {
    var newLink = "";

    if (link1.includes("youtube.com") || link1.includes("youtu.be")) {
      if (link1.includes("https://")) {
        // console.log("ssss");
        newLink = link1.replace("https://", "");
        // console.log(newLink);
        return newLink;
      } else if (link1.includes("http://")) {
        newLink = link1.replace("http://", "");
        return newLink;
      } else {
        return link1;
      }
    } else {
      setYtVid("");
      setAlertMsg("El video musical tiene que ser de youtube");
      setAlertOpen(true);
      return null;
    }
  };
  const setPlayInst = (instrument, value) => {
    var array = instruments;

    array.forEach((inst, index) => {
      if (inst.instrument == instrument) {
        array[index].plays = !array[index].plays;
      }
    });
    // console.log(array);
    setInstruments(array);
    // setChange(!change);
  };

  const setPlayGenre = (genre1, value) => {
    var array = genres;

    array.forEach((genre, index) => {
      if (genre.genre === genre1) {
        array[index].plays = !array[index].plays;
      }
    });
    setGenres(array);
    // setChange(!change);
  };

  const selectCountry = (index, name, code, state, curr) => {
    var fetchedStates = State.getStatesOfCountry(code);
    setCurrency(curr);
    setCcode(code);
    setCname(name);
    var values = countriesValues;
    if (values[index] === true) {
      values[index] = false;
    } else {
      values[index] = true;
    }
    values.forEach((val, ind) => {
      if (ind != index) {
        values[ind] = false;
      }
    });
    setCountriesValues(values);
    setStates(fetchedStates);
    if (state != undefined) {
      var stateVal = [];
      fetchedStates.forEach((el, indd) => {
        if (el.name == state) {
          stateVal.push(true);
          setSname(state);
        } else {
          stateVal.push(false);
        }
      });
      setStatesValues(stateVal);
    }
  };

  const selectState = (index, name, code, value) => {
    setSname(name);
    var stateVal = [];
    states.forEach((el, ind) => {
      if (ind != index) {
        stateVal.push(false);
      } else {
        stateVal.push(true);
      }
    });

    setStatesValues(stateVal);
  };

  const validateSize = (file) => {
    // let file = event.target.files[0];
    let size = 550000;
    let err = "";
    let types = ["image/jpeg", "image/png", "image/jpg"];
    console.log(file);

    if (types.includes(file.type)) {
      if (file.size > size) {
        err = "La imagen es muy grande, excede los 550 kB, por favor elige una imagen más chica";
        setAlertMsg(err);
        setAlertOpen(true);
        return false;
      }
    } else {
      setAlertMsg(
        "El tipo de imagen que ingresaste no es aceptado. Puedes subir archivos de los tipos jpeg, jpg, png o gif."
      );
      setAlertOpen(true);
      return false;
    }

    return true;
  };

  const setPP = (event) => {
    setLoading(true);
    var storageRef = firebase.storage().ref();

    var artistsRef = storageRef.child("artistas");
    var artistRef = artistsRef.child(user.uid);

    const profilePicRef = artistRef.child(`${user.uid}-logo.jpg`);

    var ppUrl = "";

    new Compressor(event, {
      quality: 0.2,
      success: (res) => {
        profilePicRef
          .put(res)
          .then(async (snapshot) => {
            ppUrl = await snapshot.ref.getDownloadURL();
            console.log("picUrl: ", ppUrl);
            setPicUrl(ppUrl);
          })
          .then(() => {
            setDownloaded(false);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
            setAlertMsg(error.message);
            setAlertOpen(true);
          });
      },
    });
  };

  const deletePp = () => {
    setLoading(true);
    firebase
      .firestore()
      .collection("artists")
      .doc(user.uid)
      .update({
        pp: "",
      })
      .then(() => {
        setDownloaded(false);
        setChange(!change);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        setAlertMsg(error.message);
        setAlertOpen(true);
      });
  };

  const toggleActive = () => {
    setActiveLoading(true);
    var val = active;
    firebase
      .firestore()
      .collection("artists")
      .doc(user.uid)
      .set(
        {
          status: !val,
        },
        { merge: true }
      )
      .then(() => {
        setActive(!val);
        setActiveLoading(false);
      })
      .catch((error) => {
        setActiveLoading(false);
        console.error(error);
        setAlertMsg(error.message);
        setAlertOpen(true);
      });
  };

  const setPage = () => {
    var thisInstr = [];
    var trueInst = false;
    var trueGenre = false;
    instruments.forEach((element, ind) => {
      if (element.plays == true) {
        thisInstr.push(element.instrument);
        trueInst = true;
      }
    });
    var thisGenre = [];
    genres.forEach((element, ind) => {
      if (element.plays == true) {
        thisGenre.push(element.genre);
        trueGenre = true;
      }
    });

    if (states.length != null && states.length != undefined && states.length > 0) {
      if (artistName && desc && tel && trueInst && trueGenre && cName && sName != "") {
        setLoading(true);
        if (user.artist != true) {
          firebase
            .firestore()
            .collection("artists")
            .doc(user.uid)
            .set(
              {
                uid: user.uid,
                name: artistName ? artistName : null,
                description: desc ? desc : null,
                telephone: tel ? tel : null,
                email: emailcont ? emailcont : null,
                facebook: fb ? removeHttpFb(fb) : null,
                instagram: ig ? removeHttpIg(ig) : null,
                youtube: yt ? removeHttpYt(yt) : null,
                spotify: sp ? removeHttpSp(sp) : null,
                soundcloud: sc ? removeHttpSc(sc) : null,
                video: ytVid ? removeHttpYt(ytVid) : null,
                instruments: thisInstr,
                genres: thisGenre,
                country: cName ? cName : null,
                state: sName ? sName : null,
                price: price ? price : 0,
                arrange: convenir ? convenir : false,
                currency: currency ? currency : null,
                pp: picUrl ? picUrl : "",
                status: true,
                blocked: false,
                fanCount: 0,
                fans: [],
                dateAdded: firebase.firestore.FieldValue.serverTimestamp(),
              },
              { merge: true }
            )
            .then(() => {
              genShortUrl(user.uid);
            })
            .then(() => {
              firebase.firestore().collection("users").doc(user.uid).set(
                {
                  artist: true,
                },
                { merge: true }
              );
              var u = user;
              u.artist = true;
              setAlertMsg("¡Información guardada correctamente!");
              setAlertOpen(true);
              setUser(u);
              setActive(true);
              setLoading(false);
            })
            .catch((error) => {
              setLoading(false);
              console.error(error);
              setAlertMsg(error.message);
              setAlertOpen(true);
            });
        } else {
          firebase
            .firestore()
            .collection("artists")
            .doc(user.uid)
            .set(
              {
                uid: user.uid,
                name: artistName ? artistName : null,
                description: desc ? desc : null,
                telephone: tel ? tel : null,
                email: emailcont ? emailcont : null,
                facebook: fb ? removeHttpFb(fb) : null,
                instagram: ig ? removeHttpIg(ig) : null,
                youtube: yt ? removeHttpYt(yt) : null,
                spotify: sp ? removeHttpSp(sp) : null,
                soundcloud: sc ? removeHttpSc(sc) : null,
                video: ytVid ? removeHttpYt(ytVid) : null,
                instruments: thisInstr,
                genres: thisGenre,
                country: cName ? cName : null,
                state: sName ? sName : null,
                price: price ? price : 0,
                arrange: convenir ? convenir : false,
                currency: currency ? currency : null,
                pp: picUrl ? picUrl : "",
                status: true,
              },
              { merge: true }
            )
            .then(() => {
              setAlertMsg("¡Información guardada correctamente!");
              setAlertOpen(true);
              setActive(true);
              setLoading(false);
            })

            .catch((error) => {
              setLoading(false);
              console.error(error);
              setAlertMsg(error.message);
              setAlertOpen(true);
            });
        }
      } else {
        setAlertMsg(
          "Por favor, asegúrate de llenar los siguientes campos: Nombre, Descripción, País, Estado, Telefono, 1 Instrumento y 1 Género musical."
        );
        setAlertOpen(true);
      }
    } else {
      if (artistName && desc && tel && trueInst && trueGenre && cName) {
        setLoading(true);
        if (user.artist != true) {
          firebase
            .firestore()
            .collection("artists")
            .doc(user.uid)
            .set(
              {
                uid: user.uid,
                name: artistName ? artistName : null,
                description: desc ? desc : null,
                telephone: tel ? tel : null,
                email: emailcont ? emailcont : null,
                facebook: fb ? removeHttpFb(fb) : null,
                instagram: ig ? removeHttpIg(ig) : null,
                youtube: yt ? removeHttpYt(yt) : null,
                spotify: sp ? removeHttpSp(sp) : null,
                soundcloud: sc ? removeHttpSc(sc) : null,
                video: ytVid ? removeHttpYt(ytVid) : null,
                instruments: thisInstr,
                genres: thisGenre,
                country: cName ? cName : null,
                state: sName ? sName : null,
                price: price ? price : 0,
                arrange: convenir ? convenir : false,
                currency: currency ? currency : null,
                status: true,
                fanCount: 0,
                fans: [],
                dateAdded: firebase.firestore.FieldValue.serverTimestamp(),
              },
              { merge: true }
            )
            .then(() => {
              firebase.firestore().collection("users").doc(user.uid).set(
                {
                  artist: true,
                },
                { merge: true }
              );
              var u = user;
              u.artist = true;
              setAlertMsg("¡Información guardada correctamente!");
              setAlertOpen(true);
              setUser(u);
              setActive(true);
              setLoading(false);
            })
            .catch((error) => {
              setLoading(false);
              console.error(error);
              setAlertMsg(error.message);
              setAlertOpen(true);
            });
        } else {
          firebase
            .firestore()
            .collection("artists")
            .doc(user.uid)
            .set(
              {
                uid: user.uid,
                name: artistName ? artistName : null,
                description: desc ? desc : null,
                telephone: tel ? tel : null,
                email: emailcont ? emailcont : null,
                facebook: fb ? removeHttpFb(fb) : null,
                instagram: ig ? removeHttpIg(ig) : null,
                youtube: yt ? removeHttpYt(yt) : null,
                spotify: sp ? removeHttpSp(sp) : null,
                soundcloud: sc ? removeHttpSc(sc) : null,
                video: ytVid ? removeHttpYt(ytVid) : null,
                instruments: thisInstr,
                genres: thisGenre,
                country: cName ? cName : null,
                state: sName ? sName : null,
                price: price ? price : 0,
                arrange: convenir ? convenir : false,
                currency: currency ? currency : null,
                status: true,
              },
              { merge: true }
            )
            .then(() => {
              setAlertMsg("¡Información guardada correctamente!");
              setAlertOpen(true);
              setActive(true);
              setLoading(false);
            })
            .catch((error) => {
              setLoading(false);
              console.error(error);
              setAlertMsg(error.message);
              setAlertOpen(true);
            });
        }
      } else {
        setAlertMsg(
          "Por favor, asegúrate de llenar los siguientes campos: Nombre, Descripción, País, Telefono, 1 Instrumento y 1 Género musical."
        );
        setAlertOpen(true);
      }
    }
  };

  function showImageCard() {
    let divR = document.getElementById("containerReverse");
    let divF = document.getElementById("containerFront");
    let imgLink = document.getElementById("output2");
    imgLink.innerHTML = "";
    let imgLinkk = document.getElementById("output1");
    imgLinkk.innerHTML = "";
    html2canvas(divR, {
      allowTaint: true,
    })
      .then(function (canvas) {
        var tag2 = document.createElement("p");
        var text2 = document.createTextNode("(Reverso)");
        tag2.appendChild(text2);
        imgLink.appendChild(tag2);
        imgLink.appendChild(canvas);
      })
      .then(() => {
        setReverse(false);
      });

    html2canvas(divF, {
      allowTaint: true,
    })
      .then(function (canvas2) {
        var tag = document.createElement("p");
        var text = document.createTextNode("(Frente)");
        tag.appendChild(text);
        imgLinkk.appendChild(tag);
        imgLinkk.appendChild(canvas2);
      })
      .then(() => {
        setFront(false);
      });
  }

  const getStats = (uid) => {
    var visitsArr = [];
    var sharesArr = [];
    var contactArr = [];
    var titleArr = [];
    firebase
      .firestore()
      .collection("artists")
      .doc(uid)
      .collection("stats")
      .orderBy("date", "desc")
      .limit(28)
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          visitsArr.unshift(doc.data().pageVisits);
          sharesArr.unshift(doc.data().pageShares);
          contactArr.unshift(doc.data().pageContacts);
          titleArr.unshift(doc.data().dateTitle);
        });
        setVisitData(visitsArr);
        setLabelData(titleArr);
        setShareData(sharesArr);
        setContactData(contactArr);
      })
      .catch((er) => {
        console.log(er);
      });
  };

  const genShortUrl = (uid) => {
    const nanid = uid.slice(-7);
    // console.log("nanid", nanid);
    firebase
      .firestore()
      .collection("shortUrls")
      .doc(nanid)
      .set({
        short: nanid,
        to: uid,
      })
      .then(() => {
        firebase
          .firestore()
          .collection("artists")
          .doc(uid)
          .set({ shortUrl: nanid }, { merge: true });
      })
      .then(() => {
        return nanid;
      });
  };

  useEffect(() => {
    setLoading(true);
    var fetInst = [];
    var fetGen = [];
    var fetState = "";
    var fetCount = "";
    firebase.auth().onAuthStateChanged((authUser) => {
      if (!authUser) {
        console.log("sin usuario");
        window.location = "/landing";
      } else {
        setArtistUid(authUser.uid);

        firebase
          .firestore()
          .collection("users")
          .doc(authUser.uid)
          .get()
          .then((doc) => {
            setUser(doc.data());
            if (doc.data().artist != true) {
              firebase
                .firestore()
                .collection("artists")
                .doc(authUser.uid)
                .get()
                .then((docc) => {
                  if (docc.exists) {
                    // if (docc.data().pp != "") {
                    setPicUrl(docc.data().pp);
                    // }
                  } else {
                    setLoading(false);
                  }
                })
                .then(() => {
                  setLoading(false);
                })
                .catch((er) => {
                  console.log(er);
                });
            }
            if (doc.data().artist == true && downloaded == false) {
              firebase
                .firestore()
                .collection("artists")
                .doc(doc.data().uid)
                .get()
                .then((page) => {
                  setArtistName(page.data().name);
                  setDesc(page.data().description);
                  settel(page.data().telephone);
                  setEmailCont(page.data().email);
                  setFb(page.data().facebook);
                  setIg(page.data().instagram);
                  setYt(page.data().youtube);
                  setYtVid(page.data().video);
                  setSp(page.data().spotify);
                  setSc(page.data().soundcloud);
                  setActive(page.data().status);
                  setFans(page.data().fans ? page.data().fans : []);
                  fetInst = page.data().instruments;
                  fetGen = page.data().genres;
                  setPicUrl(page.data().pp);
                  fetCount = page.data().country;
                  fetState = page.data().state;
                  setPrice(page.data().price);
                  setConvenir(page.data().arrange);
                  setShortUrl(
                    page.data().shortUrl ? page.data().shortUrl : genShortUrl(authUser.uid)
                  );
                })
                .then(() => {
                  getStats(authUser.uid);
                })
                .then(() => {
                  firebase
                    .firestore()
                    .collection("instruments")
                    .doc("instruments")
                    .get()
                    .then((doc) => {
                      var totInst = [];
                      doc.data().instruments.forEach((elem) => {
                        var instObj = new Object();
                        instObj.instrument = elem;
                        instObj.plays = false;
                        totInst.push(instObj);
                      });
                      if (fetInst != null && fetInst != undefined) {
                        fetInst.forEach((fet, ind) => {
                          totInst.forEach((inst, inde) => {
                            if (inst.instrument == fet) {
                              totInst[inde].plays = true;
                            }
                          });
                        });
                      }
                      setInstruments(
                        totInst.sort((a, b) =>
                          a.instrument !== b.instrument ? (a.instrument < b.instrument ? -1 : 1) : 0
                        )
                      );
                    });
                })
                .then(() => {
                  firebase
                    .firestore()
                    .collection("genres")
                    .doc("genres")
                    .get()
                    .then((doc) => {
                      var totGenres = [];
                      doc.data().genres.forEach((elem) => {
                        var genreObj = new Object();
                        genreObj.genre = elem;
                        genreObj.plays = false;
                        totGenres.push(genreObj);
                      });

                      if (fetGen != null && fetGen != undefined) {
                        fetGen.forEach((fet, ind) => {
                          totGenres.forEach((gen, inde) => {
                            if (gen.genre == fet) {
                              totGenres[inde].plays = true;
                            }
                          });
                        });
                      }

                      setGenres(
                        totGenres.sort((a, b) =>
                          a.genre !== b.genre ? (a.genre < b.genre ? -1 : 1) : 0
                        )
                      );
                    });
                })
                .then(() => {
                  var cvalues = [];
                  var countr = Country.getAllCountries();
                  setCountries(countr);
                  countr.forEach((el, ind) => {
                    if (el.name == fetCount) {
                      selectCountry(ind, el.name, el.isoCode, fetState, el.currency);
                      cvalues.push(true);
                    } else {
                      cvalues.push(false);
                    }
                  });
                  setCountriesValues(cvalues);
                  setLoading(false);
                  setDownloaded(true);
                });
            } else {
              if (downloaded == false) {
                firebase
                  .firestore()
                  .collection("instruments")
                  .doc("instruments")
                  .get()
                  .then((doc) => {
                    var totInst = [];
                    doc.data().instruments.forEach((elem) => {
                      var instObj = new Object();
                      instObj.instrument = elem;
                      instObj.plays = false;
                      totInst.push(instObj);
                    });
                    if (fetInst.length >= 1) {
                      fetInst.forEach((fet, ind) => {
                        totInst.forEach((inst, inde) => {
                          if (inst.instrument == fet) {
                            totInst[inde].plays = true;
                          }
                        });
                      });
                    }
                    setInstruments(
                      totInst.sort((a, b) =>
                        a.instrument !== b.instrument ? (a.instrument < b.instrument ? -1 : 1) : 0
                      )
                    );
                  })
                  .then(() => {
                    firebase
                      .firestore()
                      .collection("genres")
                      .doc("genres")
                      .get()
                      .then((doc) => {
                        var totGenres = [];
                        doc.data().genres.forEach((elem) => {
                          var genreObj = new Object();
                          genreObj.genre = elem;
                          genreObj.plays = false;
                          totGenres.push(genreObj);
                        });

                        if (fetGen.length >= 1) {
                          fetGen.forEach((fet, ind) => {
                            totGenres.forEach((gen, inde) => {
                              if (gen.genre == fet) {
                                totGenres[inde].plays = true;
                              }
                            });
                          });
                        }
                        setGenres(
                          totGenres.sort((a, b) =>
                            a.genre !== b.genre ? (a.genre < b.genre ? -1 : 1) : 0
                          )
                        );
                      });
                  })
                  .then(() => {
                    var cvalues = [];
                    var countr = Country.getAllCountries();
                    setCountries(countr);
                    countr.forEach((el, ind) => {
                      if (el.name == fetCount) {
                        selectCountry(ind, el.name, el.isoCode, fetState, el.currency);
                        cvalues.push(true);
                      } else {
                        cvalues.push(false);
                      }
                    });
                    setCountriesValues(cvalues);
                    setLoading(false);
                    setDownloaded(true);
                  });
              }
            }
          })

          .catch((err) => {
            console.log(err);
            setLoading(false);
            setDownloaded(true);
          });
      }
    });
  }, [change]);

  return (
    <div className="w-100 d-flex flex-column align-items-center justify-content-center p-0 px-0 pt-0 mt-0">
      <Modal
        show={cardOpen}
        onHide={() => setCardOpen(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter br-15"
        centered
      >
        <Modal.Header>
          <div className="d-flex w-100 align-items-center justify-content-between px-1">
            <h4 className="w-100 text-black">Tu Tarjeta de Presentación</h4>
            <Button
              variant="link"
              className="td-none"
              onClick={(e) => {
                e.preventDefault();
                setCardOpen(false);
              }}
            >
              <i className="fa-solid fa-xmark-large text-warning text-center fs-1"></i>
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center">
            {fron && rev && (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  position: "absolute",
                  width: "100%",
                  top: "0px",
                  left: "0px",
                  height: "100%",
                  backgroundColor: "white",
                  zIndex: 9999,
                }}
              >
                <Spinner animation="grow" variant="warning" />
              </div>
            )}

            {!fron && !rev && (
              <Row className="border-bottom mb-2">
                <Col xs={12} lg={6}>
                  <div>
                    <p>¿Cómo descargar las imágenes?</p>
                    <ol>
                      <li>Da click derecho en la imagen o mantén pulsado</li>
                      <li>Da click en "guardar imagen cómo" o "Save image As"</li>
                    </ol>
                  </div>
                </Col>
                <Col xs={12} lg={6} className="d-flex align-items-center justify-content-center">
                  <img src={saveAs} style={{ width: "10rem" }} />
                </Col>
              </Row>
            )}
            {fron && rev && (
              <div>
                <Row>
                  <Col xs={12} lg={6}>
                    {picUrl && withPic ? (
                      <div
                        id="containerFront"
                        className="shadow-lg d-flex flex-row"
                        style={{
                          width: "9cm",
                          height: "5cm",
                          position: "relative",
                          backgroundColor: "#1E0031",
                        }}
                      >
                        <div className="p-2 text-white" style={{ width: "55%" }}>
                          <h5 className="text-warning">{artistName}</h5>
                          <div className="mt-2" />
                          Música de
                          <br />
                          <div>
                            {genres.map((el, ind) => {
                              if (ind <= 3 && el.plays) {
                                return (
                                  <Badge pill bg="danger" text="white" className="mr-1">
                                    {el.genre}
                                  </Badge>
                                );
                              } else if (ind === 4 && el.plays) {
                                return (
                                  <Badge pill bg="danger" text="white" className="mr-1">
                                    Y más
                                  </Badge>
                                );
                              }
                            })}
                          </div>
                          <div className="mt-2" />
                          <span style={{ fontSize: "10px !important" }}>
                            <i
                              className="fa-solid fa-phone"
                              style={{
                                // fontSize: "0.rem",
                                width: "auto",
                                color: "#f5803e",
                              }}
                            ></i>{" "}
                          </span>
                          <span
                            className="font-weight-semi-bold text-white"
                            style={{
                              fontSize: "5px !important",
                              fontFamily: "monospace",
                              letterSpacing: "1.5px",
                            }}
                          >
                            {tel}
                          </span>
                          <br></br>
                        </div>
                        <div
                          style={{
                            width: "45%",
                            backgroundImage: `url(${picUrl})`,
                            backgroundPosition: "center",
                            height: "100%",
                            backgroundSize: "cover",
                            position: "relative",
                          }}
                        >
                          <div
                            className="d-flex align-items-end justify-content-end"
                            style={{
                              position: "absolute",
                              right: "50%",
                              bottom: "10px",
                              transform: "translateX(50%)",
                            }}
                          >
                            <img src={logo} style={{ width: "auto", height: "1.2rem" }}></img>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        id="containerFront"
                        className="p-3 shadow-lg"
                        style={{
                          width: "9cm",
                          height: "5cm",
                          position: "relative",
                          backgroundColor: "#1E0031",
                        }}
                      >
                        <div className="text-white" style={{ width: "70%" }}>
                          <h5 className="text-warning">{artistName}</h5>
                          Música de
                          <br />
                          {genres.map((el, ind) => {
                            if (ind <= 3 && el.plays) {
                              return (
                                <Badge pill bg="danger" text="white" className="mr-1">
                                  {el.genre}
                                </Badge>
                              );
                            } else if (ind === 4 && el.plays) {
                              return (
                                <Badge pill bg="danger" text="white" className="mr-1">
                                  Y más
                                </Badge>
                              );
                            }
                          })}
                          <div className="mt-2" />
                          <span className="mt-2" style={{ fontSize: "10px !important" }}>
                            <i
                              className="fa-solid fa-phone"
                              style={{
                                // fontSize: "0.rem",
                                width: "auto",
                                color: "#f5803e",
                              }}
                            ></i>{" "}
                          </span>
                          <span
                            className="font-weight-semi-bold text-white"
                            style={{
                              fontSize: "8px !important",
                              font: "sans-serif",
                              letterSpacing: "1.5px",
                            }}
                          >
                            {tel}
                          </span>
                          <br></br>
                        </div>

                        <div
                          style={{
                            width: "30%",
                            position: "absolute",
                            right: "0rem",
                            bottom: "0px",
                          }}
                        >
                          <img src={card} style={{ height: "auto", width: "100%" }}></img>
                        </div>
                        <div
                          className="d-flex align-items-end justify-content-end"
                          style={{
                            position: "absolute",
                            right: "1.3rem",
                            bottom: "10px",
                          }}
                        >
                          <img src={logo} style={{ width: "auto", height: "1.2rem" }}></img>
                        </div>
                      </div>
                    )}
                  </Col>

                  <Col xs={12} lg={6}>
                    <div
                      id="containerReverse"
                      className="bg-light shadow-lg d-flex flex-row"
                      style={{
                        width: "9cm",
                        height: "5cm",
                        position: "relative",
                      }}
                    >
                      <div
                        className="h-100 d-flex flex-column align-items-center justify-content-center"
                        style={{ width: "50%", position: "relative" }}
                      >
                        <QRCodeSVG
                          value={`app.mariiachi.com/artist/${artistUid}`}
                          style={{ maxHeight: "90%" }}
                        />
                        <span
                          className="font-weight-bold "
                          style={{
                            fontSize: "8px !important",
                            font: "sans-serif",
                            width: "auto",
                            letterSpacing: "0.4px",
                          }}
                        >
                          Visita mi página
                        </span>
                      </div>

                      <div
                        style={{
                          width: "50%",
                          position: "relative",
                        }}
                      >
                        <div className="d-flex flex-column h-100 w-100 align-items-center justify-content-center">
                          <span
                            className="font-weight-bold "
                            style={{
                              fontSize: "8px !important",
                              font: "sans-serif",
                              letterSpacing: "0.4px",
                            }}
                          >
                            Encuéntrame{"  "}en
                          </span>
                          <img src={logo} style={{ width: "auto", height: "1.7rem" }}></img>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
            {picUrl && (
              <ButtonGroup>
                <Button
                  id={`radio-1`}
                  variant={withPic ? "warning" : "outline-warning"}
                  name="radio"
                  onClick={(e) => {
                    e.preventDefault();
                    setWithPic(true);
                    setReverse(true);
                    setFront(true);
                    setTimeout(() => {
                      showImageCard();
                    }, [200]);
                  }}
                >
                  Con foto
                </Button>

                <Button
                  id={`radio-1`}
                  // type="radio"
                  variant={!withPic ? "warning" : "outline-warning"}
                  name="radio"
                  // value={withPic}
                  // checked={!withPic}
                  onClick={(e) => {
                    e.preventDefault();
                    setWithPic(false);
                    setReverse(true);
                    setFront(true);
                    setTimeout(() => {
                      showImageCard();
                    }, [200]);
                  }}
                >
                  Sin foto
                </Button>
              </ButtonGroup>
            )}
            <Row className="mt-2">
              <Col xs={12} lg={6}>
                <div id="output1" className=""></div>
              </Col>

              <Col xs={12} lg={6}>
                <div id="output2" className=""></div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={alertOpen}
        onHide={() => setAlertOpen(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter br-15"
        centered
      >
        <Modal.Header className="text-center">
          <h4 className="w-100 text-center">AVISO</h4>
        </Modal.Header>
        <Modal.Body>
          <p className="px-4 text-center fs-1 text-weight-semi-bold">{alertMsg}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setAlertOpen(false);
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      {loading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <Tab.Container id="left-tabs-example" defaultActiveKey="info">
          <Row className="w-100 h-100 pt-0 mt-0">
            {user.artist == true && (
              <Col xs={12} sm={12} lg={3} className="w-100 h-100 p-0 px-0">
                {width > 750 ? (
                  <div className="px-2">
                    <div className="leftMenu">
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="info"
                            href="#"
                            className="p-2 br-10 arNav d-flex align-items-center justify-content-between"
                          >
                            Información de Artista{" "}
                            <i className="fa-solid fa-chevron-right text-white"></i>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="comparte"
                            disabled={!user.artist}
                            href="#"
                            className="p-2 br-10 arNav d-flex align-items-center justify-content-between"
                          >
                            Comparte
                            <i className="fa-solid fa-chevron-right text-white"></i>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="tarjeta"
                            disabled={!user.artist}
                            href="#"
                            className="p-2 br-10 arNav d-flex align-items-center justify-content-between"
                          >
                            Tarjeta de Presentación{" "}
                            <i className="fa-solid fa-chevron-right text-white"></i>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="stats"
                            disabled={!user.artist}
                            href="#"
                            className="p-2 br-10 arNav d-flex align-items-center justify-content-between"
                          >
                            Estadísticas
                            <i className="fa-solid fa-chevron-right text-white"></i>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </div>
                ) : (
                  <div className="w-100 p-0 mb-2 pt-0 mt-0 " id="tabs">
                    <div className="overflow-scroll smooth w-100 bg-whitesmoke py-1 mt-0">
                      <div
                        className="d-flex flex-row align-items-center justify-content-center px-4"
                        style={{
                          width: "max-content",
                          minWidth: "100%",
                          overflowX: "scroll",
                        }}
                      >
                        <Nav variant="pills" className="flex-row overflow-scroll pt-0 mt-0">
                          <Nav.Item>
                            <Nav.Link
                              eventKey="info"
                              href="#"
                              className="fNav p-2 mx-1 br-15 arNav d-flex align-items-center cubic justify-content-between"
                            >
                              Información de Artista
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="comparte"
                              disabled={!user.artist}
                              href="#"
                              className="fNav p-2 mx-1 br-15 cubic arNav d-flex align-items-center justify-content-between"
                            >
                              Comparte{" "}
                              {width > 750 && (
                                <i className="fa-solid fa-chevron-right text-white"></i>
                              )}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="tarjeta"
                              disabled={!user.artist}
                              href="#"
                              className="p-2 br-15 mx-1 fNav cubic arNav d-flex align-items-center justify-content-between"
                            >
                              Tarjeta de Presentación{" "}
                              {width > 750 && (
                                <i className="fa-solid fa-chevron-right text-white"></i>
                              )}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="stats"
                              disabled={!user.artist}
                              href="#"
                              className="p-2 br-15 cubic arNav mx-1 fNav d-flex align-items-center justify-content-between"
                            >
                              Estadísticas
                              {width > 750 && (
                                <i className="fa-solid fa-chevron-right text-white"></i>
                              )}
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                    </div>
                  </div>
                )}
              </Col>
            )}
            <Col xs={12} sm={12} lg={9}>
              <Tab.Content>
                <Tab.Pane eventKey="info">
                  <div>
                    <Container fluid>
                      <h5 className="font-weight-bold text-black mt-2 text-jakarta">
                        Página de Artista
                      </h5>

                      <div className="mt-4 w-100 px-0">
                        <Row>
                          <Col
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            style={{
                              transition: "cubic-bezier(0.075, 0.82, 0.165, 1) 2s",
                            }}
                          >
                            {user.artist && (
                              <div className="mb-2">
                                <div className="d-flex align-items-center">
                                  <h5 className="text-black text-jakarta">Estatus:</h5>
                                  <Container className="d-flex align-items-center">
                                    {activeLoading ? (
                                      <div className="">
                                        <Spinner animation="border" variant="warning" />
                                      </div>
                                    ) : (
                                      <div className="d-flex flex-row align-items-center">
                                        <Button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            toggleActive();
                                          }}
                                          variant="warning"
                                          style={{
                                            width: "3rem",
                                            height: "1.5rem",
                                            backgroundColor: active ? "#f5803e" : "gray",
                                            borderRadius: "15px",
                                            padding: "0px",
                                            display: "flex",
                                            direction: "row",
                                            justifyContent: active ? "flex-end" : "flex-start",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "50%",
                                              height: "100%",
                                              backgroundColor: "white",
                                              borderRadius: "15px",
                                              padding: "0px",
                                              margin: "0px",
                                            }}
                                          />
                                        </Button>
                                        <div className="ml-2 d-flex flex-row align-items-center text-center">
                                          {active ? "activa" : "inactiva"}
                                        </div>
                                      </div>
                                    )}
                                  </Container>
                                </div>

                                <p className="mt-1 pt-0 text-jakarta">
                                  Estatus de la página:{" "}
                                  {active
                                    ? "Tu página aparecerá disponible."
                                    : "Tu página no aparecerá cuando búsquen musicos."}
                                </p>
                              </div>
                            )}
                          </Col>
                        </Row>
                        <br className="mt-2" />
                        <h5 className="text-black text-jakarta">Información de la página</h5>
                        <p className="mb-3 text-jakarta">
                          No se te olvide guardar la información (al final hay un botón)
                        </p>
                        <div>
                          <Row>
                            <Col
                              xs={12}
                              sm={12}
                              md={6}
                              lg={6}
                              className="d-flex align-items-center justify-content-center text-center"
                            >
                              {picUrl ? (
                                <div
                                  className="d-flex flex-column align-items-center justify-content-center text-center"
                                  style={{
                                    width: "13rem",
                                    height: "13rem",
                                    borderRadius: "50%",
                                  }}
                                >
                                  <img
                                    src={picUrl}
                                    className="border"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                      borderRadius: "50%",
                                    }}
                                  />
                                  <div
                                    className="d-flex flex-column align-items-center justify-content-center text-center image"
                                    style={{
                                      width: "13rem",
                                      height: "100%",
                                      borderRadius: "50%",
                                      position: "absolute",
                                      bottom: "0%",
                                    }}
                                  >
                                    <Form.Group
                                      controlId="formBasicPp"
                                      className="child"
                                      style={{
                                        width: "13rem",
                                      }}
                                    >
                                      {" "}
                                      <div
                                        className="font-weight-bold"
                                        style={{
                                          width: "13rem",
                                          position: "absolute",
                                          top: "20%",
                                          left: "0%",
                                          color: "red",
                                        }}
                                      >
                                        <Button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            deletePp();
                                          }}
                                          variant="danger"
                                          className="btn btn-sm btn-danger"
                                        >
                                          Eliminar
                                        </Button>
                                      </div>{" "}
                                      <small
                                        className="font-weight-bold"
                                        style={{
                                          width: "13rem",
                                          position: "absolute",
                                          bottom: "20%",
                                          left: "0%",
                                          color: "white",
                                        }}
                                      >
                                        Tamaño recomendado: <br />
                                        500 x 500 px
                                      </small>
                                      <Form.Control
                                        onChange={(event) => {
                                          var file = event.target.files[0];
                                          if (validateSize(file)) {
                                            setPP(file);
                                          }
                                        }}
                                        type="file"
                                        className="p-0 m-0 h-100"
                                      />
                                    </Form.Group>
                                  </div>
                                </div>
                              ) : (
                                <Form.Group controlId="formBasicPp" className="mb-4 mt-4">
                                  <Form.Label>Fóto de perfil</Form.Label>
                                  <Form.Control
                                    onChange={(event) => {
                                      var file = event.target.files[0];
                                      if (validateSize(file)) {
                                        setPP(file);
                                      }
                                    }}
                                    type="file"
                                    accept=".jpg,.png,.jpeg"
                                    className="p-0  m-0 h-100"
                                  />
                                  <small className="text-secondary font-weight-bold">
                                    Tamaño recomendado: 500 x 500 px
                                  </small>
                                </Form.Group>
                              )}
                            </Col>

                            <Col
                              xs={12}
                              sm={12}
                              md={6}
                              lg={6}
                              className="d-flex flex-column align-items-center justify-content-start "
                            >
                              <Form.Group controlId="formBasicName" className="mb-4 mt-4 w-100">
                                <Form.Label className="text-secondary text-jakarta">
                                  Nombre artístico{" "}
                                  <small>
                                    {" "}
                                    {artistName != null ? artistName.length : "0"}
                                    /35 caractéres
                                  </small>
                                </Form.Label>

                                <Form.Control
                                  onChange={(thiss) => {
                                    if (thiss.target.value.length <= 35) {
                                      setArtistName(thiss.target.value);
                                    }
                                  }}
                                  name="nombre"
                                  className="w-100"
                                  value={artistName}
                                  type="text"
                                  placeholder="Ingresa el nombre de artísta o banda"
                                />
                              </Form.Group>
                            </Col>
                          </Row>

                          <Form.Group controlId="formBasicDesc" className="mb-4 mt-4">
                            <Form.Label className="text-secondary text-jakarta">
                              Descripción
                              <small>
                                {" "}
                                {desc != null ? desc.length : "0"}
                                /300 caractéres
                              </small>
                            </Form.Label>

                            <Form.Control
                              onChange={(thiss) => {
                                if (thiss.target.value.length <= 300) {
                                  setDesc(thiss.target.value);
                                }
                              }}
                              name="desc"
                              value={desc}
                              as="textarea"
                              rows={4}
                              placeholder="Breve descripción"
                            />
                          </Form.Group>

                          <Row>
                            <Col xs={12} sm={12} md={6} lg={6}>
                              <Form.Group controlId="formBasicTel" className="mb-4 mt-4">
                                <Form.Label className="text-secondary text-jakarta">
                                  Teléfono de contacto (Whatsapp)
                                </Form.Label>

                                {/* <Form.Control
                                  onChange={(thiss) => {
                                    settel(thiss.target.value);
                                  }}
                                  name="tel"
                                  value={tel}
                                  type="text"
                                  placeholder="Ingresa teléfono de contacto"
                                /> */}
                                <PhoneInput
                                  placeholder="Ingresa tu whatsapp"
                                  defaultCountry="MX"
                                  onChange={(thiss) => {
                                    console.log(thiss);
                                    settel(thiss);
                                  }}
                                  value={tel}
                                  className={"phone-input"}
                                />
                              </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={6} lg={6}>
                              <Form.Group controlId="formBasicEmail" className="mb-4 mt-4">
                                <Form.Label className="text-secondary text-jakarta">
                                  E-mail de contacto
                                </Form.Label>

                                <Form.Control
                                  onChange={(thiss) => {
                                    setEmailCont(thiss.target.value);
                                  }}
                                  name="email"
                                  value={emailcont}
                                  type="email"
                                  placeholder="Ingresa e-mail de contacto"
                                />
                              </Form.Group>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={12} sm={12} md={12} lg={4}>
                              <Form.Group controlId="formBasicFb" className="mb-4 mt-4">
                                <Form.Label className="text-secondary text-jakarta">
                                  Link de página de facebook
                                </Form.Label>

                                <Form.Control
                                  onChange={(thiss) => {
                                    setFb(thiss.target.value);
                                  }}
                                  name="fb"
                                  value={fb}
                                  type="text"
                                  placeholder="www.facebook.com/PAGINA DE FACEBOOK"
                                />
                              </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={4}>
                              <Form.Group controlId="formBasicIg" className="mb-4 mt-4">
                                <Form.Label className="text-secondary text-jakarta">
                                  Link de página de instagram
                                </Form.Label>

                                <Form.Control
                                  onChange={(thiss) => {
                                    setIg(thiss.target.value);
                                  }}
                                  name="ig"
                                  value={ig}
                                  type="text"
                                  placeholder="www.instagram.com/PAGINA DE INSTAGRAM"
                                />
                              </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={4}>
                              <Form.Group controlId="formBasicYt" className="mb-4 mt-4">
                                <Form.Label className="text-secondary text-jakarta">
                                  Link de CANAL de youtube
                                </Form.Label>

                                <Form.Control
                                  onChange={(thiss) => {
                                    setYt(thiss.target.value);
                                  }}
                                  name="yu"
                                  value={yt}
                                  type="text"
                                  placeholder="www.youtube.com/channel/..."
                                />
                              </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={4}>
                              <Form.Group controlId="formBasicYt" className="mb-4 mt-4">
                                <Form.Label className="text-secondary text-jakarta">
                                  Link de video musical en youtube
                                </Form.Label>

                                <Form.Control
                                  onChange={(thiss) => {
                                    setYtVid(thiss.target.value);
                                  }}
                                  name="yuVid"
                                  value={ytVid}
                                  type="text"
                                  placeholder="www.youtube.com/watch?v=..."
                                />
                              </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={4}>
                              <Form.Group controlId="formBasicYt" className="mb-4 mt-4">
                                <Form.Label className="text-secondary text-jakarta">
                                  Link de Spotify
                                </Form.Label>

                                <Form.Control
                                  onChange={(thiss) => {
                                    setSp(thiss.target.value);
                                  }}
                                  name="sp"
                                  value={sp}
                                  type="text"
                                  placeholder="open.spotify.com/artist.."
                                />
                              </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={4}>
                              <Form.Group controlId="formBasicYt" className="mb-4 mt-4">
                                <Form.Label className="text-secondary text-jakarta">
                                  Link de Soundcloud
                                </Form.Label>

                                <Form.Control
                                  onChange={(thiss) => {
                                    setSc(thiss.target.value);
                                  }}
                                  name="sc"
                                  value={sc}
                                  type="text"
                                  placeholder="soundcloud.com/.."
                                />
                              </Form.Group>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={12} sm={12} md={12} lg={6}>
                              <Form>
                                <Form.Group controlId="formBasicInst" className="mb-4 mt-4">
                                  <Form.Label className="text-secondary text-jakarta">
                                    Instrumento(s)
                                  </Form.Label>
                                  <div className="border checkInst">
                                    {instruments.length > 1 &&
                                      instruments.length != null &&
                                      instruments.map((inst, index) => {
                                        return (
                                          <Form.Check
                                            onChange={(thiss) => {
                                              setPlayInst(inst.instrument, thiss.target.checked);
                                            }}
                                            key={index}
                                            label={inst.instrument}
                                            name={`instrument-${index}`}
                                            type="checkbox"
                                            defaultChecked={inst.plays}
                                            id={`checkbox-${index}`}
                                          />
                                        );
                                      })}
                                  </div>
                                </Form.Group>
                              </Form>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={6}>
                              <Form.Group controlId="formBasicGenres" className="mb-4 mt-4">
                                <Form.Label className="text-secondary text-jakarta">
                                  Género(s) Musical(es)
                                </Form.Label>
                                <div className="border checkInst">
                                  {genres.length > 1 &&
                                    genres.length != null &&
                                    genres.map((genre, index) => {
                                      return (
                                        <Form.Check
                                          onChange={(thiss) => {
                                            setPlayGenre(genre.genre, thiss.target.checked);
                                          }}
                                          key={index}
                                          label={genre.genre}
                                          name={`genre-${index}`}
                                          type="checkbox"
                                          defaultChecked={genres[index].plays}
                                          id={`checkbox-genre-${index}`}
                                        />
                                      );
                                    })}
                                </div>
                              </Form.Group>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={12} sm={12} md={6} lg={4}>
                              <Form.Group controlId="formBasicCheck" className="mb-4 mt-4">
                                <Form.Label className="text-secondary text-jakarta">
                                  País
                                </Form.Label>
                                <div className="border checkInst">
                                  {countries.length > 1 &&
                                    countries.length != null &&
                                    countries.map((country, index) => {
                                      return (
                                        <Form.Check
                                          onChange={(thiss) => {
                                            selectCountry(
                                              index,
                                              country.name,
                                              country.isoCode,
                                              thiss.target.checked,
                                              country.currency
                                            );
                                          }}
                                          key={index}
                                          checked={countriesValues[index]}
                                          label={country.flag + " " + country.name}
                                          name={`country-${index}`}
                                          type="radio"
                                          id={`radio-co-${index}`}
                                        />
                                      );
                                    })}
                                </div>
                              </Form.Group>
                            </Col>

                            {states.length > 0 && states.length != null && (
                              <Col xs={12} sm={12} md={6} lg={4}>
                                <Form.Group controlId="formBasicRadio" className="mb-4 mt-4">
                                  <Form.Label className="text-secondary text-jakarta">
                                    Estado
                                  </Form.Label>

                                  <div className="border checkInst">
                                    {states.map((state, index) => {
                                      return (
                                        <Form.Check
                                          onChange={(thiss) => {
                                            selectState(
                                              index,
                                              state.name,
                                              state.isoCode,
                                              thiss.target.checked
                                            );
                                          }}
                                          key={index}
                                          checked={statesValues[index]}
                                          label={state.name}
                                          name={`state-${index}`}
                                          type="radio"
                                          id={`radio-s-${index}`}
                                        />
                                      );
                                    })}
                                  </div>
                                </Form.Group>
                              </Col>
                            )}

                            {currency && (
                              <Col xs={12} sm={12} md={6} lg={4}>
                                <Form.Label className="text-secondary mt-4 text-jakarta">
                                  Precio
                                </Form.Label>

                                <Form.Group controlId="formPrices" className="mb-4">
                                  <Form.Label className="text-secondary text-jakarta">
                                    Precio por hora ({currency})
                                  </Form.Label>

                                  <Form.Control
                                    onChange={(thiss) => {
                                      setConvenir(false);
                                      if (parseInt(thiss.target.value) <= 9999999) {
                                        setPrice(parseInt(thiss.target.value));
                                      } else if (thiss.target.value == "") {
                                        setPrice(0);
                                      }
                                      if (price === 0) {
                                        setPrice(parseInt(thiss.target.value));
                                      }
                                    }}
                                    name="price"
                                    value={price}
                                    type="number"
                                    placeholder="¿Cuanto cobras x hr?"
                                  />
                                </Form.Group>

                                <Form.Group controlId="formPrices" className="mb-4 mt-4">
                                  <Form.Check
                                    onChange={(thiss) => {
                                      setConvenir(thiss.target.checked);
                                      setPrice(0);
                                    }}
                                    checked={convenir}
                                    label={"A convenir"}
                                    name={`convenir`}
                                    type="radio"
                                    id={`co`}
                                  />
                                </Form.Group>
                              </Col>
                            )}
                          </Row>

                          <div className="d-flex align-items-center mb-4">
                            <Button
                              variant="warning"
                              className="btn btn-warning btn-block br-10 btn-orange"
                              onClick={(e) => {
                                e.preventDefault();
                                setPage();
                              }}
                            >
                              Guardar
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Container>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="comparte">
                  {user.artist && (
                    <div className="h-100">
                      <div className="mb-3 w-100">
                        <h5 className="mt-2 text-black text-jakarta">¡Comparte tu página!</h5>
                        <div className="mt-2 w-100" style={{ display: "inline-block" }}>
                          <Form.Control
                            id="artistId"
                            type="text"
                            value={"app.mariiachi.com/sl/" + shortUrl}
                            className="w-100"
                            disabled={artDis}
                          />
                          <Button
                            variant="warning"
                            className="btn btn-orange px-3 mt-2"
                            onClick={(e) => {
                              e.preventDefault();
                              setArtDis(false);
                              setTimeout(() => {
                                copyLink();
                              }, 1);
                            }}
                            ref={target}
                            id="usesTt"
                          >
                            Copiar
                          </Button>
                          <Overlay target={target.current} show={tooltipOpen} placement="top">
                            <Tooltip>¡Link copiado!</Tooltip>
                          </Overlay>
                          <Link className="btn btn-orange px-3 ml-2 mt-2" to={"/sl/" + shortUrl}>
                            Visitar
                          </Link>
                        </div>
                      </div>
                      <div>
                        {user.artist && (
                          <div>
                            <h5 className="text-black text-jakarta">Tu código QR de página</h5>
                            <p className="text-jakarta">
                              Aquí abajo se encuentra el QR de tu página de artista,
                              <br />
                              Ponlo donde gustes, en tu tarjeta de presentación, posters, etc.
                            </p>
                            <div>
                              <QRCodeSVG
                                value={`app.mariiachi.com/artist/${artistUid}`}
                                style={{ maxHeight: "90%" }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </Tab.Pane>

                <Tab.Pane eventKey="tarjeta">
                  <div className="h-100">
                    <h5 className="mt-2 text-black text-jakarta">Tu tarjeta de presentación</h5>
                    <p className="text-jakarta">
                      ¡Comparte tu información a donde quiera que vayas! <br />
                      Genera e imprime tu tarjeta de presentación con tu código QR de tu página.
                    </p>
                    {user.artist && (
                      <Button
                        className="btn-danger br-10"
                        onClick={(e) => {
                          e.preventDefault();
                          setReverse(true);
                          setFront(true);
                          setCardOpen(true);
                          setTimeout(() => {
                            showImageCard();
                          }, [200]);
                        }}
                      >
                        Genera tu Tarjeta de Presentación
                      </Button>
                    )}
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="stats" on>
                  <div>
                    <h5 className="text-black mt-2 font-weight-bold mb-3 text-jakarta">
                      Estadísticas
                    </h5>

                    {fans.length > 0 ? (
                      <div>
                        <h5 className="text-black mt-4 text-jakarta">Fans</h5>

                        <p className="text-jakarta">
                          Eres favorito de {fans.length} personas ¡Sigue así!
                        </p>

                        <h5 className="text-black mt-2 mb-1 text-jakarta">Tendencias</h5>
                        {labelData != null ? (
                          <ReactECharts option={options} />
                        ) : (
                          <div>
                            <p className="text-jakarta">Todavía no hay datos que mostrar</p>
                          </div>
                        )}
                      </div>
                    ) : (
                      <p className="text-jakarta">
                        Todavía no tienes fans, pero no te preocupes, espera a que te descubran.
                        ¡Comparte tu página de mariiachi!
                      </p>
                    )}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      )}
    </div>
  );
}

export default Artista;
