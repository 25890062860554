import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPlayer from "react-player";
import "../../App.css";
import NavApp from "../../Components/NavApp";
import NavbarLanding from "../../Components/NavbarLanding";
import "../../assets/css/theme.css";

import { Autoplay, EffectCreative } from "swiper";
import "swiper/css";
import "swiper/css/effect-creative";
import { Swiper, SwiperSlide } from "swiper/react";

import {
  Badge,
  Button,
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import AmazonAd from "../../Components/AmazonAd";
import ArtistDisp from "../../Components/ArtistDisp";
import Footer from "../../Components/Footer";
import { UseUser } from "../../Context/userContext";
import drums1 from "../../assets/img/instruments/drums1.png";
import guitar2 from "../../assets/img/instruments/guitar2.png";
import mariachiman from "../../assets/img/instruments/mariachi1.png";
import mariachiGirl from "../../assets/img/mariachigirl.png";
import mariachi from "../../assets/img/mariachiman.png";
import PopularSection from "../HomeSection/PopularSection";

function Search() {
  const [loading, setLoading] = useState(true);
  const [queryLoading, setQueryLoading] = useState(false);
  const { loc, setLocContext, user, setUserContext, setShowLoc } = UseUser();
  const [indexx, setindexx] = useState(0);

  const [modalVideo, setVideo] = useState(false);

  const [verified, setVerified] = useState(false);
  const [instruments, setInstruments] = useState([]);
  const [genres, setGenres] = useState([]);

  const [sOption, setOption] = useState("generos");

  const [instFilter, setInstFilter] = useState([]);
  const [genFilter, setGenFilter] = useState([]);

  const [order1, setOrder] = useState("Más Relevantes");

  const [artistQuery, setArtistQuery] = useState([]);

  const [offCanv, setOfCanv] = useState(false);
  const handleClose = () => setOfCanv(false);
  const handleShow = () => setOfCanv(true);
  const [endd, setEnd] = useState(false);
  const [lastDoc, setLast] = useState();

  const setPlayInst = (instrument, value) => {
    var array = instruments;

    var filter = [];

    array.forEach((inst, index) => {
      if (inst.instrument == instrument) {
        array[index].plays = !array[index].plays;
      }

      if (array[index].plays == true) {
        filter.push(inst.instrument);
      }
    });

    if (filter.length <= 10) {
      setInstFilter(filter);
      setInstruments(array);
    } else {
      alert("Puedes seleccionar máximo 10 instrumentos");
    }
  };

  const setPlayGenre = (genre1, value) => {
    var array = genres;

    var filter = [];
    array.forEach((genre, index) => {
      if (genre.genre === genre1) {
        if (genFilter.length < 10 || (array[index].plays == true && genFilter.length == 10)) {
          array[index].plays = !array[index].plays;
        }
      }
      if (genFilter.length < 10) {
        if (array[index].plays === true) {
          filter.push(genre.genre);
        }
      }
    });

    if (filter.length < 10) {
      setGenFilter(filter);
      setGenres(array);
    } else {
      alert("Puedes seleccionar máximo 10 géneros.");
      return;
    }
  };

  const getMore = async () => {
    handleClose();
    setindexx(indexx + 1);
    setQueryLoading(true);
    var query = firebase
      .firestore()
      .collection("artists")
      .where("blocked", "!=", true)
      .where("status", "==", true)
      .where("country", "==", loc.country)
      .where("state", "==", loc.state)
      .orderBy("blocked", "desc");

    if (sOption == "generos") {
      if (genFilter.length > 0) {
        query = query.where("genres", "array-contains-any", genFilter);
      }
    } else {
      if (instFilter.length > 0) {
        query = query.where("instruments", "array-contains-any", instFilter);
      }
    }

    if (verified) {
      query = query.where("verified", "==", true);
    }

    if (order1 == "Mayor Precio") {
      query = query.orderBy("price", "desc");
    }
    if (order1 == "Menor Precio") {
      query = query.orderBy("price", "asc");
    }
    if (order1 == "Populares") {
      query = query.orderBy("fanCount", "desc");
    }
    var last;
    var results = artistQuery;
    await query
      .startAfter(lastDoc)
      .limit(30)
      .get()
      .then(async (snap) => {
        snap.forEach((doc) => {
          results.push(doc.data());
        });
        last = snap.docs[snap.docs.length - 1];
        console.log("last: ", last);
        if (snap.docs.length < 30 || snap.docs.length == undefined) {
          setEnd(true);
        } else {
          setLast(last);
        }
      })
      .then(() => {
        setQueryLoading(false);
        setArtistQuery(results);
        document.getElementById(`tail-${indexx + 1}`).scrollIntoView();
      })
      .catch((er) => {
        setQueryLoading(false);
        console.log(er);
      });
  };

  const getQuery = async (orr) => {
    setEnd(false);
    handleClose();
    setQueryLoading(true);
    var query = firebase
      .firestore()
      .collection("artists")
      .where("status", "==", true)
      .where("country", "==", loc.country)
      .where("state", "==", loc.state);

    if (sOption == "generos") {
      if (genFilter.length > 0) {
        query = query.where("genres", "array-contains-any", genFilter);
      }
    } else {
      if (instFilter.length > 0) {
        query = query.where("instruments", "array-contains-any", instFilter);
      }
    }

    if (verified) {
      query = query.where("verified", "==", true);
    }

    if (orr != null && orr != undefined) {
      if (orr == "Mayor Precio") {
        query = query.orderBy("price", "desc");
      }
      if (orr == "Menor Precio") {
        query = query.orderBy("price", "asc");
      }
      if (orr == "Populares") {
        query = query.orderBy("fanCount", "desc");
      }
    } else {
      if (order1 == "Mayor Precio") {
        query = query.orderBy("price", "desc");
      }
      if (order1 == "Menor Precio") {
        query = query.orderBy("price", "asc");
      }
      if (order1 == "Populares") {
        query = query.orderBy("fanCount", "desc");
      }
    }

    var results = [];
    await query
      .limit(30)
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          console.log(doc.data());
          results.push(doc.data());
        });
        var last = snap.docs[snap.docs.length - 1];
        setLast(last);
        if (snap.docs.length == 0) {
          setEnd(true);
        }
      })
      .then(() => {
        setQueryLoading(false);
        console.log(results);
        setArtistQuery(results);
      })
      .catch((er) => {
        setQueryLoading(false);
        console.log(er);
      });
  };

  const getInitial = async (loca) => {
    setEnd(false);
    setQueryLoading(true);
    var query = firebase
      .firestore()
      .collection("artists")
      .where("blocked", "!=", true)
      .where("status", "==", true)
      .where("country", "==", loca.country)
      .where("state", "==", loca.state)
      .orderBy("blocked", "desc");

    var results = [];
    await query
      .limit(30)
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          results.push(doc.data());
        });
        var last = snap.docs[snap.docs.length - 1];
        setLast(last);
        if (snap.docs.length == 0) {
          setEnd(true);
        }
      })
      .then(() => {
        setQueryLoading(false);
        setArtistQuery(results);
      })
      .catch((er) => {
        setQueryLoading(false);
        console.log(er);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    var fetInst = [];
    var fetGen = [];
    var prevvv = user;
    var cvalues = [];
    var allowedLocs = {};
    document.querySelector("title").innerHTML = "Mariiachi.com | " + "Buscar";

    if (loc.country == undefined || loc.country == null) {
      setShowLoc(true);
    } else {
      firebase.auth().onAuthStateChanged((authUser) => {
        firebase
          .firestore()
          .collection("instruments")
          .doc("instruments")
          .get()
          .then((doc) => {
            var totInst = [];
            doc.data().instruments.forEach((elem) => {
              var instObj = new Object();
              instObj.instrument = elem;
              instObj.plays = false;
              totInst.push(instObj);
            });
            if (fetInst != null && fetInst != undefined) {
              fetInst.forEach((fet, ind) => {
                totInst.forEach((inst, inde) => {
                  if (inst.instrument == fet) {
                    totInst[inde].plays = true;
                  }
                });
              });
            }
            setInstruments(
              totInst.sort((a, b) =>
                a.instrument !== b.instrument ? (a.instrument < b.instrument ? -1 : 1) : 0
              )
            );
          })
          .then(() => {
            firebase
              .firestore()
              .collection("genres")
              .doc("genres")
              .get()
              .then((doc) => {
                var totGenres = [];
                doc.data().genres.forEach((elem) => {
                  var genreObj = new Object();
                  genreObj.genre = elem;
                  genreObj.plays = false;
                  totGenres.push(genreObj);
                });

                if (fetGen != null && fetGen != undefined) {
                  fetGen.forEach((fet, ind) => {
                    totGenres.forEach((gen, inde) => {
                      if (gen.genre == fet) {
                        totGenres[inde].plays = true;
                      }
                    });
                  });
                }

                setGenres(
                  totGenres.sort((a, b) => (a.genre !== b.genre ? (a.genre < b.genre ? -1 : 1) : 0))
                );
              });
          })
          .then(() => {
            setLoading(false);
            if (loc != null && loc != undefined) {
              getInitial(loc);
            }
          })
          .catch((er) => {
            console.log(er);
            alert(er);
            setLoading(false);
          });
      });
    }
  }, [loc]);

  return (
    <div>
      <br id="top" />

      <Modal
        show={modalVideo}
        size="lg"
        className="my-modal"
        centered
        onHide={() => {
          setVideo(false);
        }}
      >
        <ModalBody style={{ backgroundColor: "rgba(0,0,0,0) !important" }}>
          <div
            className="shadow-lg w-100 px-1 py-1 br-15 mb-3"
            style={{
              height: "50vw",
              maxHeight: "27rem",
              // backgroundColor: "black",
            }}
          >
            <ReactPlayer
              controls={true}
              url="https://youtu.be/kajz1pRCQVs"
              className="w-100 h-100 br-15"
            />
          </div>
        </ModalBody>
      </Modal>
      {loading ? (
        <div
          className="bg-light d-flex align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <div className="w-100">
          {user.email ? <NavApp /> : <NavbarLanding />}

          <div
            className="offCanvas"
            style={{
              height: "100vh",
              width: "100vw",
              position: "fixed",
              top: "0px",
              left: offCanv ? "0px" : "-100vw",
              backgroundColor: "white",
              zIndex: "999",
            }}
          >
            <Container className="w-100 pt-4">
              <div className="w-100 d-flex flex-row align-items-end justify-content-end">
                <br />
                <Button
                  variant="link"
                  className="px-1 mt-5"
                  style={{ textDecoration: "none" }}
                  onClick={handleClose}
                >
                  <i className="far fa-times-circle text-warning fs-2"></i>
                </Button>
              </div>

              <h5 className="mt-2 text-warning px-2 text-left">Ubicación</h5>

              {loc != null && loc != undefined && (
                <div className="d-flex flex-row ">
                  <Button
                    variant="link"
                    className="text-warning whitesmoke-hvr py-05 ml-0  m-0 link pulse-lg-2"
                    onClick={(e) => {
                      e.preventDefault();
                      // setModal(true);
                      // setLoading(true);
                      setShowLoc(true);
                    }}
                  >
                    <p className="text-center text-location h-100 p-0 m-0">
                      <i className="fa-solid fa-location-dot mr-2"></i>
                      {loc.state}, {loc.country}
                    </p>
                  </Button>
                </div>
              )}
              <h5 className="text-warning mt-3 px-2 text-left">Filtrar por:</h5>
              <div className="w-100 d-flex align-items-center justify-content-center ">
                <ButtonGroup aria-label="Basic example">
                  <Button
                    variant={sOption == "instrumentos" ? "outline-warning" : "warning"}
                    className="btn px-1 "
                    style={{ fontSize: "0.8rem" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setOption("generos");
                    }}
                  >
                    Géneros
                  </Button>
                  <Button
                    variant={sOption == "instrumentos" ? "warning" : "outline-warning"}
                    className="btn px-1"
                    style={{ fontSize: "0.8rem" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setOption("instrumentos");
                    }}
                  >
                    Instrumentos
                  </Button>
                </ButtonGroup>
              </div>

              {sOption == "generos" ? (
                <div className="w-100">
                  <h5 className="mt-3 text-warning px-2 text-left">Género(s)</h5>
                  <div className="border checkInst w-100 br-15 px-2">
                    {genres.length > 1 &&
                      genres.length != null &&
                      genres.map((genre, index) => {
                        return (
                          <Form.Check
                            onChange={(thiss) => {
                              setPlayGenre(genre.genre, thiss.target.checked);
                            }}
                            key={index}
                            label={genre.genre}
                            name={`genre-${index}`}
                            type="checkbox"
                            checked={genres[index].plays}
                            id={`checkbox-genre-${index}`}
                          />
                        );
                      })}
                  </div>
                </div>
              ) : (
                <div className="w-100">
                  <h5 className="mt-3 text-warning px-2 text-left">Instrumento(s)</h5>
                  <div className="border checkInst w-100">
                    {instruments.length > 1 &&
                      instruments.length != null &&
                      instruments.map((inst, index) => {
                        return (
                          <Form.Check
                            onChange={(thiss) => {
                              setPlayInst(inst.instrument, thiss.target.checked);
                            }}
                            key={index}
                            label={inst.instrument}
                            name={`instrument-${index}`}
                            type="checkbox"
                            checked={inst.plays}
                            id={`checkbox-${index}`}
                          />
                        );
                      })}
                  </div>
                </div>
              )}
              <h5 className="mt-3 text-warning px-2 text-left">Tipo de Artista</h5>
              <Form.Check
                onChange={(thiss) => {
                  setVerified(thiss.target.checked);
                }}
                label={"Sólo Verificados"}
                name={`verified`}
                type="checkbox"
                id={`checkbox-verified`}
              />
              <Button
                variant="warning"
                className="btn btn-block mt-3 px-2 pulse-lg-2 br-15"
                onClick={(e) => {
                  e.preventDefault();
                  getQuery();
                }}
              >
                Buscar
              </Button>
            </Container>
          </div>

          <Container fluid className="w-100 mh-100" style={{ marginTop: "5.5rem" }}>
            <Row className="mt-4">
              {/* left menu filter artists */}
              <Col
                xs={12}
                sm={12}
                md={3}
                lg={2}
                style={{
                  position: "relative",
                }}
              >
                <div
                  className="d-xss-none d-sm-none d-md-block"
                  style={{ position: "sticky", top: "80px" }}
                >
                  <div className="d-flex flex-column align-items-start justify-content-center">
                    <h5 className="mt-4 searchTitle px-2 text-left mb-1">Ubicación</h5>

                    {loc != null && loc != undefined && (
                      <div className="d-flex flex-row">
                        <Button
                          variant="link"
                          className="text-warning whitesmoke-hvr py-05 ml-2  m-0 link pulse-lg-2"
                          onClick={(e) => {
                            e.preventDefault();
                            // setModal(true);
                            // setLoading(true);
                            setShowLoc(true);
                          }}
                        >
                          <p className="text-center text-location h-100 p-0 m-0">
                            <i className="fa-solid fa-location-dot mr-2"></i>
                            {loc.state}, {loc.country}
                          </p>
                        </Button>
                      </div>
                    )}
                    <h5 className="searchTitle mt-3 px-2 text-left">Filtrar por:</h5>
                    <div className="w-100 d-flex align-items-center justify-content-center">
                      <ButtonGroup aria-label="Basic example" className="br-10">
                        <Button
                          variant="warning"
                          className={`btn px-2  ${
                            sOption == "instrumentos" ? "outline-orange" : "orange"
                          }`}
                          style={{ fontSize: "0.8rem" }}
                          onClick={(e) => {
                            e.preventDefault();
                            setOption("generos");
                          }}
                        >
                          Géneros
                        </Button>
                        <Button
                          variant="warning"
                          className={`btn px-2 ${
                            sOption == "instrumentos" ? "orange" : "outline-orange"
                          }`}
                          style={{ fontSize: "0.8rem" }}
                          onClick={(e) => {
                            e.preventDefault();
                            setOption("instrumentos");
                          }}
                        >
                          Instrumentos
                        </Button>
                      </ButtonGroup>
                    </div>

                    {sOption == "generos" ? (
                      <div className="w-100">
                        <h5 className="mt-3 searchTitle px-2 text-left">Género(s)</h5>
                        <div className="border checkInst w-100 br-15 px-2">
                          {genres.length > 1 &&
                            genres.length != null &&
                            genres.map((genre, index) => {
                              return (
                                <Form.Check
                                  onChange={(thiss) => {
                                    setPlayGenre(genre.genre, thiss.target.checked);
                                  }}
                                  key={index}
                                  label={genre.genre}
                                  name={`genre-${index}`}
                                  type="checkbox"
                                  checked={genres[index].plays}
                                  id={`checkbox-genre-${index}`}
                                />
                              );
                            })}
                        </div>
                      </div>
                    ) : (
                      <div className="w-100">
                        <h5 className="mt-3 searchTitle px-2 text-left">Instrumento(s)</h5>
                        <div className="border checkInst w-100 br-15 px-2">
                          {instruments.length > 1 &&
                            instruments.length != null &&
                            instruments.map((inst, index) => {
                              return (
                                <Form.Check
                                  onChange={(thiss) => {
                                    setPlayInst(inst.instrument, thiss.target.checked);
                                  }}
                                  key={index}
                                  label={inst.instrument}
                                  name={`instrument-${index}`}
                                  type="checkbox"
                                  checked={inst.plays}
                                  id={`checkbox-${index}`}
                                />
                              );
                            })}
                        </div>
                      </div>
                    )}

                    <h5 className="mt-3 searchTitle px-2 text-left">Tipo de Artista</h5>
                    <div className="px-2">
                      <Form.Check
                        onChange={(thiss) => {
                          setVerified(thiss.target.checked);
                        }}
                        label={"Sólo Verificados"}
                        name={`verified`}
                        type="checkbox"
                        id={`verified`}
                      />
                    </div>

                    <Button
                      variant="warning"
                      className="btn btn-block btn-orange br-15 mt-3 px-2 pulse-lg-2"
                      onClick={(e) => {
                        e.preventDefault();
                        getQuery();
                      }}
                    >
                      Buscar
                    </Button>
                  </div>
                </div>

                {/* MOBILE MENU */}
                <div className="d-md-none mb-3 px-0 mx-0">
                  <Swiper
                    grabCursor={true}
                    effect={"creative"}
                    creativeEffect={{
                      prev: {
                        shadow: true,
                        translate: [0, 0, -400],
                      },
                      next: {
                        translate: ["100%", 0, 0],
                      },
                    }}
                    modules={[EffectCreative, Autoplay]}
                    autoplay={{
                      delay: 4500,
                      disableOnInteraction: false,
                    }}
                    className="swiperSearch"
                  >
                    <SwiperSlide className="searchSlide">
                      <Link
                        className="h-100 td-none"
                        to={user.email ? "/account#artista" : "/auth#regis"}
                      >
                        <div className="px-3 pt-3 h-100 h-100 align-items-center d-flex flex-column">
                          <LazyLoadImage src={mariachi} className="srchImg" />
                          <p className="font-weight-bold text-white text-jakarta">
                            ¿Eres músico o tienes una banda?
                          </p>
                          <div className="w-100 px-3">
                            <h5 className="text-black text-jakarta">
                              Regístrate para <br /> darte a conocer
                            </h5>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>

                    <SwiperSlide className="searchSlide">
                      <Link
                        className="h-100 td-none"
                        to={user.email ? "/account#artista" : "/auth#regis"}
                      >
                        <div className="px-3 pt-3 h-100 h-100 align-items-center d-flex flex-column">
                          <LazyLoadImage src={mariachiGirl} className="srchImg" />
                          <p className="font-weight-bold text-white text-jakarta">
                            Sin música, no hay fiesta
                          </p>
                          <div className="w-100 px-3">
                            <h5 className="text-black text-jakarta">
                              Encuentra música
                              <br />
                              para tu evento
                            </h5>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>

                    <SwiperSlide className="searchSlide">
                      <Link
                        className="h-100 td-none"
                        to={user.email ? "/account#artista" : "/auth#regis"}
                      >
                        <div className="px-3 pt-3 h-100 h-100 align-items-center d-flex flex-column">
                          <LazyLoadImage src={guitar2} className="srchImg" />
                          <p className="font-weight-bold text-white text-jakarta">
                            Música, para todos
                          </p>
                          <div className="w-100 px-3">
                            <h5 className="text-black text-jakarta">
                              Encuentra música de cualquiér género
                            </h5>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  </Swiper>

                  <div className="d-flex mt-4 flex-column align-items-center justify-content-center ">
                    <Row className="w-100">
                      <Col
                        xs={6}
                        sm={6}
                        md={6}
                        className="d-flex flex-column align-items-center justify-content-center "
                      >
                        <Button
                          variant="warning"
                          className="btn btn-block btn-orange px-1 br-10"
                          onClick={handleShow}
                        >
                          Filtrar
                        </Button>
                      </Col>

                      <Col
                        xs={6}
                        sm={6}
                        md={6}
                        className="d-flex flex-column align-items-center justify-content-center "
                      >
                        <Dropdown className="br-15">
                          <Dropdown.Toggle
                            variant="warning"
                            id="dropdown-basic"
                            className="btn btn-block btn-orange px-3 br-10"
                          >
                            {order1}
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="bg-light">
                            <Dropdown.Item
                              className="dropdown-item-warning cursor"
                              onClick={(e) => {
                                e.preventDefault();
                                setOrder("Más Relevantes");
                                getQuery("Más Relevantes");
                              }}
                            >
                              Más Relevantes
                            </Dropdown.Item>

                            <Dropdown.Item
                              className="dropdown-item-warning cursor"
                              onClick={(e) => {
                                e.preventDefault();
                                setOrder("Mayor Precio");
                                getQuery("Mayor Precio");
                              }}
                            >
                              Mayor Precio
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item-warning cursor"
                              onClick={(e) => {
                                e.preventDefault();
                                setOrder("Menor Precio");
                                getQuery("Menor Precio");
                              }}
                            >
                              Menor Precio
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item-warning cursor"
                              onClick={(e) => {
                                e.preventDefault();
                                setOrder("Populares");
                                getQuery("Populares");
                              }}
                            >
                              Más Populares
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>

              <Col
                xs={12}
                sm={12}
                md={9}
                lg={8}
                xl={7}
                className="mh-100 p-0 w-100 "
                style={{ overflowX: "hidden" }}
              >
                <div
                  className="w-100 px-2 d-flex flex-column align-items-center justify-content-begin mb-3"
                  style={{ display: offCanv ? "none" : "block" }}
                >
                  <div className="d-sm-none">
                    <Swiper
                      grabCursor={true}
                      effect={"creative"}
                      creativeEffect={{
                        prev: {
                          shadow: true,
                          translate: [0, 0, -400],
                        },
                        next: {
                          translate: ["100%", 0, 0],
                        },
                      }}
                      modules={[EffectCreative, Autoplay]}
                      autoplay={{
                        delay: 4500,
                        disableOnInteraction: false,
                      }}
                      className="swiperSearch"
                    >
                      <SwiperSlide className="br-30 overflow-hidden searchSlide">
                        <Container fluid className="text-white w-100 justify-content-center pb-1">
                          <Row className="w-100 justify-content-center">
                            <Col xs={12} md={6} lg={6}>
                              <div className="px-2 h-100 d-flex flex-column justify-content-center">
                                <p className="font-weight-bold text-jakarta">
                                  ¿Eres músico o tienes una banda?
                                </p>
                                <h4 className="text-black text-jakarta">
                                  Regístrate para darte a conocer
                                </h4>

                                {user.email ? (
                                  <Link
                                    className="btn btn-danger btn-block p-2 br-15"
                                    to="/account#artista"
                                  >
                                    Crea tu página de artista
                                  </Link>
                                ) : (
                                  <div className="d-flex align-items-baseline">
                                    <Button
                                      variant="link"
                                      className="link d-flex text-white td-none align-items-baseline px-0 ml-0 mr-4"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setVideo(true);
                                      }}
                                    >
                                      <i className="fa-solid fa-play d-flex align-items-center justify-content-center text-center shadow-hvr mr-2"></i>
                                      <p className="text-white pt-0 mt-0 pb-1">
                                        <strong>Ver video</strong>
                                      </p>
                                    </Button>

                                    <Link
                                      className="btn btn-danger p-1 px-3  br-15"
                                      to="/auth#regis"
                                    >
                                      Regístrate
                                    </Link>
                                  </div>
                                )}
                              </div>
                            </Col>
                            <Col xs={12} md={6} lg={6} className="pb-2">
                              <div className="mgSearch">
                                <LazyLoadImage src={mariachiman} className="srchImg" />
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </SwiperSlide>

                      <SwiperSlide className="br-30 overflow-hidden searchSlide">
                        <Container fluid className="text-white">
                          <Row>
                            <Col xs={12} md={6} lg={6}>
                              <div className="px-2 h-100 d-flex flex-column justify-content-center">
                                <h4 className="text-black text-jakarta">
                                  Encuentra músicos y bandas para cualquier ocasión
                                </h4>
                                <p className="font-weight-bold">
                                  ¡Podrás encontrar de cualquier género musical!
                                </p>

                                <Button
                                  variant="link"
                                  className="link d-flex text-white td-none  align-items-baseline px-0 ml-0"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setVideo(true);
                                  }}
                                >
                                  <i className="fa-solid fa-play d-flex align-items-center justify-content-center text-center shadow-hvr mr-2"></i>
                                  <p className="text-white pt-0 mt-0 pb-1">
                                    <strong>Ver video</strong>
                                  </p>
                                </Button>
                              </div>
                            </Col>
                            <Col xs={12} md={6} lg={6} className="pb-2">
                              <div className="mgSearch">
                                <LazyLoadImage src={drums1} className="srchImg" />
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </SwiperSlide>

                      <SwiperSlide className="br-30 overflow-hidden searchSlide">
                        <Container fluid className="text-white ccenter h-100">
                          <Row>
                            <Col xs={12} md={6} lg={6}>
                              <div className="px-2 h-100 d-flex flex-column justify-content-center">
                                <h4 className="text-black text-jakarta">
                                  Encuentra músicos y bandas para cualquier ocasión
                                </h4>
                                <p className="font-weight-bold text-jakarta">
                                  ¡Podrás encontrar de cualquier género musical!
                                </p>
                                <Button
                                  variant="link"
                                  className="link d-flex text-white td-none  align-items-baseline px-0 ml-0"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setVideo(true);
                                  }}
                                >
                                  <i className="fa-solid fa-play d-flex align-items-center justify-content-center text-center shadow-hvr mr-2"></i>
                                  <p className="text-white pt-0 mt-0 pb-1">
                                    <strong>Ver video</strong>
                                  </p>
                                </Button>
                              </div>
                            </Col>
                            <Col xs={12} md={6} lg={6} className="pb-2">
                              <div className="mgSearch">
                                <LazyLoadImage src={guitar2} className="srchImg" />
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </SwiperSlide>
                    </Swiper>
                  </div>

                  <br />
                  <div className="d-flex align-items-start w-100">
                    <h5 className="mt-4 text-secondary px-3 text-left">
                      Populares en {loc.state}, {loc.country}
                    </h5>
                  </div>

                  <Container fluid className="mt-3">
                    <PopularSection loc={loc} mini />
                  </Container>

                  <div className="w-100 d-md-flex d-flex flex-row align-items-baseline justify-content-end mt-0 d-xss-none d-sm-none d-xs-none mt-1">
                    <h5 className="mt-4 text-secondary px-3 text-left mr-auto">
                      Resultados de búsqueda
                    </h5>
                    <p className="font-weight-bold text-black">Ordenar por</p>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="link"
                        id="dropdown-basic"
                        className="text-warning font-weight-semi-bold"
                        style={{ textDecoration: "none" }}
                      >
                        {order1}
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="bg-light">
                        <Dropdown.Item
                          className="dropdown-item-warning cursor"
                          onClick={(e) => {
                            e.preventDefault();
                            setOrder("Más Relevantes");
                            getQuery("Más Relevantes");
                          }}
                        >
                          Más Relevantes
                        </Dropdown.Item>

                        <Dropdown.Item
                          className="dropdown-item-warning cursor"
                          onClick={(e) => {
                            e.preventDefault();
                            setOrder("Mayor Precio");
                            getQuery("Mayor Precio");
                          }}
                        >
                          Mayor Precio
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="dropdown-item-warning cursor"
                          onClick={(e) => {
                            e.preventDefault();
                            setOrder("Menor Precio");
                            getQuery("Menor Precio");
                          }}
                        >
                          Menor Precio
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="dropdown-item-warning cursor"
                          onClick={(e) => {
                            e.preventDefault();
                            setOrder("Populares");
                            getQuery("Populares");
                          }}
                        >
                          Populares
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  {sOption == "generos" ? (
                    <div style={{ display: "inline" }} className="mt-0 w-100">
                      {genFilter.map((el, ind) => {
                        return (
                          <Badge
                            pill
                            bg="warning"
                            text="white"
                            className="mr-1 my-1"
                            style={{ fontSize: "0.9rem" }}
                          >
                            {el}{" "}
                            <Button
                              className="p-0 m-0"
                              variant="link"
                              style={{ textDecoration: "none" }}
                              onClick={(e) => {
                                e.preventDefault();
                                setPlayGenre(el);
                              }}
                            >
                              {" "}
                              <i
                                className="far fa-times-circle"
                                style={{
                                  fontSize: "1rem",
                                  width: "auto",
                                  color: "white",
                                }}
                              ></i>
                            </Button>
                          </Badge>
                        );
                      })}
                    </div>
                  ) : (
                    <div style={{ display: "inline" }} className="mt-0 w-100">
                      {instFilter.map((el, ind) => {
                        return (
                          <Badge
                            pill
                            bg="danger"
                            text="white"
                            className="mr-1 my-1"
                            style={{ fontSize: "0.9rem" }}
                          >
                            {el}{" "}
                            <Button
                              className="p-0 m-0"
                              variant="link"
                              style={{ textDecoration: "none" }}
                              onClick={(e) => {
                                e.preventDefault();
                                setPlayInst(el);
                              }}
                            >
                              {" "}
                              <i
                                className="far fa-times-circle"
                                style={{
                                  fontSize: "1rem",
                                  width: "auto",
                                  color: "white",
                                }}
                              ></i>
                            </Button>
                          </Badge>
                        );
                      })}
                    </div>
                  )}

                  {queryLoading ? (
                    <div className="d-flex align-items-center justify-content-center w-100 h-100">
                      <Spinner animation="border" variant="warning" />
                    </div>
                  ) : (
                    <Row className="w-100">
                      {artistQuery.length > 0
                        ? artistQuery.map((ar, ind) => {
                            if (ind == indexx * 30 - 3) {
                              return (
                                <ArtistDisp artist={ar} key={ind}>
                                  <div id={`tail-${indexx}`}></div>
                                </ArtistDisp>
                              );
                            } else {
                              return <ArtistDisp artist={ar} key={ind} />;
                            }
                          })
                        : endd && (
                            <Container>
                              <p className="mt-4">
                                No hay resultados para esta búsqueda en tu zona.
                              </p>
                            </Container>
                          )}
                    </Row>
                  )}

                  {artistQuery.length >= 30 && (
                    <Button
                      className="mb-4 mt-1 btn-block btn-orange br-15"
                      variant="warning"
                      disabled={endd ? true : false}
                      onClick={(e) => {
                        e.preventDefault();
                        getMore();
                      }}
                    >
                      {endd ? "No hay más resultados" : "Cargar Más"}
                    </Button>
                  )}
                </div>
              </Col>

              <Col
                xs={12}
                sm={12}
                md={12}
                lg={2}
                xl={3}
                // style={{ minHeight: "30rem" }}
                className="p-0"
              >
                <div className="pb-3" style={{ position: "sticky", top: "80px" }}>
                  <AmazonAd />
                  <div className="d-flex">
                    <div className="p-0" style={{ width: "50%" }}>
                      <AmazonAd />
                    </div>
                    <div className="p-0" style={{ width: "50%" }}>
                      <AmazonAd />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          <Footer />
        </div>
      )}
    </div>
  );
}

export default Search;
