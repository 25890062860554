import "animate.css/animate.min.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Autoplay } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import "../App.css";
import "../assets/css/theme.css";

function AmazonAd() {
  const [ad1, setAd1] = useState({ img: "", link: "" });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    var getAdV2 = firebase.functions().httpsCallable("getAmazonAdV2");

    getAdV2().then((el) => {
      if (el.data == null) {
        setTimeout(() => {
          getAdV2().then((elf) => {
            console.log("ell: ", elf);
            setAd1(elf.data);
            setLoading(false);
          });
        }, 1000);
      } else {
        setAd1(el.data);
        setLoading(false);
      }
    });
  }, []);
  return (
    <Container fluid className="pb-3">
      {loading ? (
        <div className=" d-flex align-items-center justify-content-center py-5">
          {/* <Spinner animation="border" variant="secondary" /> */}
        </div>
      ) : (
        <div>
          <p>
            Promocionado{" "}
            <span>
              <a href="https://amzn.to/3ZlUXgE" target="__blank">
                Amazon.com.mx
              </a>
            </span>
          </p>
          {ad1 != null && (
            <Swiper
              spaceBetween={30}
              autoplay={{
                delay: 4500,
                // disableOnInteraction: true,
                pauseOnMouseEnter: true,
              }}
              modules={[Autoplay]}
              className="adSwiper p-0"
            >
              {ad1.map((el, ind) => {
                return (
                  <SwiperSlide key={ind}>
                    <div className="d-flex p-0 flex-column align-items-center justify-content-center">
                      <a
                        target="__blank"
                        href={el.link}
                        className="d-flex ad flex-column align-items-center justify-content-center"
                      >
                        <img
                          src={el.img}
                          style={{ width: "100%", height: "10rem", objectFit: "cover" }}
                        />
                        <p className="textAd">{el.title.substring(0, 20)}...</p>

                        <div className="adChild">
                          <p className="textAd fs-1">{el.price}</p>
                          <p className="textAd">{el.title.substring(0, 45)}...</p>
                        </div>
                      </a>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        </div>
      )}
    </Container>
  );
}

export default AmazonAd;
