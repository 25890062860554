import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import "animate.css/animate.min.css";
import "firebase/compat/analytics";
import firebase from "firebase/compat/app";

import App from "./App";
import LocSelect from "./Components/LocSelect/LocSelect";
import { UserProvider } from "./Context/userContext";
import "./assets/css/theme.css";
// import Sponsors from "./Layouts/Sponsors/sponsors";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_S_B,
  messagingSenderId: process.env.REACT_APP_MSG_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MSR_ID,
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(
  <UserProvider>
    <LocSelect />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </UserProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
