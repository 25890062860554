import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useEffect, useState } from "react";
import { Badge, Col, Container, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { Autoplay, EffectCards, EffectCreative } from "swiper";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/effect-creative";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../App.css";
import guitarrista from "../../assets/img/instruments/guitarrista.png";

function ArtistCarousel2({ loc }) {
  const [loading, setLoading] = useState(true);
  const [artists, setArtists] = useState([]);
  var width = window.innerWidth;
  const [isMobile, setMobile] = useState(true);

  useEffect(() => {
    if (width < 500) {
      console.log("is mobile");
    } else {
      setMobile(false);
      console.log("desk");
    }
    const getArtists = async (location) => {
      console.log("loc; ", location);
      var favArr = [];
      await firebase
        .firestore()
        .collection("artists")
        // .where("blocked", "!=", true)
        .where("country", "==", location.country)
        .where("state", "==", location.state)
        .where("status", "==", true)
        .where("pp", "!=", "")
        // .orderBy("blocked", "desc")
        // .orderBy("fanCount", "desc")
        .limit(10)
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            if (doc.data().video != "" && doc.data().video != " ") {
              favArr.push({ numb: Math.random(), ...doc.data() });
            }
          });
        })
        .then(() => {
          // setRInt(Math.floor(Math.random() * (favArr.length + 1)));
          favArr.sort(function (a, b) {
            return b.numb - a.numb;
          });
          setArtists(favArr);
          setLoading(false);
        })
        .catch((er) => {
          console.log(er);
          setLoading(false);
        });
    };

    if (loc != undefined && loc != null) {
      getArtists(loc);
      // setLoading(false)
    }
  }, [loc]);

  return (
    <div style={{ maxWidth: "100vw" }}>
      {loading ? (
        <div></div>
      ) : (
        <div className="w-100 d-flex flex-column align-items-center pt-4 justify-content-center mt-xs-4 ox-hid pb-3">
          {isMobile ? (
            <Swiper
              effect={"cards"}
              grabCursor={true}
              modules={[Autoplay, EffectCards]}
              className="swiper-cards"
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
            >
              <SwiperSlide className="bg-white shadow pb-3">
                <div className="d-flex flex-column h-100 align-items-center justify-content-center w-100 px-4">
                  <Row className="h-100">
                    <Col xs={12} sm={12} md={6} lg={6}>
                      <div className="w-100 h-100 d-flex flex-column justify-content-center">
                        <div
                          className=" br-30 d-flex align-items-center justify-content-center p-0 mb-3"
                          style={{
                            height: "15rem",
                            width: "auto",
                            position: "relative",
                          }}
                        >
                          <LazyLoadImage
                            src={guitarrista}
                            style={{ height: "100%", width: "auto" }}
                          />
                        </div>

                        <h1 className={`headerTitle fs-1 text-warning text-center`}>
                          mariiachi.com
                        </h1>
                        <h4
                          className={`text-black headerTitle ${
                            isMobile ? "fs-1 ls-1 text-center" : "fs-3"
                          }`}
                        >
                          Descubre musicos y bandas para cualquier ocasión
                        </h4>

                        <Link
                          className={`btn btn-orange br-15 shadow-hvr py-2 btn-block`}
                          to={"/search"}
                        >
                          Descubre
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </SwiperSlide>

              {artists.map((ar, index) => {
                return (
                  <SwiperSlide className="blurColor pt-2" key={index + 1}>
                    <Link className="br-15 h-100 td-none" to={`/artist/${ar.uid}`}>
                      <b className="text-white px-3 fs-1 mb-0">Te presentamos a</b>
                      <div className="w-100 h-100 d-flex flex-column justify-content-around px-3">
                        <LazyLoadImage
                          src={ar.pp}
                          alt={ar.name}
                          className="shadow-lg br-15"
                          style={{
                            height: "15rem",
                            width: "auto",
                            objectFit: "contain",
                          }}
                        />
                        <div>
                          <h4 className="text-white headerTitle fs-2 mt-0 pt-0">{ar.name}</h4>
                          <p>
                            {ar.genres.map((el, ind) => {
                              if (ind <= 3) {
                                return (
                                  <Badge key={ind} pill bg="warning" text="white" className="mr-1">
                                    {el}
                                  </Badge>
                                );
                              } else if (ind === 4) {
                                return (
                                  <Badge key={ind} pill bg="warning" text="white" className="mr-1">
                                    ...
                                  </Badge>
                                );
                              }
                            })}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : (
            <Container>
              <div className="w-100 px-4 d-flex flex-column align-items-center pt-4 justify-content-center mt-xs-4">
                <Swiper
                  grabCursor={true}
                  effect={"creative"}
                  creativeEffect={{
                    prev: {
                      shadow: true,
                      translate: [0, 0, -400],
                    },
                    next: {
                      translate: ["100%", 0, 0],
                    },
                  }}
                  modules={[EffectCreative, Autoplay]}
                  autoplay={{
                    delay: 4500,
                    disableOnInteraction: false,
                  }}
                  className="mySwiper"
                >
                  <SwiperSlide className="bg-white shadow pb-3">
                    <div className="d-flex flex-column h-100 align-items-center justify-content-center w-100 px-4">
                      <Row className="h-100">
                        <Col xs={12} sm={12} md={6} lg={6}>
                          <div className="w-100 h-100 d-flex flex-column justify-content-center">
                            <h1
                              className={`headerTitle fs-1 text-warning ${
                                isMobile ? "text-center" : ""
                              }`}
                            >
                              mariiachi.com
                            </h1>
                            <h4
                              className={`text-black headerTitle ${
                                isMobile ? "fs-1 ls-1 text-center" : "fs-3"
                              }`}
                            >
                              Descubre musicos y bandas para cualquier ocasión
                            </h4>

                            <Link
                              className={`btn btn-orange br-15 shadow-hvr mt-2 py-2 `}
                              to={"/search"}
                            >
                              Descubre
                            </Link>
                          </div>
                        </Col>

                        <Col xs={12} sm={12} md={6} lg={6} className="h-100-md">
                          <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
                            <div className="patternBg br-30 d-flex align-items-center justify-content-center">
                              <LazyLoadImage src={guitarrista} className="carMar" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </SwiperSlide>

                  {artists.map((ar, index) => {
                    return (
                      <SwiperSlide className="bg-white shadow pb-3">
                        <Row className="h-100">
                          <Col xs={12} sm={12} md={6} lg={6}>
                            <div className="w-100 h-100 d-flex flex-column justify-content-center">
                              <p>Te presentamos a</p>
                              <h4 className="text-black headerTitle fs-3">{ar.name}</h4>
                              <p>
                                {ar.genres.map((el, ind) => {
                                  if (ind <= 3) {
                                    return (
                                      <Badge
                                        key={ind}
                                        pill
                                        bg="warning"
                                        text="white"
                                        className="mr-1"
                                      >
                                        {el}
                                      </Badge>
                                    );
                                  } else if (ind === 4) {
                                    return (
                                      <Badge
                                        key={ind}
                                        pill
                                        bg="warning"
                                        text="white"
                                        className="mr-1"
                                      >
                                        ...
                                      </Badge>
                                    );
                                  }
                                })}
                              </p>

                              <Link
                                className="btn btn-orange br-15 shadow-hvr py-2"
                                to={`/artist/${ar.uid}`}
                              >
                                Descubre
                              </Link>
                            </div>
                          </Col>

                          <Col xs={12} sm={12} md={6} lg={6} className="h-100-md">
                            <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
                              <div className="artistCarImg ">
                                <LazyLoadImage
                                  src={ar.pp}
                                  alt={ar.name}
                                  className="br-30 arImg shadow-lg"
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </Container>
          )}
        </div>
      )}
    </div>
  );
}

export default ArtistCarousel2;
