import React, { useMemo, useState } from "react";
const UserContext = React.createContext();

export function UserProvider(props) {
  const [user, setUser] = useState({});
  const [loc, setLoc] = useState({});
  const [showLoc, setShow] = useState(false);
  const [validLocs, setValidLocs] = useState([]);

  // useEffect(() => {

  // }, []);
  const setLocContext = (info) => {
    setLoc(info);
  };

  const setUserContext = (info) => {
    setUser(info);
  };

  const setShowLoc = (info) => {
    setShow(info);
  };

  const setValidLocsContext = (info) => {
    setValidLocs(info);
  };

  const value = useMemo(() => {
    return {
      validLocs,
      showLoc,
      user,
      loc,
      setLocContext,
      setUserContext,
      setShowLoc,
      setValidLocsContext,
    };
  }, [user, loc, showLoc, validLocs]);

  return <UserContext.Provider value={value} {...props} />;
}

export function UseUser() {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error("useUser should be inside userContext provider");
  }
  return context;
}
