import React, { useEffect } from "react";

import { Card } from "react-bootstrap";
import { scroller } from "react-scroll";
import "../App.css";
import NavLanding from "../Components/NavbarLanding";
import "../assets/css/theme.css";

import "animate.css/animate.min.css";
import { Container } from "react-bootstrap";
// import "../scss/indicator.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import AuthComponent from "../Components/Auth/Auth";
import Footer from "../Components/Footer";
import maracas from "../assets/img/maracas.png";

function Auth() {
  useEffect(() => {
    scroller.scrollTo("top", {
      smooth: true,
    });
  }, []);

  return (
    <div>
      {window.innerWidth > 500 ? (
        <div>
          <NavLanding />

          <div
            className="bannerAuth"
            id="top"
            style={{
              display: "flex",
              // alignItems: "stretch",
              flexDirection: "column",
            }}
          >
            <div className="mask-dark w-100 text-white">
              <div className="py-5 h-100 d-flex align-items-center justify-content-center">
                <Container className="d-flex align-items-center justify-content-center">
                  <Fade up delay={500}>
                    <Card className="px-5 my-4 mt-6 pb-3" style={{ borderRadius: "15px" }}>
                      <AuthComponent />
                    </Card>
                  </Fade>
                </Container>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="py-5 h-100 d-flex flex-column align-items-center justify-content-center">
          <br className="mt-5" />

          <div className="ccenter w-100">
            <Link to="/">
              <LazyLoadImage
                src={maracas}
                alt="mariiachi"
                title="mariiachi"
                className="mt-5"
                style={{
                  height: "5rem",
                  width: "auto",
                }}
              />
            </Link>
          </div>

          <AuthComponent />
        </div>
      )}

      <Footer />
    </div>
  );
}

export default Auth;
