import "firebase/compat/auth";
import "firebase/compat/firestore";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import "../App.css";

function ArtistCardVert({ artist }) {
  return (
    <div
      className="d-flex flex-column mr-4 my-3 pt-0"
      style={{
        width: "12rem",
        height: "17rem",
      }}
    >
      <Link to={`/artist/${artist.uid}`} className="btn-link text-secondary mb-0 mt-0">
        <LazyLoadImage
          src={
            artist.pp
              ? artist.pp
              : "https://firebasestorage.googleapis.com/v0/b/mariiachi-dca12.appspot.com/o/mariiachi(chido).png?alt=media&token=dcc361ad-4a3d-4527-b482-c4df7ae80042"
          }
          className="vertImg artistImg"
          alt={artist.name}
        />
      </Link>

      <div className="w-100 h-100 py-2 d-flex flex-column justify-content-space-between">
        <div>
          <Link to={`/artist/${artist.uid}`} className="btn-link title-artist mb-0">
            <div style={{ display: "inline" }}>
              <p className=" mb-0  title-artist" style={{ lineHeight: "99%" }}>
                {artist.verified && (
                  <i
                    className="fas fa-check-circle text-primary mb-0"
                    style={{ fontSize: "0.9rem" }}
                  ></i>
                )}{" "}
                {artist.name}
              </p>
            </div>
          </Link>
        </div>
        <div className="w-100">
          <div className="divGenres w-100">
            {artist.genres.map((el, ind) => {
              if (ind <= 1) {
                return (
                  <span key={ind} className="mr-1 genreVert">
                    {el} {artist.genres.length > 1 ? "," : ""}
                  </span>
                );
              } else if (ind === 2) {
                return (
                  <span key={ind} className="mr-1 genreVert">
                    ...
                  </span>
                );
              }
            })}
          </div>
          <div className="dividerOrange "></div>
          {artist.fanCount != null && artist.fanCount != undefined && artist.fanCount > 0 && (
            <p className="favVert pb-0 mb-0">Favorito de {artist.fanCount} usuarios</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ArtistCardVert;
