import React, { useEffect, useState } from "react";

import "animate.css/animate.min.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { Button, Card, Container, Toast } from "react-bootstrap";
import { scroller } from "react-scroll";
import "../App.css";
import NavLanding from "../Components/NavbarLanding";
import "../assets/css/theme.css";
// import "../scss/indicator.scss";
import Fade from "react-reveal/Fade";
import Footer from "../Components/Footer";

function VerifyEmail() {
  const [free, setFree] = useState(false);

  const [showA, setShowA] = useState(false);

  // const toggleShowA = () => setShowA(!showA);

  const sendEmail = () => {
    var actionCodeSettings = {
      url: "https://app.mariiachi.com/auth#login",
    };
    firebase
      .auth()
      .currentUser.sendEmailVerification(actionCodeSettings)
      .then(() => {
        setShowA(true);
        setFree(false);
        setTimeout(() => {
          setFree(true);
        }, 180000);
      })
      .catch((er) => {
        alert(er);
      });
  };

  useEffect(() => {
    scroller.scrollTo("top", {
      smooth: true,
    });
    firebase.auth().onAuthStateChanged((authUser) => {
      console.log(authUser.uid);
      if (!authUser) {
        console.log("sin usuario");
        window.location = "/auth";
      } else {
        sendEmail();
      }
    });

    setTimeout(() => {
      setFree(true);
    }, 300000);
  }, []);

  return (
    <div style={{ overflowX: "hidden" }}>
      <NavLanding verify />

      <Toast
        onClose={() => setShowA(false)}
        show={showA}
        delay={3000}
        autohide
        style={{ position: "fixed", bottom: "1rem", left: "1rem" }}
        className="px-2 py-1"
      >
        <Toast.Body className="text-warning">Correo Enviado</Toast.Body>
      </Toast>

      <div className="overflow-hidden bannerAuth" id="top">
        <div className="mask-dark w-100 text-white h-100">
          <div className="scale-down h-100 d-flex align-items-center justify-content-center">
            <Container className="d-flex align-items-center justify-content-center">
              <Fade up delay={500}>
                <Card
                  className="px-5 my-4 mt-6 pb-3 br-30"
                  // style={{ borderRadius: "15px" }}
                >
                  <Card.Header className="d-flex align-items-center justify-content-center text-warning">
                    <h4 className="text-warning text-center">Verifica tu correo electrónico</h4>
                  </Card.Header>

                  <p className="text-secondary text-center fs-1">
                    Enviamos un correo para que verifiques tu correo electrónico. Puede tardar unos
                    pocos minutos.
                    <br /> <strong>Recuerda revisar el folder de spam.</strong>
                  </p>

                  <p className="text-secondary text-center fs-1 mt-1">
                    Si no llegó el correo puedes volver a enviarlo con este botón de abajo.
                  </p>
                  <Button
                    variant="warning"
                    className="btn-block"
                    disabled={free ? false : true}
                    onClick={(e) => {
                      e.preventDefault();
                      sendEmail();
                    }}
                  >
                    {free ? "Enviar de nuevo" : "Espera 3 min."}
                  </Button>
                  <br />
                  <br />
                  <p className="text-secondary text-center fs-1">
                    Si tienes alguna <strong>duda o problema</strong>, contáctanos.
                  </p>

                  <div className="d-flex justify-content-center align-items-center">
                    <a
                      href="mailto:contacto.mariiachi@gmail.com"
                      className="text-warning mr-3 fs-1 font-weight-semi-bold"
                    >
                      contacto.mariiachi@gmail.com
                    </a>
                    <a
                      className="mr-3"
                      target="__blank"
                      href="https://www.facebook.com/Mariiachi-109446741632513"
                    >
                      {/* <img
                        //   className="process-icon"
                        href=""
                        src={fb}
                        alt=""
                        style={{ height: "2rem", width: "auto" }}
                      /> */}
                      <i
                        className="fab fa-facebook-square"
                        style={{
                          fontSize: "2.5rem",
                          width: "auto",
                          color: "#f5803e",
                        }}
                      ></i>
                    </a>
                    <a
                      // className="mr-3"
                      target="__blank"
                      href="https://www.instagram.com/_mariiachi.com_/"
                    >
                      {/* <img
                        //   className="process-icon"
                        src={ig}
                        alt=""
                        style={{ height: "2rem", width: "auto" }}
                      /> */}
                      <i
                        className="fab fa-instagram"
                        style={{
                          fontSize: "2.5rem",
                          width: "auto",
                          color: "#f5803e",
                        }}
                      ></i>
                    </a>
                  </div>
                </Card>
              </Fade>
            </Container>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default VerifyEmail;
