import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { Autoplay, EffectCreative } from "swiper";
import "swiper/css";
import "swiper/css/effect-creative";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../App.css";
import AmazonAd from "../../Components/AmazonAd";
import Footer from "../../Components/Footer";
import NavApp from "../../Components/NavApp";
import NavbarLanding from "../../Components/NavbarLanding";
import { UseUser } from "../../Context/userContext";
import drums1 from "../../assets/img/instruments/drums1.png";
import guitar2 from "../../assets/img/instruments/guitar2.png";
import anim from "../../assets/img/mariachib.svg";
import pro from "../../assets/img/pronetwork.png";
import ggs from "../../assets/img/sponsors/logo-ggs.png";
import imgF from "../../assets/img/sponsors/logo-img.png";
import swirvle from "../../assets/img/sponsors/logo_swirvle.png";

import ArtistCarousel2 from "./ArtistCarousel2";
import DiscoverSection from "./DiscoverSection";
import GenreSection from "./GenreSection";
import PopularSection from "./PopularSection";
function AppHome() {
  var width = window.innerWidth;
  const { loc, setLocContext, user, setUserContext, setShowLoc } = UseUser();
  const [favArtists, setFavs] = useState([]);
  const ref = useRef(null);

  useEffect(() => {
    if (loc.country == undefined || loc.country == null) {
      setShowLoc(true);
    }
  }, [loc, user]);

  return (
    <Container fluid className="home-container" style={{ maxWidth: "100vw" }}>
      <div className="w-100">
        {user.email ? <NavApp /> : <NavbarLanding />}
        <br className="mb-5" />
        <br className="mb-5" />
        <br className="mb-5" />

        <ArtistCarousel2 loc={loc} />
        <Container className="w-100 ">
          <Row className="mt-2">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={2}
              className=" d-sm-none d-md-block"
              style={{
                minHeight: "5rem",
              }}
            >
              <div
                className="d-flex flex-column align-items-center justify-content-center"
                style={{ position: "sticky", top: "80px" }}
              >
                <AmazonAd />
                {/* <AmazonAd /> */}
              </div>
            </Col>

            <Col xs={12} sm={12} md={12} lg={8} className="p-0 w-100 mainHome">
              <div>
                {loc ? (
                  <div className="mb-3">
                    <br />
                    <h4 className="mt-4 section-title px-3">
                      Populares en {loc.state}, {loc.country}
                    </h4>
                    <PopularSection loc={loc} more />
                    <h4 className="mt-2 section-title px-3 w-100 d-flex align-items-center justify-content-between">
                      Descubre el Talento
                    </h4>
                    <DiscoverSection loc={loc} />

                    <div className="w-100 text-center mb-3 px-3">
                      <h3 className="text-black headerTitle">Busca músicos y bandas</h3>
                      <p className="text-jakarta">
                        Mariachis, saxofonistas, bandas de rock, musica ambiental, pianistas,
                        marimba, para cualquier ocasión
                      </p>
                    </div>

                    <div className="shadow-lg d-flex flex-row align-items-center justify-content-between p-3 br-10 mt-3  bg-white d-md-none">
                      <div className="ccenter flex-column">
                        {loc != null && loc != undefined && (
                          <div
                            className="d-flex flex-row px-0 text-center align-items-center "
                            style={{
                              position: "relative",
                            }}
                          >
                            <Button
                              variant="link"
                              className="text-warning whitesmoke-hvr ml-2 m-0 link pulse-lg"
                              onClick={(e) => {
                                // e.preventDefault();
                                // setModal(true);
                                // setLoading(true);
                                setShowLoc(true);
                              }}
                            >
                              <p className="text-center text-location h-100 p-0 m-0  br-15">
                                <i className="fa-solid fa-location-dot mr-2 br-30"></i>
                                {loc.state}, {loc.country}
                              </p>
                            </Button>
                          </div>
                        )}
                      </div>
                      <div className="ml-3 h-100 ccenter">
                        <Link to="/search" className="btn btn-warning px-4 py-2 br-10">
                          Buscar
                        </Link>
                      </div>
                    </div>

                    <div
                      className="shadow-lg flex-row align-items-center justify-content-between bg-white p-3 br-10 mt-3 ttop d-none d-md-flex"
                      style={{
                        position: "sticky",
                        top: "4.9rem",
                      }}
                    >
                      <div className="ccenter flex-column">
                        {loc != null && loc != undefined && (
                          <div
                            className="d-flex flex-row px-0 text-center align-items-center ttop"
                            style={{
                              position: "relative",
                            }}
                          >
                            <Button
                              variant="link"
                              className="text-warning whitesmoke-hvr ml-2  m-0 link pulse-lg"
                              onClick={(e) => {
                                // e.preventDefault();
                                // setModal(true);
                                // setLoading(true);
                                setShowLoc(true);
                              }}
                            >
                              <p className="text-center text-location h-100 p-0 m-0  br-15">
                                <i className="fa-solid fa-location-dot mr-2  br-30"></i>
                                {loc.state}, {loc.country}
                              </p>
                            </Button>
                          </div>
                        )}
                      </div>
                      <div className="ml-3 h-100 ccenter">
                        <Link to="/search" className="btn btn-warning px-4 py-2 br-10">
                          Buscar
                        </Link>
                      </div>
                    </div>
                    <br />
                    <h4 className="mt-2 section-title px-3 w-100 d-flex align-items-center justify-content-between">
                      Para Serenata
                    </h4>
                    <br />
                    <GenreSection loc={loc} genre={["mariachi", "balada"]} />
                    <h4 className="mt-2 section-title px-3 w-100 d-flex align-items-center justify-content-between mb-2">
                      Para Rockear
                    </h4>
                    <GenreSection loc={loc} genre={["classic rock", "rock metal", "hard rock"]} />
                  </div>
                ) : (
                  <div />
                )}

                {user.email ? (
                  <div>
                    {/* <h4 className="mt-2 section-title px-3 w-100 d-flex align-items-center justify-content-between mb-2">
                      Tus Favoritos{" "}
                    </h4>
                    <div className="d-xs-none d-sm-none d-md-block">
                      <div className="w-100 d-flex mb-3" style={{ flexDirection: "row-reverse" }}>
                        <Button
                          variant="warning"
                          style={{
                            height: "2rem",
                            width: "2rem",
                            borderRadius: "50%",
                            marginRight: "1.5rem",
                            marginLeft: "0.5rem",
                          }}
                          onClick={() => {
                            ref.current.scrollLeft += 130;
                          }}
                        >
                          <i className="fas fa-chevron-right ccenter"></i>
                        </Button>
                        <Button
                          variant="warning"
                          style={{
                            height: "2rem",
                            width: "2rem",
                            borderRadius: "50%",
                          }}
                          onClick={() => {
                            ref.current.scrollLeft += -130;
                          }}
                        >
                          <i className="fas fa-chevron-left ccenter"></i>
                        </Button>
                        <Link
                          className="text-warning font-weight-normal mr-4"
                          to="/account#favoritos"
                          style={{ fontSize: "1rem" }}
                        >
                          Ver más
                        </Link>
                      </div>
                    </div>
                    <div className="w-100 ccenter sbar" style={{ height: "23rem" }}>
                      <div className="w-100 smooth" style={{ overflowX: "scroll" }} ref={ref}>
                        <div
                          className="ccenter flex-row pb-4 px-3"
                          style={{
                            width: "max-content",
                            minWidth: "100%",
                            overflowY: "hidden",
                          }}
                        >
                          {favArtists.length > 0 ? (
                            favArtists.map((artiist) => {
                              return <ArtistCardVert artist={artiist} />;
                            })
                          ) : (
                            <p className="px-2 w-100 text-center font-weight-semi-bold">
                              ¡No tienes músicos o bandas favoritas todavía! ¡Búsca y guárdalos como
                              favoritos!
                            </p>
                          )}
                        </div>
                      </div>
                    </div> */}
                  </div>
                ) : (
                  // <div className="w-100 ccenter sbar" style={{ height: "23rem" }}>
                  //   <p className="px-2 w-100 text-center font-weight-semi-bold">
                  //     Regístrate o inicia sesión para guardar a tus favoritos
                  //   </p>
                  // </div>
                  <div />
                )}

                <Swiper
                  grabCursor={true}
                  effect={"creative"}
                  creativeEffect={{
                    prev: {
                      shadow: true,
                      translate: [0, 0, -400],
                    },
                    next: {
                      translate: ["100%", 0, 0],
                    },
                  }}
                  modules={[EffectCreative, Autoplay]}
                  autoplay={{
                    delay: 4500,
                    disableOnInteraction: false,
                  }}
                  className="swiperSearch"
                >
                  <SwiperSlide className="br-30 overflow-hidden searchSlide">
                    <Container fluid className="text-white w-100 justify-content-center pb-1">
                      <Row className="w-100 justify-content-center">
                        <Col xs={12} md={6} lg={6}>
                          <div className="px-2 h-100 d-flex flex-column justify-content-center">
                            <p className="font-weight-bold text-jakarta">
                              ¿Eres músico o tienes una banda?
                            </p>
                            <h4 className="text-black text-jakarta">
                              Regístrate para darte a conocer
                            </h4>

                            {user.email ? (
                              <Link
                                className="btn btn-danger btn-block p-2 br-15"
                                to="/account#artista"
                              >
                                Crea tu página de artista
                              </Link>
                            ) : (
                              <div className="d-flex align-items-baseline">
                                <Link className="btn btn-danger p-1 px-3  br-15" to="/auth#regis">
                                  Regístrate
                                </Link>
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col xs={12} md={6} lg={6} className="pb-2">
                          <div className="mgSearch">
                            <LazyLoadImage src={anim} className="srchImg" alt="mariachi" />
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </SwiperSlide>

                  <SwiperSlide className="br-30 overflow-hidden searchSlide">
                    <Container fluid className="text-white">
                      <Row>
                        <Col xs={12} md={6} lg={6}>
                          <div className="px-2 h-100 d-flex flex-column justify-content-center">
                            <h4 className="text-black text-jakarta">
                              Encuentra músicos y bandas para cualquier ocasión
                            </h4>
                            <p className="font-weight-bold">
                              ¡Podrás encontrar de cualquier género musical!
                            </p>

                            {user.email ? (
                              <Link
                                className="btn btn-danger btn-block p-2 br-15"
                                to="/account#artista"
                              >
                                Crea tu página de artista
                              </Link>
                            ) : (
                              <div className="d-flex align-items-baseline">
                                <Link className="btn btn-danger p-1 px-3  br-15" to="/auth#regis">
                                  Regístrate
                                </Link>
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col xs={12} md={6} lg={6} className="pb-2">
                          <div className="mgSearch">
                            <LazyLoadImage src={drums1} className="srchImg" alt="quinceaños" />
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </SwiperSlide>

                  <SwiperSlide className="br-30 overflow-hidden searchSlide">
                    <Container fluid className="text-white ccenter h-100">
                      <Row>
                        <Col xs={12} md={6} lg={6}>
                          <div className="px-2 h-100 d-flex flex-column justify-content-center">
                            <h4 className="text-black text-jakarta">
                              Encuentra músicos y bandas para cualquier ocasión
                            </h4>
                            <p className="font-weight-bold text-jakarta">
                              ¡Podrás encontrar de cualquier género musical!
                            </p>

                            {user.email ? (
                              <Link
                                className="btn btn-danger btn-block p-2 br-15"
                                to="/account#artista"
                              >
                                Crea tu página de artista
                              </Link>
                            ) : (
                              <div className="d-flex align-items-baseline">
                                <Link className="btn btn-danger p-1 px-3  br-15" to="/auth#regis">
                                  Regístrate
                                </Link>
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col xs={12} md={6} lg={6} className="pb-2">
                          <div className="mgSearch">
                            <LazyLoadImage src={guitar2} className="srchImg" alt="bodas" />
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </SwiperSlide>
                </Swiper>

                <div className="d-flex flex-column align-items-center justify-content-center mt-4 mb-5 pt-6">
                  <p className="text-secondary headerTitle ls-1 text-center">
                    AGRADECEMOS A NUESTROS COLABORADORES
                  </p>

                  <Row className="d-flex align-items-center justify-content-center mb-4">
                    <Col className="d-flex flex-column align-items-center justify-content-center">
                      <a href="https://grupogasolinerosolidaridad.com" target="_blank">
                        <LazyLoadImage
                          alt="gasolinera solidaridad"
                          src={ggs}
                          style={{ width: "7rem" }}
                        />
                      </a>
                    </Col>
                    <Col className="d-flex flex-column align-items-center justify-content-center">
                      <a href="https://imagefactorymty.com/" target="_blank">
                        <LazyLoadImage
                          alt="image factory monterrey"
                          src={imgF}
                          style={{ width: "7rem" }}
                        />
                      </a>
                    </Col>
                    <Col className="d-flex flex-column align-items-center justify-content-center">
                      <a href="https://swirvle.com/" target="_blank">
                        <LazyLoadImage alt="Swirvle" src={swirvle} style={{ width: "7rem" }} />
                      </a>
                    </Col>
                  </Row>

                  <p className="text-secondary headerTitle ls-1">Medios</p>

                  <Row className="d-flex align-items-center justify-content-center">
                    <Col className="d-flex flex-column align-items-center justify-content-center">
                      <a
                        href="https://www.pronetwork.mx/mariiachi-com-descubre-la-nueva-forma-para-buscar-musicos-y-bandas/"
                        target="_blank"
                      >
                        <LazyLoadImage alt="Pro network" src={pro} style={{ width: "6.5rem" }} />
                      </a>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>

            <Col xs={12} sm={12} md={12} lg={2}>
              <div
                className="d-flex flex-column align-items-center  justify-content-center"
                style={{ position: "sticky", top: "80px" }}
              >
                <AmazonAd />
                {/* <AmazonAd /> */}
              </div>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    </Container>
  );
}

export default AppHome;
