import "firebase/compat/auth";
import "firebase/compat/firestore";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import "../App.css";

function ArtistCircle({ artist }) {
  return (
    <div
      className="d-flex flex-column align-items-center mr-1 my-1"
      style={{
        width: "8rem",
        height: "8rem",
      }}
    >
      <Link to={`/artist/${artist.uid}`} className="btn-link text-secondary mb-0 mt-0">
        <LazyLoadImage
          src={
            artist.pp
              ? artist.pp
              : "https://firebasestorage.googleapis.com/v0/b/mariiachi-dca12.appspot.com/o/mariiachi(chido).png?alt=media&token=dcc361ad-4a3d-4527-b482-c4df7ae80042"
          }
          // className="vertImg artistImg"
          style={{
            width: "5rem",
            height: "5rem",
            borderRadius: "50%",
          }}
          alt={artist.name}
        />
      </Link>

      {/* <div className="w-100 h-100 py-2 d-flex flex-column justify-content-center"> */}
      {/* <div> */}
      <Link to={`/artist/${artist.uid}`} className="btn-link text-center mb-0">
        <div className="mt-2">
          <p className="mb-0 title-artist" style={{ lineHeight: "99%" }}>
            {artist.verified && (
              <i
                className="fas fa-check-circle text-primary mb-0"
                style={{ fontSize: "0.9rem" }}
              ></i>
            )}{" "}
            {artist.name}
          </p>
        </div>
      </Link>
      {/* </div> */}
      {/* </div> */}
    </div>
  );
}

export default ArtistCircle;
