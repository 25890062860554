import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPlayer from "react-player";
import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import NavApp from "../Components/NavApp";

import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Toast,
} from "react-bootstrap";
import {
  EmailShareButton,
  FacebookShareButton,
  PinterestShareButton,
  RedditShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import AmazonAd from "../Components/AmazonAd";
import Footer from "../Components/Footer";
import NavLanding from "../Components/NavbarLanding";
import { UseUser } from "../Context/userContext";
import pinPoint from "../assets/img/icons/map-marker.png";
import SuggestionsCard from "./SuggestionsCard";

function ArtistPage() {
  const { user, setUserContext, setLocContext } = UseUser();
  const navigate = useNavigate();
  const [motive, setMotive] = useState("");
  const [loading, setLoading] = useState(true);
  const [fans, setFans] = useState([]);
  const [artistId, setArtistId] = useState("");
  const [modalContact, setModalContact] = useState(false);
  const [artistInfo, setArtistInfo] = useState({});
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [login, setLogin] = useState(false);
  const [modalShow, setModal] = useState(false);
  const [modalShare, setModalShare] = useState(false);
  const [userId, setUserId] = useState(null);
  const [fav, setFav] = useState(false);
  const [modalReport, setModalReport] = useState(false);

  function copyLink() {
    const elem = document.createElement("textarea");
    elem.value = "app.mariiachi.com" + window.location.pathname;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 2500);
  }

  const toggleFav = () => {
    var fanArr = [];
    if (!fav) {
      firebase
        .firestore()
        .collection("artists")
        .doc(artistId)
        .get()
        .then((doc) => {
          if (doc.data().fans != null && doc.data().fans != undefined) {
            fanArr = doc.data().fans;
          }
          if (fanArr.includes(userId) == false) {
            fanArr.push(userId);
          }
        })
        .then(() => {
          firebase.firestore().collection("artists").doc(artistId).set(
            {
              fans: fanArr,
              fanCount: fanArr.length,
            },
            { merge: true }
          );
        })
        .then(() => {
          var favArr = [];
          firebase
            .firestore()
            .collection("users")
            .doc(userId)
            .get()
            .then((doc) => {
              if (doc.data().favs != null && doc.data().favs != undefined) {
                favArr = doc.data().favs;
              }
              if (!favArr.includes(artistId)) {
                favArr.push(artistId);
              }
            })
            .then(() => {
              firebase.firestore().collection("users").doc(userId).set(
                {
                  favs: favArr,
                },
                { merge: true }
              );
              setFav(true);
              setFans(fanArr);
            });
        })
        .catch((er) => {
          console.log(er);
          alert(er);
        });
    } else {
      firebase
        .firestore()
        .collection("artists")
        .doc(artistId)
        .get()
        .then((doc) => {
          if (doc.data().fans != null && doc.data().fans != undefined) {
            fanArr = doc.data().fans;
            const index = fanArr.indexOf(userId);
            if (index > -1) {
              fanArr.splice(index, 1);
            }
          }
        })
        .then(() => {
          firebase.firestore().collection("artists").doc(artistId).set(
            {
              fans: fanArr,
              fanCount: fanArr.length,
            },
            { merge: true }
          );
        })
        .then(() => {
          var favArr = [];
          firebase
            .firestore()
            .collection("users")
            .doc(userId)
            .get()
            .then((doc) => {
              if (doc.data().favs != null && doc.data().favs != undefined) {
                favArr = doc.data().favs;

                const index2 = favArr.indexOf(artistId);
                if (index2 > -1) {
                  favArr.splice(index2, 1);
                }
                setFav(false);
                setFans(fanArr);
              }
            })
            .then(() => {
              firebase.firestore().collection("users").doc(userId).set(
                {
                  favs: favArr,
                },
                { merge: true }
              );
            });
        })
        .catch((er) => {
          console.log(er);
          alert(er);
        });
    }
  };

  const reportPage = () => {
    if (motive != "" && motive != " ") {
      firebase
        .firestore()
        .collection("reportedArtists")
        .doc()
        .set({
          reported: true,
          artistId: artistId,
          user: userId,
          reportReason: motive,
        })
        .then(() => {
          setModalReport(false);
        });
    } else {
      alert("Por favor agrega un motivo.");
    }
  };
  var addContactToArtist = firebase.functions().httpsCallable("addContactToArtist");
  var addShareToArtist = firebase.functions().httpsCallable("addShareToArtist");

  useEffect(() => {
    var addVisitToArtist = firebase.functions().httpsCallable("addVisitToArtist");
    setFav(false);
    setLoading(true);
    var idd = "";
    firebase.auth().onAuthStateChanged((authUser) => {
      if (!authUser) {
      } else {
        setUserId(authUser.uid);
        idd = authUser.uid;
      }
    });

    var urll = window.location.pathname;
    var artId = urll.split("/")[2];
    setArtistId(artId);
    if (artId != undefined) {
      var artistObj = new Object();
      firebase
        .firestore()
        .collection("artists")
        .doc(artId)
        .get()
        .then((page) => {
          artistObj.name = page.data().name;
          artistObj.uid = page.data().uid;
          artistObj.description = page.data().description;
          artistObj.telephone = page.data().telephone;
          artistObj.email = page.data().email;
          artistObj.fb = page.data().facebook;
          artistObj.ig = page.data().instagram;
          artistObj.yt = page.data().youtube;
          artistObj.sp = page.data().spotify;
          artistObj.sc = page.data().soundcloud;
          artistObj.active = page.data().status;
          artistObj.video = page.data().video;
          artistObj.currency = page.data().currency;
          artistObj.instruments = page.data().instruments;
          artistObj.genres = page.data().genres;
          artistObj.blocked = page.data().blocked;
          var arFan = page.data().fans ? page.data().fans : [];
          setFans(arFan);
          if (idd != "") {
            if (arFan != null && arFan != undefined) {
              if (arFan.includes(idd)) {
                setFav(true);
              }
            }
          }

          artistObj.pp = page.data().pp;
          artistObj.country = page.data().country;
          artistObj.state = page.data().state;
          artistObj.price = page.data().price;
          artistObj.arrange = page.data().arrange;
          artistObj.verified = page.data().verified;
          setArtistInfo(artistObj);

          setLocContext({
            state: page.data().state,
            country: page.data().country,
            countryCode: page.data().country,
            currency: page.data().currency,
          });
        })
        .then(() => {
          document.querySelector("title").innerHTML = "Mariiachi.com | " + artistObj.name;
          setLoading(false);
          addVisitToArtist({ artistUid: artistObj.uid }).then(() => {
            console.log("visita hecha");
          });
        })
        .catch((err) => {
          setLoading(false);
          alert(err.message);
        });
    }
  }, [window.location.pathname]);

  return (
    <Container fluid>
      <Modal
        show={modalContact}
        onHide={() => setModalContact(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        controls
      >
        <Modal.Header>
          <div className="d-flex w-100 align-items-center justify-content-between px-1">
            <h4 className="text-black">Datos de Contacto</h4>
            <Button
              variant="link"
              className="td-none"
              onClick={(e) => {
                e.preventDefault();
                setModalContact(false);
              }}
            >
              <i className="fa-solid fa-xmark-large text-warning text-center fs-1"></i>
            </Button>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Container>
            {artistInfo.telephone != null &&
              artistInfo.telephone.length != undefined &&
              artistInfo.telephone.length > 7 && (
                <div className="d-flex w-100 flex-row align-items-center justify-content-start text-center">
                  <div className="d-flex w-100 flex-row align-items-baseline justify-content-center h-100 text-center">
                    <p className="arIcon text-center d-flex align-items-center justify-content-center">
                      <i className="fa-solid fa-phone text-white "></i>
                    </p>
                    <Row className="w-100 ml-2">
                      <Col xs={12} sm={12} md={12} lg={3} className="d-flex align-items-start">
                        <p className="text-black text-jakarta ml-1 font-weight-semi-bold">
                          Teléfono
                        </p>
                      </Col>

                      <Col xs={12} sm={12} md={12} lg={9} className="d-flex align-items-start">
                        <p className="ml-1 text-secondary">{artistInfo.telephone}</p>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}

            {artistInfo.email != null && artistInfo.email != undefined && (
              <div className="d-flex w-100 flex-row align-items-center justify-content-start h-100 text-center">
                <div className="d-flex w-100 flex-row align-items-baseline justify-content-center h-100 text-center">
                  <p className="arIcon text-center d-flex align-items-center justify-content-center">
                    <i className="fa-solid fa-envelope text-white "></i>
                  </p>

                  <Row className="w-100 ml-2">
                    <Col xs={12} sm={12} md={12} lg={3} className="d-flex align-items-start ">
                      <p className="text-black text-jakarta ml-1 font-weight-semi-bold">Email</p>
                    </Col>

                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={9}
                      className="d-flex align-items-start text-wrap"
                    >
                      <p className="ml-1 text-secondary text-wrap text-left">{artistInfo.email}</p>
                    </Col>
                  </Row>
                </div>
              </div>
            )}

            {artistInfo.fb || artistInfo.ig || artistInfo.yt || artistInfo.sp || artistInfo.sc ? (
              <div className="d-flex w-100 flex-row align-items-center justify-content-start h-100 text-center">
                <div className="d-flex w-100 flex-row align-items-baseline justify-content-center h-100 text-center">
                  <p className="arIcon text-center d-flex align-items-center justify-content-center">
                    <i className="fa-solid fa-hashtag text-white"></i>
                  </p>

                  <Row className="w-100 ml-2">
                    <Col xs={12} sm={12} md={12} lg={3} className="d-flex align-items-start ">
                      <p className="text-black text-jakarta ml-1 font-weight-semi-bold">
                        Redes Sociales
                      </p>
                    </Col>

                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={9}
                      className="d-flex align-items-start text-wrap"
                    >
                      <div className="d-flex flex-row justify-content-flex-start">
                        {artistInfo.fb && (
                          <a className="mr-3 " target="__blank" href={"https://" + artistInfo.fb}>
                            <i
                              className="fab fa-facebook-square"
                              style={{
                                fontSize: "2rem",
                                width: "auto",
                                color: "#f5803e",
                              }}
                            ></i>
                          </a>
                        )}
                        {artistInfo.ig && (
                          <a className="mr-3 " target="__blank" href={"https://" + artistInfo.ig}>
                            <i
                              className="fab fa-instagram"
                              style={{
                                fontSize: "2rem",
                                width: "auto",
                                color: "#f5803e",
                              }}
                            ></i>
                          </a>
                        )}
                        {artistInfo.yt && (
                          <a className="mr-3 " target="__blank" href={"https://" + artistInfo.yt}>
                            <i
                              className="fab fa-youtube"
                              style={{
                                fontSize: "2rem",
                                width: "auto",
                                color: "#f5803e",
                              }}
                            ></i>
                          </a>
                        )}
                        {artistInfo.sp && (
                          <a className="mr-3 " target="__blank" href={"https://" + artistInfo.sp}>
                            <i
                              className="fab fa-spotify"
                              style={{
                                fontSize: "2rem",
                                width: "auto",
                                color: "#f5803e",
                              }}
                            ></i>
                          </a>
                        )}
                        {artistInfo.sc && (
                          <a className="mr-3 " target="__blank" href={"https://" + artistInfo.sc}>
                            <i
                              className="fab fa-brands fa-soundcloud"
                              style={{
                                fontSize: "2rem",
                                width: "auto",
                                color: "#f5803e",
                              }}
                            ></i>
                          </a>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </Container>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalReport}
        onHide={() => setModalReport(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        controls
      >
        <Modal.Header>
          <div className="d-flex w-100 align-items-center justify-content-between px-1">
            <h4 className="text-black">Reportar Página</h4>
            <Button
              variant="link"
              className="td-none"
              onClick={(e) => {
                e.preventDefault();
                setModalReport(false);
              }}
            >
              <i className="fa-solid fa-xmark-large text-warning text-center fs-1"></i>
            </Button>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Container>
            <Form autocomplete="off">
              <Form.Group controlId="formBasicEmail" className="mb-4 mt-2">
                <Form.Label className="text-secondary">
                  ¿Por que razón deseas reportar esta página?
                </Form.Label>

                <Form.Control
                  onChange={(thiss) => {
                    setMotive(thiss.target.value);
                  }}
                  name="motive"
                  value={motive}
                  type="text"
                  className="shadow-sm"
                  placeholder="Razón / motivo por el cual se reporta la página"
                />
              </Form.Group>
            </Form>
            <Button
              variant="outline-danger"
              className="btn btn-block br-10"
              onClick={(e) => {
                e.preventDefault();
                reportPage();
              }}
            >
              REPORTAR
            </Button>
          </Container>
        </Modal.Body>
      </Modal>

      <Toast
        onClose={() => setTooltipOpen(false)}
        show={tooltipOpen}
        delay={3000}
        autohide
        style={{
          position: "fixed",
          bottom: "1rem",
          left: "1rem",
          zIndex: 999999999999,
          backgroundColor: "#F68D1E",
        }}
        className="px-2 py-1 br-15"
      >
        <Toast.Body className="text-white font-weight-bold" style={{ backgroundColor: "#F68D1E" }}>
          ¡Link Copiado!
        </Toast.Body>
      </Toast>

      <Modal
        show={modalShare}
        onHide={() => setModalShare(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter-share"
        centered
        controls
      >
        <Modal.Header>
          <div className="d-flex w-100 align-items-center justify-content-between px-1">
            <h4 className="text-black">Comparte</h4>
            <Button
              variant="link"
              className="td-none"
              onClick={(e) => {
                e.preventDefault();
                setModalShare(false);
              }}
            >
              <i className="fa-solid fa-xmark-large text-warning text-center fs-1"></i>
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Container className="ccenter">
            <Row className="ccenter">
              <Col xs={3} md={1}>
                <EmailShareButton
                  className="ml-2 mr-2 td-none"
                  url={"app.mariiachi.com" + window.location.pathname}
                  subject={artistInfo.name + " en mariiachi.com"}
                  body={
                    artistInfo.name +
                    " en mariiachi.com. Visita su página de artista: " +
                    ("app.mariiachi.com" + window.location.pathname)
                  }
                >
                  <i className="fa-solid fa-envelope text-warning text-center fs-3"></i>
                </EmailShareButton>
              </Col>
              <Col xs={3} md={1}>
                <FacebookShareButton
                  className="ml-2 mr-2 td-none"
                  onClick={(e) => {
                    e.preventDefault();
                    addShareToArtist({ artistUid: artistInfo.uid }).then(() => {
                      console.log("compartir");
                    });
                  }}
                  url={"app.mariiachi.com" + window.location.pathname}
                  hashtag="#mariiachi"
                  quote={artistInfo.name}
                >
                  <i className="fa-brands fa-facebook text-warning text-center fs-3"></i>
                </FacebookShareButton>
              </Col>
              <Col xs={3} md={1}>
                <PinterestShareButton
                  className="ml-2 mr-2 td-none"
                  onClick={(e) => {
                    e.preventDefault();
                    addShareToArtist({ artistUid: artistInfo.uid }).then(() => {
                      console.log("compartir");
                    });
                  }}
                  url={"app.mariiachi.com" + window.location.pathname}
                  description={artistInfo.name + " en mariiachi.com"}
                  media={
                    artistInfo.pp
                      ? artistInfo.pp
                      : "https://firebasestorage.googleapis.com/v0/b/mariiachi-dca12.appspot.com/o/mariiachi(chido).png?alt=media&token=dcc361ad-4a3d-4527-b482-c4df7ae80042"
                  }
                >
                  <i className="fa-brands fa-pinterest text-warning text-center fs-3"></i>
                </PinterestShareButton>
              </Col>
              <Col xs={3} md={1}>
                <RedditShareButton
                  className="ml-2 mr-2 td-none"
                  onClick={(e) => {
                    e.preventDefault();
                    addShareToArtist({ artistUid: artistInfo.uid }).then(() => {
                      console.log("compartir");
                    });
                  }}
                  url={"app.mariiachi.com" + window.location.pathname}
                  title={artistInfo.name + " en mariiachi.com"}
                >
                  <i className="fa-brands fa-reddit text-warning text-center fs-3"></i>
                </RedditShareButton>
              </Col>
              <Col xs={3} md={1}>
                <TwitterShareButton
                  className="ml-2 mr-2 td-none mt-xs-2"
                  onClick={(e) => {
                    e.preventDefault();
                    addShareToArtist({ artistUid: artistInfo.uid }).then(() => {
                      console.log("compartir");
                    });
                  }}
                  url={"app.mariiachi.com" + window.location.pathname}
                  title={artistInfo.name + " en mariiachi.com"}
                >
                  <i className="fa-brands fa-twitter text-warning text-center fs-3"></i>
                </TwitterShareButton>
              </Col>
              <Col xs={3} md={1}>
                <WhatsappShareButton
                  className="ml-2 mr-2 td-none mt-xs-2"
                  onClick={(e) => {
                    e.preventDefault();
                    addShareToArtist({ artistUid: artistInfo.uid }).then(() => {
                      console.log("compartir");
                    });
                  }}
                  url={"app.mariiachi.com" + window.location.pathname}
                  title={artistInfo.name + ", visita su página de mariiachi aquí "}
                >
                  <i className="fa-brands fa-whatsapp text-warning text-center fs-3"></i>
                </WhatsappShareButton>
              </Col>

              <Col xs={3} md={1}>
                <Button
                  variant="link"
                  className="td-none ml-2 mr-2 px-0 mt-0 mb-0  mt-xs-2"
                  onClick={(e) => {
                    e.preventDefault();
                    copyLink();
                    addShareToArtist({ artistUid: artistInfo.uid }).then(() => {
                      console.log("compartir");
                    });
                  }}
                >
                  <i className="fa-solid fa-link-simple text-warning text-center fs-2"></i>
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>

      {loading ? (
        <div
          className="bg-light d-flex align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <div>
          {user.email ? <NavApp /> : <NavLanding />}
          <Modal
            show={modalShow}
            onHide={() => setModal(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter " style={{ color: "gray" }}>
                ¿No eres usuario todavía?
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="px-4">
                ¡Inicia sesión o regístrate! Si eres usuario, puedes crear tu página de
                artista/banda y/o guardar a tus músicos favoritos.
              </p>
              <div className="px-4">
                <Link className="btn btn-warning px-2 mr-2" to="/auth#login">
                  Inicia Sesión
                </Link>
                <Link className="btn btn-warning px-2" to="/auth#regis">
                  Regístrate
                </Link>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setModal(false);
                }}
              >
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal>
          <div style={{ marginTop: "5rem" }}>
            <Row className="mt-4">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={2}
                // style={{
                //   minHeight: "0.5rem",
                // }}
              >
                {/* {ad1.link != "" && ad2.link != "" && ( */}
                <div className="pb-3" style={{ position: "sticky", top: "80px" }}>
                  <div
                    className="d-xss-none d-sm-none d-md-block"
                    // style={{ position: "fixed" }}
                  >
                    <AmazonAd />
                    {/* <AmazonAd /> */}
                  </div>
                </div>
              </Col>

              <Col xs={12} sm={12} md={12} lg={7} style={{ minHeight: "100vh" }}>
                <div className="d-flex align-items-center justify-content-between">
                  <Breadcrumb>
                    <Breadcrumb.Item active>
                      <LazyLoadImage src={pinPoint} className="mr-1" style={{ height: "1.1rem" }} />
                      {artistInfo.country}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{artistInfo.state}</Breadcrumb.Item>
                  </Breadcrumb>

                  <Button
                    variant="link"
                    className="mt-1 mr-2 br-10 text-danger ccenter"
                    onClick={(e) => {
                      e.preventDefault();
                      setModalReport(true);
                    }}
                  >
                    <i
                      className="fa-solid fa-flag text-danger text-center"
                      style={{ fontSize: "0.9rem" }}
                    ></i>
                  </Button>
                </div>
                {artistInfo.active && artistInfo.blocked != true ? (
                  <div className="w-100">
                    <br />
                    <Row>
                      <Col
                        xs={12}
                        sm={12}
                        md={6}
                        lg={4}
                        className={`d-flex flex-column align-items-center justify-content-center text-center `}
                      >
                        <div
                          className="d-flex flex-column align-items-center justify-content-center text-center"
                          style={{
                            height: "100%",
                            maxHeight: "15rem",
                            width: "100%",
                            maxWidth: "15rem",
                          }}
                        >
                          <LazyLoadImage
                            src={
                              artistInfo.pp
                                ? artistInfo.pp
                                : "https://firebasestorage.googleapis.com/v0/b/mariiachi-dca12.appspot.com/o/mariiachi(chido).png?alt=media&token=dcc361ad-4a3d-4527-b482-c4df7ae80042"
                            }
                            className="artist-img"
                            alt={artistInfo.name}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              borderRadius: "10%",
                            }}
                          />
                        </div>
                      </Col>

                      <Col xs={12} sm={12} md={6} lg={8}>
                        <br />
                        <Container>
                          {artistInfo.verified && (
                            <p className="mb-0">
                              <i
                                className="fas fa-check-circle text-primary"
                                style={{ fontSize: "1.1rem" }}
                              ></i>{" "}
                              <span className="">Artista verificado</span>
                            </p>
                          )}
                          <h1
                            className="text-left text-black text-jakarta mt-0"
                            style={{ fontSize: "2.2rem" }}
                          >
                            {artistInfo.name}
                          </h1>
                          <div className="d-flex">
                            {/* <Col> */}

                            {/* </Col>
                            <Col> */}
                            {user.email ? (
                              <Button
                                onClick={(e) => {
                                  e.preventDefault();
                                  toggleFav();
                                }}
                                variant="outline-danger"
                                className={`btn mt-1 mr-2 br-10 ${
                                  fav ? "btn-danger text-white" : "btn-outline-danger"
                                } `}
                              >
                                {fav ? (
                                  <div className="d-fle align-items-baseline">
                                    <i className="fa-solid fa-star mr-1"></i> Favorito
                                  </div>
                                ) : (
                                  <div className="d-flex align-items-baseline">
                                    <i className="fa-regular fa-star mr-1"></i> Guardar Favorito
                                  </div>
                                )}
                              </Button>
                            ) : (
                              <div className="ccenter">
                                <p className="mb-1">
                                  <Link className="text-warning px-0" to="/auth#regis">
                                    Regístrate
                                  </Link>{" "}
                                  o{" "}
                                  <Link className="text-warning px-0" to="/auth#login">
                                    inicia sesión
                                  </Link>{" "}
                                  para guardarlo como favorito.
                                </p>
                              </div>
                            )}
                            {/* </Col> */}
                          </div>

                          <div className="mt-2 d-flex flex-row justify-content-flex-start">
                            {artistInfo.fb && (
                              <a
                                className="mr-3 "
                                target="__blank"
                                href={"https://" + artistInfo.fb}
                              >
                                <i
                                  className="fab fa-facebook-square"
                                  style={{
                                    fontSize: "2rem",
                                    width: "auto",
                                    color: "#f5803e",
                                  }}
                                ></i>
                              </a>
                            )}
                            {artistInfo.ig && (
                              <a
                                className="mr-3 "
                                target="__blank"
                                href={"https://" + artistInfo.ig}
                              >
                                <i
                                  className="fab fa-instagram"
                                  style={{
                                    fontSize: "2rem",
                                    width: "auto",
                                    color: "#f5803e",
                                  }}
                                ></i>
                              </a>
                            )}

                            {artistInfo.yt && (
                              <a
                                className="mr-3 "
                                target="__blank"
                                href={"https://" + artistInfo.yt}
                              >
                                <i
                                  className="fab fa-youtube"
                                  style={{
                                    fontSize: "2rem",
                                    width: "auto",
                                    color: "#f5803e",
                                  }}
                                ></i>
                              </a>
                            )}
                            {artistInfo.sp && (
                              <a
                                className="mr-3 "
                                target="__blank"
                                href={"https://" + artistInfo.sp}
                              >
                                <i
                                  className="fab fa-spotify"
                                  style={{
                                    fontSize: "2rem",
                                    width: "auto",
                                    color: "#f5803e",
                                  }}
                                ></i>
                              </a>
                            )}

                            {artistInfo.sc && (
                              <a
                                className="mr-3 "
                                target="__blank"
                                href={"https://" + artistInfo.sc}
                              >
                                <i
                                  className="fab fa-brands fa-soundcloud"
                                  style={{
                                    fontSize: "2rem",
                                    width: "auto",
                                    color: "#f5803e",
                                  }}
                                ></i>
                              </a>
                            )}
                          </div>
                          {artistInfo.description && (
                            <p className="font-weight-bold mt-1 pr-2">{artistInfo.description}</p>
                          )}
                          <div className="d-flex flex-row align-items-baseline">
                            {fans.length != null && fans.length != undefined && fans.length > 0 ? (
                              <div>
                                <p className="text-danger  br-10 mr-2 text-center">
                                  <i className="fas fa-circle-star text-danger text-center mb-0"></i>{" "}
                                  Favorito de {fans.length} personas
                                </p>
                              </div>
                            ) : (
                              <div>
                                {user.email && (
                                  <p className="text-fav br-10 mr-2 text-center">
                                    ¡Sé el primero en guárdalo como favorito!
                                  </p>
                                )}
                              </div>
                            )}
                            {/* <Button
                              variant="link"
                              className="btn btn-share h-f td-none text-center br-10"
                              onClick={(e) => {
                                e.preventDefault();
                                setModalShare(true);
                              }}
                              ref={target}
                              id="usesTt"
                            >
                              {" "}
                              <i className="fa-solid fa-share mr-md-2"></i>
                              Compartir
                            </Button> */}

                            {/* <Overlay
                              target={target.current}
                              show={tooltipOpen}
                              placement="top"
                            >
                              <Tooltip>
                                ¡Link copiado!
                              </Tooltip>
                            </Overlay> */}
                          </div>
                        </Container>
                      </Col>
                    </Row>
                    <Container>
                      <Row className="d-flex justify-content-end py-3 mb-2">
                        {artistInfo.video && (
                          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-3">
                            <div
                              className="shadow w-100 px-1 py-2 br-15 mb-3"
                              style={{
                                height: "50vw",
                                maxHeight: "27rem",
                                backgroundColor: "black",
                              }}
                            >
                              <ReactPlayer
                                controls={true}
                                url={"https://" + artistInfo.video}
                                className="w-100 h-100 br-15"
                              />
                            </div>
                          </Col>
                        )}
                        <Col
                          xs={12}
                          sm={12}
                          md={6}
                          lg={7}
                          xl={7}
                          className="d-flex align-items-start justify-content-start"
                        >
                          <div className="mb-2 w-100">
                            {artistInfo.instruments && (
                              <div className="d-flex w-100 flex-row align-items-center justify-content-start h-100 text-center">
                                <div className="d-flex w-100 flex-row align-items-baseline justify-content-center h-100 text-center">
                                  <p className="arIcon text-center d-flex align-items-center justify-content-center">
                                    <i className="fa-solid fa-saxophone text-white"></i>
                                  </p>

                                  <Row className="w-100 ml-2">
                                    <Col
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={3}
                                      className="d-flex align-items-start"
                                    >
                                      <p className="text-black text-jakarta font-weight-semi-bold">
                                        Instrumentos
                                      </p>
                                    </Col>

                                    <Col
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={9}
                                      className="d-flex align-items-start"
                                    >
                                      <p className="ml-1 text-gray text-left text-jakarta">
                                        {artistInfo.instruments.map((inst, ind) => {
                                          if (ind == artistInfo.instruments.length - 1) {
                                            return inst.charAt(0).toUpperCase() + inst.slice(1);
                                          } else {
                                            return (
                                              inst.charAt(0).toUpperCase() + inst.slice(1) + ", "
                                            );
                                          }
                                        })}
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            )}

                            {artistInfo.genres && (
                              <div className="d-flex w-100 flex-row align-items-center justify-content-start h-100 text-center">
                                <div className="d-flex w-100 flex-row align-items-baseline justify-content-center h-100 text-center">
                                  <p className="arIcon text-center d-flex align-items-center justify-content-center">
                                    <i className="fa-solid fa-music text-white "></i>
                                  </p>
                                  <Row className="w-100 ml-2">
                                    <Col
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={3}
                                      className="d-flex align-items-start"
                                    >
                                      <p className="text-black text-jakarta  font-weight-semi-bold">
                                        Géneros
                                      </p>
                                    </Col>

                                    <Col
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={9}
                                      className="d-flex align-items-start"
                                    >
                                      <p className="ml-1 text-left text-gray text-jakarta">
                                        {artistInfo.genres.map((gen, ind1) => {
                                          if (ind1 == artistInfo.genres.length - 1) {
                                            return gen.charAt(0).toUpperCase() + gen.slice(1);
                                          } else {
                                            return (
                                              gen.charAt(0).toUpperCase() + gen.slice(1) + ", "
                                            );
                                          }
                                        })}
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            )}

                            <div className="d-flex w-100 flex-row align-items-center justify-content-start h-100 text-center">
                              <div className="d-flex w-100 flex-row align-items-baseline justify-content-center h-100 text-center">
                                <p className="arIcon text-center d-flex align-items-center justify-content-center">
                                  <i className="far fa-money-bill text-white "></i>
                                </p>

                                <Row className="w-100 ml-2">
                                  <Col
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={3}
                                    className="d-flex align-items-start"
                                  >
                                    <p className="text-black text-jakarta font-weight-semi-bold">
                                      Precio
                                    </p>
                                  </Col>

                                  <Col
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={9}
                                    className="d-flex align-items-start"
                                  >
                                    <p className="ml-1 text-gray text-left text-jakarta">
                                      {artistInfo.arrange ? (
                                        "A convenir"
                                      ) : (
                                        <span>
                                          ${artistInfo.price.toString()} {artistInfo.currency} /
                                          hora
                                        </span>
                                      )}
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={6}
                          lg={5}
                          xl={5}
                          className="d-flex flex-column align-items-start justify-content-start p-0 mb-0"
                        >
                          <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
                            {artistInfo.telephone != null &&
                              artistInfo.telephone.length != undefined &&
                              artistInfo.telephone.length > 7 && (
                                <a
                                  target="_blank"
                                  className="btn btn-success br-10 w-100 py-2 d-flex align-items-center justify-content-center"
                                  href={`https://wa.me/${artistInfo.telephone}?text=¡Hola!%20te%20encontré%20por%20mariiachi.com,%20quisiera%20pedirte%20información.`}
                                >
                                  <i className="fa-brands fa-whatsapp text-white text-center fs-2 mr-2"></i>{" "}
                                  Contactar por Whatsapp
                                </a>
                              )}

                            <Button
                              variant="link"
                              className="text-warning br-10 w-100 mt-1 py-2 mb-0"
                              onClick={(e) => {
                                e.preventDefault();
                                setModalContact(true);
                                addContactToArtist({
                                  artistUid: artistInfo.uid,
                                }).then(() => {
                                  console.log("contacto hecha");
                                });
                              }}
                            >
                              Ver Datos de Contacto
                            </Button>
                          </div>

                          <div className="p-0 w-100">
                            <h5 className="text-black">Comparte</h5>
                            <div className="d-flex align-items-center">
                              <EmailShareButton
                                className="ml-2 mr-2 td-none"
                                url={"app.mariiachi.com" + window.location.pathname}
                                subject={artistInfo.name + " en mariiachi.com"}
                                body={
                                  artistInfo.name +
                                  " en mariiachi.com. Visita su página de artista: " +
                                  ("app.mariiachi.com" + window.location.pathname)
                                }
                              >
                                <i className="fa-solid fa-envelope text-warning text-center fs-2"></i>
                              </EmailShareButton>

                              <FacebookShareButton
                                className="ml-2 mr-2 td-none"
                                onClick={(e) => {
                                  e.preventDefault();
                                  addShareToArtist({ artistUid: artistInfo.uid }).then(() => {
                                    console.log("compartir");
                                  });
                                }}
                                url={"app.mariiachi.com" + window.location.pathname}
                                hashtag="#mariiachi"
                                quote={artistInfo.name}
                              >
                                <i className="fa-brands fa-facebook text-warning text-center fs-2"></i>
                              </FacebookShareButton>

                              {/* <PinterestShareButton
                                className="ml-2 mr-2 td-none"
                                onClick={(e) => {
                                  e.preventDefault();
                                  addShareToArtist({ artistUid: artistInfo.uid }).then(() => {
                                    console.log("compartir");
                                  });
                                }}
                                url={"app.mariiachi.com" + window.location.pathname}
                                description={artistInfo.name + " en mariiachi.com"}
                                media={
                                  artistInfo.pp
                                    ? artistInfo.pp
                                    : "https://firebasestorage.googleapis.com/v0/b/mariiachi-dca12.appspot.com/o/mariiachi(chido).png?alt=media&token=dcc361ad-4a3d-4527-b482-c4df7ae80042"
                                }
                              >
                                <i className="fa-brands fa-pinterest text-warning text-center fs-3"></i>
                              </PinterestShareButton> */}

                              {/* <RedditShareButton
                                className="ml-2 mr-2 td-none"
                                onClick={(e) => {
                                  e.preventDefault();
                                  addShareToArtist({ artistUid: artistInfo.uid }).then(() => {
                                    console.log("compartir");
                                  });
                                }}
                                url={"app.mariiachi.com" + window.location.pathname}
                                title={artistInfo.name + " en mariiachi.com"}
                              >
                                <i className="fa-brands fa-reddit text-warning text-center fs-3"></i>
                              </RedditShareButton> */}

                              <TwitterShareButton
                                className="ml-2 mr-2 td-none"
                                onClick={(e) => {
                                  e.preventDefault();
                                  addShareToArtist({ artistUid: artistInfo.uid }).then(() => {
                                    console.log("compartir");
                                  });
                                }}
                                url={"app.mariiachi.com" + window.location.pathname}
                                title={artistInfo.name + " en mariiachi.com"}
                              >
                                <i className="fa-brands fa-twitter text-warning text-center fs-2"></i>
                              </TwitterShareButton>

                              <WhatsappShareButton
                                className="ml-2 mr-2 td-none"
                                onClick={(e) => {
                                  e.preventDefault();
                                  addShareToArtist({ artistUid: artistInfo.uid }).then(() => {
                                    console.log("compartir");
                                  });
                                }}
                                url={"app.mariiachi.com" + window.location.pathname}
                                title={artistInfo.name + ", visita su página de mariiachi aquí "}
                              >
                                <i className="fa-brands fa-whatsapp text-warning text-center fs-2"></i>
                              </WhatsappShareButton>

                              <Button
                                variant="link"
                                className="td-none ccenter ml-2 mr-2 px-0 mt-0 mb-0 h-100 "
                                onClick={(e) => {
                                  e.preventDefault();
                                  copyLink();
                                  addShareToArtist({ artistUid: artistInfo.uid }).then(() => {
                                    console.log("compartir");
                                  });
                                }}
                              >
                                <i className="fa-solid fa-link-simple text-warning text-center fs-2"></i>
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className="pb-3">
                        <div className="d-block d-md-none">
                          <AmazonAd />
                          {/* <AmazonAd /> */}
                        </div>
                      </div>
                      <Card className="br-15 p-2 py-3 w-100 border-none shadow-lg text-warning text-center mb-2">
                        <p className="font-weight-semi-bold mb-2">¿También eres artista?</p>
                        {user.email ? (
                          <Link
                            className="btn btn-danger btn-sm br-15 btn-block p-2"
                            to="/account#artista"
                          >
                            Crea tu página de artista
                          </Link>
                        ) : (
                          <Link
                            className="btn btn-danger btn-sm br-15 btn-block p-2"
                            to="/auth#regis"
                          >
                            Regístrate
                          </Link>
                        )}
                      </Card>
                    </Container>
                  </div>
                ) : (
                  <Container>
                    <br />
                    <h4 className="text-warning">Ups, artista no disponible por el momento.</h4>
                    <p>El artista tiene esta página inactiva por el momento.</p>
                  </Container>
                )}
              </Col>

              <Col xs={12} sm={12} md={12} lg={3}>
                <br style={{ position: "relative" }} className="mb-4" />
                <div style={{ position: "sticky", top: "5rem" }}>
                  <SuggestionsCard
                    auid={artistInfo.uid}
                    country={artistInfo.country}
                    state={artistInfo.state}
                    instFilter={artistInfo.genres}
                  />
                </div>
              </Col>
            </Row>
          </div>

          <Footer />
        </div>
      )}
    </Container>
  );
}

export default ArtistPage;
