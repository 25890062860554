import "firebase/compat/auth";
import "firebase/compat/firestore";
import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "../src/App.css";
import "./App.css";
import AppHome from "./Layouts/HomeSection/AppHome";
import "./index.css";

import "animate.css/animate.min.css";
import "firebase/compat/analytics";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import ArtistPage from "./Layouts/ArtistPage";
import Auth from "./Layouts/Auth";
import Landing from "./Layouts/Landing";
import MiCuenta from "./Layouts/MiCuenta/MiCuenta";
import ShortLink from "./Layouts/ShortLink";

import EarlyPage from "./Layouts/EarlyPage";
import Search from "./Layouts/Search/Search";
import VerifyEmail from "./Layouts/VerifyEmail";
import "./assets/css/theme.css";

import { UseUser } from "./Context/userContext";

function App() {
  const navigate = useNavigate();
  const { loc, setLocContext, user, setUserContext, setShowLoc, setValidLocsContext, validLocs } =
    UseUser();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allowedLocsDoc = await firebase
          .firestore()
          .collection("allowedSections")
          .doc("allowed")
          .get();

        const allowedLocs = allowedLocsDoc.data();
        setValidLocsContext(allowedLocs);

        const authUser = firebase.auth().currentUser;

        console.log("authUser: ", authUser);
        if (!authUser) {
          if (!loc.country || !loc.country.trim()) {
            if (
              !window.location.pathname.includes("artist") &&
              !window.location.pathname.includes("auth")
            ) {
              setShowLoc(true);
            }
          } else {
            setShowLoc(false);
          }
        } else {
          if (!authUser.emailVerified) {
            console.log("sin verif");
            // window.location = "/verify-email";
            navigate("/verify-email");
          } else {
            const userObj = await getUserData(authUser);

            setUserContext(userObj);

            if (!userObj.location || !userObj.location.country) {
              if (
                !window.location.pathname.includes("artist") &&
                !window.location.pathname.includes("auth")
              ) {
                setShowLoc(true);
              }
            } else {
              setShowLoc(false);

              setLocContext({ ...(userObj.location || {}) });

              if (
                allowedLocs.countries.includes(userObj.location.country) &&
                allowedLocs.states.includes(userObj.location.state)
              ) {
                // Haz algo si es necesario
              } else {
                // window.location = "/pre-register";
                navigate("/pre-register");
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
        alert(error);
      }
    };

    const getUserData = async (authUser) => {
      var userObj = {};

      const userData = await firebase.firestore().collection("users").doc(authUser.uid).get();

      // userObj.name = userData.data().nombre;
      // userObj.surname = userData.data().apellido;
      // userObj.favs = userData.data().favs;
      // userObj.artist = userData.data().artist;
      // userObj.location = userData.data().location;
      // userObj.uid = authUser.uid;

      userObj = userData.data();
      // if (loc.country && loc.country.trim() && loc.state && loc.state.trim()) {
      // } else {
      //   setLocContext({ ...(userData.data().location || {}) });
      // }
      return userObj;
    };

    firebase.auth().onAuthStateChanged((authUser) => {
      fetchData();
    });
  }, []);

  useEffect(() => {
    if (validLocs) {
      if (loc.country && loc.country.trim() && loc.state && loc.state.trim()) {
        if (validLocs.countries.includes(loc.country) && validLocs.states.includes(loc.state)) {
          // haz algo si es necesario
        } else {
          // window.location = "/pre-register";
          console.log("pre-register");
          navigate("/pre-register");
        }
      }
    }
  }, [loc, validLocs]);

  return (
    <Routes>
      <Route exact path="/landing" element={<Landing />} />
      <Route exact path="/verify-email" element={<VerifyEmail />} />
      <Route exact path="/pre-register" element={<EarlyPage />} />
      <Route exact path="/" element={<AppHome />} />
      <Route exact path="/auth" element={<Auth />} />
      <Route exact path="/account" element={<MiCuenta />} />
      <Route path="/artist/:id" element={<ArtistPage />} />
      <Route path="/sl/:id" element={<ShortLink />} />
      <Route path="/search" element={<Search />} />
      <Route path="*" element={<Landing />} />
    </Routes>
  );
}

export default App;
