import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import "../../App.css";

import { Button, Spinner } from "react-bootstrap";

function DiscoverSection({ loc, genre }) {
  const [loading, setLoading] = useState(true);
  const [popularArtists, setPopulars] = useState([]);

  const [errorr, setErr] = useState("");
  const ref = useRef(null);
  useEffect(() => {
    const getPopulars = async (location) => {
      console.log("loc; ", location);
      var favArr = [];
      await firebase
        .firestore()
        .collection("artists")
        .where("country", "==", location.country)
        .where("state", "==", location.state)
        .where("status", "==", true)
        .where("video", "!=", null)
        .limit(15)
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            if (doc.data().video != "" && doc.data().video != " ") {
              favArr.push(doc.data());
            }
          });
        })
        .then(() => {
          setPopulars(favArr);
          setLoading(false);
        })
        .catch((er) => {
          setLoading(false);
          console.log(er);
          setErr(er);
        });
    };

    if (loc != undefined && loc != null) {
      getPopulars(loc);
    }
  }, [loc]);

  return (
    <div className="w-100">
      <div className="d-xs-none d-sm-none d-md-block">
        <div className="w-100 d-flex mb-3 align-items-center justify-content-end">
          <Link className="text-warning font-weight-semi-bold mr-4" to="/search">
            Ver más
          </Link>
          <Button
            variant="warning"
            className="btnChevron"
            onClick={() => {
              ref.current.scrollLeft += -130;
            }}
          >
            <i className="fas fa-chevron-left d-flex align-items-center justify-content-center"></i>
          </Button>
          <Button
            variant="warning"
            className="btnChevron ml-2"
            onClick={() => {
              ref.current.scrollLeft += 130;
            }}
          >
            <i className="fas fa-chevron-right d-flex align-items-center justify-content-center"></i>
          </Button>
        </div>
      </div>
      {loading ? (
        <div className=" d-flex align-items-center justify-content-center py-5">
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <div
          className="d-flex align-items-center w-100 justify-content-center mb-4 sbar"
          // style={{ minHeight: "24rem", width: "100%" }}
        >
          <div className="w-100 smooth" style={{ overflowX: "scroll" }} ref={ref}>
            <div
              className="d-flex flex-row align-items-center justify-content-center pb-4 px-3"
              style={{
                width: "max-content",
                minWidth: "100%",
                overflowY: "hidden",
              }}
            >
              {errorr != "" ? (
                <p>{errorr}</p>
              ) : popularArtists.length > 0 ? (
                popularArtists.map((artistt, ind) => {
                  return (
                    artistt.blocked != true && (
                      <div>
                        <div
                          className="d-flex flex-column align-items-center justify-content-center mx-2 br-10"
                          style={{
                            height: "18rem",
                            width: "25rem",
                            borderRadius: "10px !important",
                          }}
                          key={ind}
                        >
                          <div
                            style={{
                              borderRadius: "10px",
                              backgroundColor: "black",
                            }}
                            className="w-100 h-100 br-10 d-flex align-items-center justify-content-center"
                          >
                            <ReactPlayer
                              controls={true}
                              url={"https://" + artistt.video}
                              style={{
                                borderRadius: "10px !important",
                              }}
                              className="br-10"
                              width="97%"
                              height="97%"
                            />
                          </div>

                          <div className="w-100 px-2 mt-2 d-flex flex-row align-items-center br-15 ">
                            <Link to={`/artist/${artistt.uid}`}>
                              <LazyLoadImage
                                src={
                                  artistt.pp
                                    ? artistt.pp
                                    : "https://firebasestorage.googleapis.com/v0/b/mariiachi-dca12.appspot.com/o/mariiachi_profileimg%20(1).png?alt=media&token=a38c0591-b595-489d-ba94-4c9108f1d36e"
                                }
                                className="rounded-circle my-1 videoProfile"
                                alt={artistt.name}
                              />
                            </Link>
                            <div className="ml-2 h-100 d-flex flex-column align-items-start justify-content-center">
                              <Link
                                className="text-black td-none"
                                style={{ width: "100%" }}
                                to={`/artist/${artistt.uid}`}
                              >
                                <p className="text-black font-weight-semi-bold mb-0">
                                  {artistt.verified && (
                                    <span>
                                      <i
                                        className="fas fa-check-circle text-primary mb-0"
                                        style={{ fontSize: "0.9rem" }}
                                      ></i>
                                    </span>
                                  )}{" "}
                                  {artistt.name}
                                </p>
                              </Link>
                              {artistt.fanCount > 0 && (
                                <p
                                  className="p-0 td-none mb-0 text-secondary"
                                  style={{
                                    textDecoration: "underline white 0px",
                                  }}
                                >
                                  Favorito de {artistt.fanCount} usuarios
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  );
                })
              ) : (
                <p className="px-2 w-100 text-center font-weight-semi-bold">
                  ¡Oh mira! Toavía no hay músicos en tu zona.
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DiscoverSection;
