import "../../App.css";
import ArtistDisp from "../../Components/ArtistDisp";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Container,
  Carousel,
  Accordion,
  Spinner,
} from "react-bootstrap";

function Favoritos() {
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({});

  const [favArtists, setFavs] = useState([]);

  const [artArr, setArtArray] = useState([]);
  const [change, setChange] = useState(false);

  const getMore = async () => {
    // console.log('gm', artArr);
    var favoritoos = favArtists;
    setLoading(true);
    if (artArr != null && artArr != undefined) {
      var i = 0;
      var maxx = 30;
      if (artArr.length < 30) {
        maxx = artArr.length;
      }
      var rawAr = artArr;
      for (i = 1; i <= maxx; i++) {
        // userObj.favs.forEach((favv) => {
        // console.log("FAVV :, ", favv);
        // console.log("wh; ", userObj.favs[i]);
        await firebase
          .firestore()
          .collection("artists")
          .doc(artArr[i])
          .get()
          .then((doc) => {
            if (doc.exists) {
              // console.log("ex");
              favoritoos.push(doc.data());
            }
          })
          .catch((er) => {
            console.log(er);
            alert(er);
            setLoading(false);
          });

        // });
      }
      rawAr.splice(0, maxx);
      setArtArray(rawAr);
      // console.log('sal', rawAr);
      setFavs(favoritoos);
      setLoading(false);
    }
  };

  useEffect(() => {
    var favoritos;
    var cvalues = [];

    firebase.auth().onAuthStateChanged((authUser) => {
      if (!authUser) {
        console.log("sin usuario");
        window.location = "/landing";
      } else {
        var userObj = new Object();
        firebase
          .firestore()
          .collection("users")
          .doc(authUser.uid)
          .get()
          .then((page) => {
            userObj.name = page.data().nombre;
            userObj.surname = page.data().apellido;
            userObj.favs = page.data().favs ? page.data().favs : null;
            userObj.artist = page.data().artist;
            userObj.location = page.data().location;
            userObj.uid = authUser.uid;
            setArtArray(page.data().favs ? page.data().favs : null);
          })
          .then(async () => {
            // console.log("baby: ", userObj.favs.length);
            if (userObj.favs != null) {
              favoritos = [];
              var i = 0;
              var maxx = 30;
              if (userObj.favs.length < 30) {
                maxx = userObj.favs.length;
              }
              var rawAr = userObj.favs;
              // console.log("start", rawAr);
              for (i = 0; i <= maxx; i++) {
                // userObj.favs.forEach((favv) => {
                // console.log("FAVV :, ", favv);
                // console.log("wh; ", userObj.favs[i]);
                await firebase
                  .firestore()
                  .collection("artists")
                  .doc(userObj.favs[i])
                  .get()
                  .then((doc) => {
                    if (doc.exists) {
                      // console.log("ex");
                      favoritos.push(doc.data());
                    }
                  });
                // });
              }
              rawAr.splice(0, maxx);
              // console.log("stend", rawAr);
              setArtArray(rawAr);
            } else {
              setArtArray(null);
              favoritos = null;
              // setFavs(null);
              // setLoading(false);
            }
          })
          .then(() => {
            // console.log("baby2: ", favoritos);
            setFavs(favoritos);
            setLoading(false);
          })
          .catch((er) => {
            console.log(er);
            alert(er);
            setLoading(false);
          });
      }
    });

    var urll = window.location.href;
    // if (urll.includes("micuenta#artista")) {
    //   setContent("artista");
    // } else if (urll.includes("micuenta#ajustes")) {
    //   setContent("ajustes");
    // } else if (urll.includes("micuenta#favoritos")) {
    //   setContent("favoritos");
    // }
  }, [change]);

  return (
    <div className="w-100">
      {loading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <div className="w-100">
          <h4 className="font-weight-bold text-black mt-2">
            Favoritos</h4>

          <div className="mt-5 px-2 w-100 d-flex align-items-center justify-content-center">
            {favArtists ? (
              <Row className="w-100">
                {favArtists != null &&
                  favArtists != undefined &&
                  favArtists.length > 0 ? (
                  favArtists.map((ar, ind) => {
                    return <ArtistDisp artist={ar} key={ind} />;
                  })
                ) : (
                  <p>Todavía no tienes artistas guardados como favoritos</p>
                )}
              </Row>
            ) : (
              <p>Todavía no tienes artistas guardados como favoritos</p>
            )}
          </div>

          {artArr != null && artArr != undefined && (
            <Button
              className="mb-4 mt-1 btn-block"
              variant="warning"
              disabled={artArr.length == 0}
              onClick={(e) => {
                e.preventDefault();
                getMore();
              }}
            >
              {artArr.length == 0 ? "No hay más resultados" : "Cargar Más"}
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

export default Favoritos;
