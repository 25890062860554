import "animate.css/animate.min.css";
import { State } from "country-state-city";
import firebase from "firebase/compat/app";

import "firebase/compat/auth";
import "firebase/compat/firestore";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "swiper/css";
import "../../App.css";
import { UseUser } from "../../Context/userContext";
import "../../assets/css/theme.css";
import logo from "../../assets/img/logo.png";

function LocSelect() {
  const { setLocContext, loc, user, setUserContext, showLoc, setShowLoc, validLocs } = UseUser();
  const [ad1, setAd1] = useState({ img: "", link: "" });
  const [loading, setLoading] = useState(true);

  const [countries, setCountries] = useState([
    { flag: "🇲🇽", name: "Mexico", isoCode: "MX", currency: "MXN" },
    { flag: "🇺🇸", name: "United States", isoCode: "US", currency: "USD" },
  ]);
  const [states, setStates] = useState([]);
  const [countriesValues, setCountriesValues] = useState([]);
  const [statesValues, setStatesValues] = useState([]);
  const [currency, setCurrency] = useState("");
  const [cCode, setCcode] = useState("");
  const [cName, setCname] = useState("");
  const [sName, setSname] = useState("");
  const [validLoc, setValidLoc] = useState(true);

  const [isUser, setIsUser] = useState(user.email ? true : false);
  const selectCountry = (index, name, code, curr) => {
    var fetchedStates = State.getStatesOfCountry(code);
    setCurrency(curr);
    setCcode(code);
    setCname(name);
    var values = countriesValues;
    if (values[index] === true) {
      values[index] = false;
    } else {
      values[index] = true;
    }
    values.forEach((val, ind) => {
      if (ind != index) {
        values[ind] = false;
      }
    });
    setCountriesValues(values);
    setStates(fetchedStates);
    var stateVal = [];
    fetchedStates.forEach((el, indd) => {
      stateVal.push(false);
    });
    setStatesValues(stateVal);
  };

  const selectState = (index, name, code, value) => {
    setSname(name);
    var stateVal = [];
    states.forEach((el, ind) => {
      if (ind != index) {
        stateVal.push(false);
      } else {
        stateVal.push(true);
      }
    });

    setStatesValues(stateVal);
  };

  const setLocation = () => {
    if (cCode != "" && cName != "" && sName != "" && currency != "") {
      if (validLocs.countries.includes(cName) && validLocs.states.includes(sName)) {
        // Haz algo si es necesario
      } else {
        // navigate("/pre-register");
      }

      if (isUser) {
        setLocContext({
          state: sName,
          country: cName,
          countryCode: cCode,
          currency: currency,
        });
        firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
          .set(
            {
              location: {
                state: sName,
                country: cName,
                countryCode: cCode,
                currency: currency,
              },
            },
            { merge: true }
          )
          .then(() => {
            setShowLoc(false);
          })
          .catch((er) => {
            alert(er);
            console.log(er);
            // setChange(!change);
          });
      } else {
        setLocContext({
          state: sName,
          country: cName,
          countryCode: cCode,
          currency: currency,
        });
        setShowLoc(false);
      }
    } else {
      alert("Por favor elige una ubicación para continuar.");
      return;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allowedLocsDoc = await firebase
          .firestore()
          .collection("allowedSections")
          .doc("allowed")
          .get();

        const allowedLocs = allowedLocsDoc.data();

        const authUser = firebase.auth().currentUser;
        // var prevvv = user;

        if (!authUser) {
          setIsUser(false);
          // prevvv.isUser = false;
          //   setUserContext(prevvv);

          if (!loc.country || !loc.country.trim()) {
            // setModal(true);
          } else {
            if (
              allowedLocs.countries.includes(loc.country) &&
              allowedLocs.states.includes(loc.state)
            ) {
              setLoading(false);
            } else {
              setValidLoc(false);
            }
          }

          // prevvv.location = loc;
          // setUserInfo(prevvv);
        } else {
          if (!authUser.emailVerified) {
            console.log("sin verif");
            window.location = "/verify-email";
          } else {
            setIsUser(true);
            // prevvv.isUser = true;
            // prevvv.uid = authUser.uid;
            // setUserContext(prevvv);

            const userObj = await getUserData(authUser);

            if (!userObj.location || !userObj.location.country) {
              // setModal(true);
              setLoading(false);
            } else {
              // prevvv.location = userObj.location;

              if (
                !(
                  allowedLocs.countries.includes(userObj.location.country) &&
                  allowedLocs.states.includes(userObj.location.state)
                )
              ) {
                window.location = "/pre-register";
              }
            }

            // setUserInfo(userObj);
            // setUserContext(prevvv);
          }
        }
      } catch (error) {
        alert(error);
        setLoading(false);
      }
    };

    const getUserData = async (authUser) => {
      const userObj = {};

      const userData = await firebase.firestore().collection("users").doc(authUser.uid).get();

      userObj.name = userData.data().nombre;
      userObj.surname = userData.data().apellido;
      userObj.favs = userData.data().favs;
      userObj.artist = userData.data().artist;
      userObj.location = userData.data().location;
      userObj.uid = authUser.uid;

      // if (loc.country && loc.country.trim() && loc.state && loc.state.trim()) {
      // } else {
      //   setLocContext({ ...(userData.data().location || {}) });
      // }
      return userObj;
    };

    fetchData();
  }, []);

  useEffect(() => {}, [showLoc, user]);

  return (
    <div>
      {showLoc ? (
        <div className="locModal">
          <div className="ccenter w-100">
            <LazyLoadImage
              src={logo}
              alt="mariiachi"
              title="mariiachi"
              style={{
                height: "2.5rem",
                width: "auto",
              }}
            />
          </div>
          <h4 className="text-jakarta text-black mt-3">Selecciona tu Ubicación</h4>
          <div className="w-100 ccenter">
            <Row className="w-100 ccenter flex-row px-3">
              <Col xs={12} sm={12} md={6} lg={4} className="pt-0 mt-0 pb-0 mb-0">
                <Form.Group controlId="formBasicCheck" className="mb-4 mt-2">
                  <Form.Label className="text-warning">
                    País: <span className="text-black">{cName ? cName : ""}</span>
                  </Form.Label>
                  <div className="border checkInst px-2 br-10">
                    {countries.length > 1 &&
                      countries.length != null &&
                      countries.map((country, index) => {
                        return (
                          <Form.Check
                            onChange={(thiss) => {
                              selectCountry(
                                index,
                                country.name,
                                country.isoCode,
                                thiss.target.checked,
                                country.currency
                              );
                            }}
                            key={index}
                            checked={countriesValues[index]}
                            label={country.flag + " " + country.name}
                            name={`country-${index}`}
                            type="radio"
                            id={`radio-co-${index}`}
                          />
                        );
                      })}
                  </div>
                </Form.Group>
              </Col>

              {states.length > 0 && states.length != null && (
                <Col xs={12} sm={12} md={6} lg={4} className="pt-0 mt-0">
                  <Form.Group controlId="formBasicRadio" className="mb-4 mt-1">
                    <Form.Label className="text-warning pt-0 mt-0">
                      Estado:{" "}
                      <span className="text-black font-weight-bold">{sName ? sName : ""}</span>
                    </Form.Label>

                    <div className="border checkInst px-2 br-10">
                      {states.map((state, index) => {
                        return (
                          <Form.Check
                            onChange={(thiss) => {
                              selectState(index, state.name, state.isoCode, thiss.target.checked);
                            }}
                            key={index}
                            checked={statesValues[index]}
                            label={state.name}
                            name={`state-${index}`}
                            type="radio"
                            id={`radio-s-${index}`}
                          />
                        );
                      })}
                    </div>
                  </Form.Group>
                </Col>
              )}
            </Row>
          </div>
          <div className="px-3">
            <Button
              variant="warning"
              className="btn btn-block btn-orange br-10"
              onClick={(e) => {
                e.preventDefault();
                setLocation();
              }}
            >
              Seguir
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default LocSelect;
