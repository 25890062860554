import React, { useEffect, useState } from "react";
import "../App.css";
import "../assets/css/theme.css";
import { Row, Col, Container } from "react-bootstrap";
import "animate.css/animate.min.css";
import { Link } from "react-router-dom";

import logo from "../assets/img/logo.png";
function Footer() {
  var anio = new Date();
  const [verse, setVerse] = useState("");
  var verseOptions = [
    "Todo lo puedo en Cristo que me fortalece. -Fil. 4:13",
    "Ninguno puede servir a dos señores, porque estimará a uno y menospreciará al otro. No podéis servir a Dios y a las riquezas. -Mat. 6:24",
    "Y todo lo que hacéis, de palabra o de hecho, hacedlo todo en el nombre del Señor Jesús, dando gracias por medio de El a Dios el Padre. -Col 3:17",
    "La persona laboriosa alcanzará el mando, a la perezosa le tocará servir. -Prob 12:24",
    "Tú, Señor, cumplirás en mí tus planes; tu misericordia, Señor, permanece para siempre. Yo soy creación tuya. ¡No me desampares! -Sal. 138:8",
    "El alma del perezoso desea y nada alcanza, pero el alma de los diligentes será prosperada. -Prob. 13:4",
    "Hay quienes presumen de ricos y no tienen nada; y hay quienes pasan por pobres y tienen muchas riquezas. -Prob. 13:7",
    "Las riquezas obtenidas por vanidad disminuirán, pero el que recoge con mano laboriosa las aumentará. -Prob. 13:11",
    "Pobreza y vergüenza tendrá el que menosprecia la disciplina, pero el que acepta la corrección será honrado. -Prob 13:18",
    "El que anda entre sabios será sabio, pero el que se junta con necios sufrirá el mal. -Prob. 13:20",
    "En la casa del justo hay gran abundancia, pero turbación en las ganancias del malvado. -Prob. 15:6",
    "Hagan lo que hagan, trabajen de buena gana, como para el Señor y no como para nadie en este mundo. -Col 3:23",
    "Encomienda a Jehová tus obras, y tus pensamientos serán afirmados. -Prob. 16:3",
  ];
  useEffect(() => {
    setVerse(verseOptions[Math.floor(Math.random() * verseOptions.length)]);
  }, [window.location.href]);
  return (
    <div
      // className="border-top"
      className="br-15 mt-2"
    >
      <Container className="py-2">
        <Row className="d-flex justify-content-between">
          <Col
            xs={12}
            sm={12}
            md={3}
            lg={3}
            className="d-flex flex-column justify-content-center"
          >
            <div>
              <img
                src={logo}
                alt="mariiachi"
                title="mariiachi.com"
                style={{
                  height: "2.5rem",
                  width: "auto",
                  objectFit: "contain",
                }}
              />
              <br />
              <div className="d-flex justify-content-start mt-2">
                Somos una plataforma para encontrar
                <br />
                músicos y bandas para cualquier ocasión.
                <br />
                Sin música, no hay fiesta.
                <br />
              </div>
              <div className="d-flex justify-content-start mt-3">
                <a
                  className="mr-3"
                  target="__blank"
                  href="https://www.tiktok.com/@mariiachi.com?lang=es"
                >
                  <i
                    className="fab fa-tiktok"
                    style={{
                      fontSize: "1.5rem",
                      width: "auto",
                      color: "#f5803e",
                    }}
                  ></i>
                </a>
                <a
                  className="mr-3"
                  target="__blank"
                  href="https://www.facebook.com/Mariiachi-109446741632513"
                >
                  <i
                    className="fab fa-facebook-square"
                    style={{
                      fontSize: "1.5rem",
                      width: "auto",
                      color: "#f5803e",
                    }}
                  ></i>
                </a>

                <a
                  className="mr-3"
                  target="__blank"
                  href="https://www.instagram.com/_mariiachi.com_/"
                >
                  <i
                    className="fab fa-instagram"
                    style={{
                      fontSize: "1.5rem",
                      width: "auto",
                      color: "#f5803e",
                    }}
                  ></i>
                </a>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={9} lg={8}>
            <div className="mt-4 mb-1 mt-lg-0 d-flex flex-column justify-content-between">
              <Row>
                <Col xs={12} sm={12} md={5} lg={4} className=" pr-md-4">
                  <p className="font-weight-bold mb-2">Contacto</p>
                  <a
                    href="mailto:contacto.mariiachi@gmail.com"
                    className="text-warning"
                  >
                    contacto.mariiachi@gmail.com
                  </a>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={4}
                  lg={3}
                  className="pl-3 mt-4 mt-md-0 pb-md-2"
                >
                  <p className="font-weight-bold mb-2">Legal</p>
                  <div className="d-flex flex-column justify-content-start">
                    <a
                      href="https://firebasestorage.googleapis.com/v0/b/mariiachi-dca12.appspot.com/o/legal%2FTerminos%20y%20Condiciones%20mariiachi.docx.pdf?alt=media&token=b83e5dcd-f552-46fc-b428-7098a174d26c"
                      target="_blank"
                      className="text-warning"
                      rel="noopener noreferrer"
                    >
                      Términos y Condiciones
                    </a>
                    <a
                      href="https://firebasestorage.googleapis.com/v0/b/mariiachi-dca12.appspot.com/o/legal%2FAviso%20de%20Privacidad%20Integral%20Mariiachi.docx.pdf?alt=media&token=ed7f1403-97ed-480d-8aac-af4ed2151a05"
                      target="_blank"
                      className="text-warning"
                      rel="noopener noreferrer"
                    >
                      Política de Privacidad
                    </a>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={4} lg={3} className=" pr-md-4 pb-4">
                  <p className="font-weight-bold mb-2">
                    De México para el mundo
                  </p>
                  <div className="d-flex flex-column justify-content-start">
                    Orgullosamente fundado
                    <br />
                    en Monterrey Nuevo León.
                    {/* <Link
                      to="/"
                      className="text-warning"

                    >
                      Patrocinadores
                    </Link>
                    <Link
                      to="/"
                      className="text-warning"
                    >
                      Sé Patrocinador
                    </Link> */}
                  </div>
                </Col>
              </Row>
              <div>
                <p className="font-weight-bold mb-1">Para el Alma</p>
                <span className="italic">{verse}</span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="bg-light">
        <Container className="d-flex align-items-center justify-content-center">
          <div className="row no-gutters py-1 text-center d-flex align-items-center">
            <small>
              <div className="d-flex font-weight-medium">
                {anio.getFullYear()} &copy;{" "}
                <p className="text-warning ml-1">mariiachi.com</p>
              </div>
            </small>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Footer;
