import "../App.css";
import NavApp from "../Components/NavApp";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import ReactPlayer from "react-player";

import {
  Row,
  Col,
  Button,
  Card,
  Container,
  Spinner,
  Toast,
  Overlay,
  Modal,
  Breadcrumb,
  Form,
} from "react-bootstrap";
import Footer from "../Components/Footer";
import pinPoint from "../assets/img/icons/map-marker.png";
import NavLanding from "../Components/NavbarLanding";
import SuggestionsCard from "./SuggestionsCard";
import { UseUser } from "../Context/userContext";

function ArtistPage() {
  const { user, setUserContext } = UseUser();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [login, setLogin] = useState(false);

  useEffect(() => {
    const urll = window.location.pathname;
    var shortId = urll.split("/")[2];

    console.log("shortID", shortId);
    firebase.auth().onAuthStateChanged((authUser) => {
      if (!authUser) {
        setLogin(false);
      } else {
        setLogin(true);
      }
    });

    firebase
      .firestore()
      .collection("shortUrls")
      .doc(shortId)
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          const longUrl = "/artist/" + doc.data().to;
          navigate(longUrl);
        } else {
          setLoading(false);
        }
      });
  }, [window.location.pathname]);

  return (
    <Container fluid>
      {loading ? (
        <div
          className="bg-light d-flex align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <div>
          {login ? <NavApp /> : <NavLanding />}

          <div className="h-100 w-100 ccenter" style={{ minHeight: "100vh" }}>
            <h5 className="text-black text-jakarta">
              Ups, hubo un error, revisa que el link esté correctamente escrito
            </h5>
          </div>

          <Footer />
        </div>
      )}
    </Container>
  );
}

export default ArtistPage;
