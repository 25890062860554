import "../../App.css";
import NavApp from "../../Components/NavApp";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Container,
  Carousel,
  Accordion,
  Spinner,
} from "react-bootstrap";

function Ajustes() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});

  const [receiveNews, setReceiveNews] = useState(true);
  const [newsLoading, setNewsLoading] = useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((authUser) => {
      if (!authUser) {
        console.log("sin usuario");
        window.location = "/landing";
      } else {
        firebase
          .firestore()
          .collection("users")
          .doc(authUser.uid)
          .get()
          .then((doc) => {
            setUser(doc.data());
            setReceiveNews(doc.data().news ? true : false);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      }
    });
  }, []);


  const toggleNews = () => {
    setNewsLoading(true);
    var val = receiveNews;
    // console.log("press");
    firebase
      .firestore()
      .collection("users")
      .doc(user.uid)
      .set(
        {
          news: !val,
        },
        { merge: true }
      )
      .then(() => {
        // console.log("update");
        setReceiveNews(!val);
        setNewsLoading(false);
        // setChange(!change);
      })
      .catch((error) => {
        setNewsLoading(false);
        console.error(error);
        alert(error.message);
      });
  };
  const submitForgot = (event) => {
    event.preventDefault();
    setLoading(true);

    firebase
      .auth()
      .sendPasswordResetEmail(user.email)
      .then(() => {
        setLoading(false);
        alert(
          "Enviamos un correo para restablecer tu contraseña. Puede tardar un poco en llegar. Recuerda revisar en el folder de spam."
        );
      })
      .catch(function (error) {
        setLoading(false);
        var errorCode = error.code;
        var errorMessage = error.message;
        switch (errorCode) {
          case "auth/wrong-password":
            alert("Correo electrónico o contraseña incorrecta.");
            break;
          case "auth/user-not-found":
            alert("No se ha encontrado el usuario.");
            break;
          case "auth/too-many-requests":
            alert(
              "Has excedido el límite de intentos. Favor de intentar de nuevo más tarde."
            );
            break;
          default:
            alert("Ha ocurrido un error desconocido.");
            break;
        }
        console.log(errorCode, errorMessage);
      });
  };

  return (
    <div>
      {loading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <div>
          <Container >
            <h4 className="font-weight-bold text-black mt-2"
            >Ajustes</h4>

            <div className="mt-4">
              {/* <h4 className="text-secondary">¡Hola {user.nombre}!</h4> */}

              <h5 className="text-black mb-3">Cuenta</h5>
              <p><span className="font-weight-bold">Correo:</span> {user.email}</p>

              <h5 className="text-black mb-3">Contraseña</h5>
              <div className="mt-3">
                <Button
                  variant="outline-danger"
                  className="btn br-10"
                  onClick={submitForgot}
                >
                  Restablecer contraseña.
                </Button>
              </div>
            </div>

            <hr />

            <div className="mt-2">
              <h5 className="text-black mb-3">Noticias</h5>
              <p>Recibe novedades, noticias, eventos, promociones, ofertas de mariiachi.com y patrocinadores.</p>
              <div className="d-flex flex-row align-items-center justify-content-start">
                {newsLoading ?
                  <div
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Spinner animation="border" variant="warning" />
                  </div>
                  :
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      toggleNews();
                    }}
                    variant="warning"
                    style={{
                      width: "2rem",
                      height: "1rem",
                      backgroundColor: receiveNews ? "#f5803e" : "gray",
                      borderRadius: "15px",
                      padding: "0px",
                      display: "flex",
                      direction: "row",
                      justifyContent: receiveNews
                        ? "flex-end"
                        : "flex-start",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        height: "100%",
                        backgroundColor: "white",
                        borderRadius: "15px",
                        padding: "0px",
                        margin: "0px",
                      }}
                    />
                  </Button>
                }


                <div className="ml-2 d-flex flex-row align-items-center text-center text-secondary">
                  {/* {receiveNews ? "activa" : "inactiva"}   */}
                  Me gustaría recibir noticias y novedades
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
    </div>
  );
}

export default Ajustes;
