import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useState } from "react";
import { Badge, Card, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../App.css";

import { UseUser } from "../Context/userContext";

function ArtistDisp({ children, artist }) {
  const { user, setUserContext } = UseUser();
  const [fav, setFav] = useState(false);

  // useEffect(()=> {
  var width = window.innerWidth;
  // console.log("www", width);
  // },[])

  const toggleFav = () => {
    var fanArr = [];
    if (!fav) {
      firebase
        .firestore()
        .collection("artists")
        .doc(artist.uid)
        .get()
        .then((doc) => {
          if (doc.data().fans != null && doc.data().fans != undefined) {
            fanArr = doc.data().fans;
          }
          if (fanArr.includes(user.uid) == false) {
            fanArr.push(user.uid);
          }
        })
        .then(() => {
          firebase.firestore().collection("artists").doc(artist.uid).set(
            {
              fans: fanArr,
              fanCount: fanArr.length,
            },
            { merge: true }
          );
        })
        .then(() => {
          var favArr = [];
          firebase
            .firestore()
            .collection("users")
            .doc(user.uid)
            .get()
            .then((doc) => {
              if (doc.data().favs != null && doc.data().favs != undefined) {
                favArr = doc.data().favs;
              }
              if (!favArr.includes(artist.uid)) {
                favArr.push(artist.uid);
              }
            })
            .then(() => {
              firebase.firestore().collection("users").doc(user.uid).set(
                {
                  favs: favArr,
                },
                { merge: true }
              );
              setFav(true);
              //   alert("guarddao");
              // setFans(fanArr);
            });
        })
        .catch((er) => {
          console.log(er);
          alert(er);
        });
    } else {
      firebase
        .firestore()
        .collection("artists")
        .doc(artist.uid)
        .get()
        .then((doc) => {
          if (doc.data().fans != null && doc.data().fans != undefined) {
            fanArr = doc.data().fans;
            const index = fanArr.indexOf(user.uid);
            if (index > -1) {
              fanArr.splice(index, 1);
            }
          }
        })
        .then(() => {
          firebase.firestore().collection("artists").doc(artist.uid).set(
            {
              fans: fanArr,
              fanCount: fanArr.length,
            },
            { merge: true }
          );
        })
        .then(() => {
          var favArr = [];
          firebase
            .firestore()
            .collection("users")
            .doc(user.uid)
            .get()
            .then((doc) => {
              if (doc.data().favs != null && doc.data().favs != undefined) {
                favArr = doc.data().favs;

                const index2 = favArr.indexOf(artist.uid);
                if (index2 > -1) {
                  favArr.splice(index2, 1);
                }
                setFav(false);
                // setFans(fanArr);
              }
            })
            .then(() => {
              firebase.firestore().collection("users").doc(user.uid).set(
                {
                  favs: favArr,
                },
                { merge: true }
              );
            });
        })
        .catch((er) => {
          console.log(er);
          alert(er);
        });
    }
  };

  if (width > 750) {
    return (
      <Col
        xs={12}
        sm={12}
        md={6}
        lg={4}
        className=" d-flex align-items-center justify-content-center mt-2 mb-2"
      >
        <Link
          // variant="link"
          className="h-100 w-100 d-flex flex-column align-items-center justify-content-center"
          style={{ textDecoration: "none" }}
          to={`/artist/${artist.uid}`}
        >
          <Card className="h-100 w-100 p-2 br-15 shadow-lg hvr-warning border-none">
            {children}
            {/* <p className="text-secondary mb-2" >
              {artist.state}, {artist.country}
            </p>
   */}
            <div className="w-100 d-flex align-items-center justify-content-center">
              <Container
                className="p-0"
                style={{
                  height: "13rem",
                  width: "100%",
                }}
              >
                <img
                  src={
                    artist.pp
                      ? artist.pp
                      : "https://firebasestorage.googleapis.com/v0/b/mariiachi-dca12.appspot.com/o/mariiachi(chido).png?alt=media&token=dcc361ad-4a3d-4527-b482-c4df7ae80042"
                  }
                  className="mb-2 br-10"
                  alt={artist.name}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
                <div
                  className="d-inline inline"
                  style={{
                    display: "inline",
                    position: "absolute",
                    bottom: "-0.8rem",
                    left: "0.5rem",
                    fontSize: "1.2rem",
                  }}
                >
                  {artist.verified && (
                    <Badge
                      pill
                      bg="white"
                      text="secondary"
                      className="bg-white secondaryY p-1 pl-2 pr-2 br-10 shadow-lg mr-1"
                    >
                      <i className="fas fa-check-circle text-primary text-center"></i> Artista
                      verificado
                    </Badge>
                  )}
                  {artist.fanCount != null &&
                    artist.fanCount != undefined &&
                    artist.fanCount > 0 && (
                      <Badge
                        pill
                        bg="white"
                        text="danger"
                        className="bg-white p-1 pl-2 pr-2 br-10 shadow-lg mr-1"
                      >
                        <i
                          className="fas fa-circle-star text-danger text-center"
                          style={{
                            // fontSize: "2rem",
                            // paddingTop: "3px",
                            marginBottom: "0",
                          }}
                        ></i>{" "}
                        {artist.fanCount}
                      </Badge>
                    )}
                </div>
              </Container>
            </div>

            <div className="px-2 d-flex flex-column align-items-start justify-content-between child1 w-100 ml-lg-4 ml-xl-0 text-wrap">
              <div className="text-wrap mt-3">
                <p className="text-warning fs-1 font-weight-semi-bold pt-0 text-wrap pb-0 mb-0">
                  {artist.name}
                </p>
                <div className="row px-3 pb-0 mb-0">
                  <p className="text-secondary pb-0 mb-1">
                    <i className="far fa-money-bill mr-2 " style={{ fontSize: "1rem" }}></i>
                    <span>$ </span>
                    {artist.arrange ? (
                      <span>A convenir</span>
                    ) : (
                      <span>
                        {artist.price} {artist.currency} / hr
                      </span>
                    )}
                  </p>
                </div>

                <div style={{ display: "inline" }} className="mt-0">
                  {artist.genres.map((el, ind) => {
                    if (ind <= 2) {
                      return (
                        <Badge
                          pill
                          bg="warning"
                          text="white"
                          className="mr-1 inv-hvr-warning"
                          key={ind}
                        >
                          {el}
                        </Badge>
                      );
                    } else if (ind === 3) {
                      return (
                        <Badge pill bg="warning" text="white" className="mr-1" key={ind}>
                          ...
                        </Badge>
                      );
                    }
                  })}
                </div>

                <div style={{ display: "inline" }} className="mt-0">
                  {artist.instruments.map((el, ind) => {
                    if (ind <= 2) {
                      return (
                        <Badge pill bg="danger" text="white" className="mr-1" key={ind}>
                          {el}
                        </Badge>
                      );
                    } else if (ind === 3) {
                      return (
                        <Badge pill bg="danger" text="white" className="mr-1" key={ind}>
                          ...
                        </Badge>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </Card>
        </Link>
      </Col>
    );
  } else {
    return (
      <Col
        xs={12}
        sm={12}
        md={6}
        lg={6}
        className=" d-flex align-items-center justify-content-center mt-2 mb-2"
      >
        <Link
          // variant="link"
          className="h-100 w-100 d-flex flex-column align-items-center justify-content-center"
          style={{ textDecoration: "none" }}
          to={`/artist/${artist.uid}`}
        >
          <Card className="h-100 w-100 px-1 br-15 shadow border-none">
            {children}
            <div className="w-100 d-flex flex-row align-items-center mt-2">
              <div className="px-2">
                <img
                  src={
                    artist.pp
                      ? artist.pp
                      : "https://firebasestorage.googleapis.com/v0/b/mariiachi-dca12.appspot.com/o/mariiachi(chido).png?alt=media&token=dcc361ad-4a3d-4527-b482-c4df7ae80042"
                  }
                  className="br-15 mb-2"
                  style={{
                    height: "4.5rem",
                    width: "4.5rem",
                    objectFit: "cover",
                  }}
                />
              </div>

              <div>
                <p className="text-warning font-weight-semi-bold mt-0 pt-0 mb-0">
                  {artist.verified && (
                    <i
                      className="fas fa-check-circle text-primary text-center"
                      style={{
                        // fontSize: "2rem",
                        // paddingTop: "3px",
                        marginBottom: "0",
                      }}
                    ></i>
                  )}{" "}
                  {artist.name}
                </p>

                <div className="row px-3 pb-0 mb-0">
                  <p className="text-secondary pb-0 mb-1">
                    <i className="far fa-money-bill mr-2 " style={{ fontSize: "1rem" }}></i>${" "}
                    {artist.arrange ? (
                      "A convenir"
                    ) : (
                      <span>
                        {artist.price} {artist.currency} / hr
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ position: "absolute", bottom: "5px", right: "10px" }}
            >
              {artist.fanCount != null && artist.fanCount != undefined && artist.fanCount > 0 && (
                <div>
                  <span className="text-danger mr-1">{artist.fanCount}</span>
                  <i
                    className="fas fa-circle-star text-danger text-center"
                    style={{
                      // fontSize: "2rem",
                      // paddingTop: "3px",
                      marginBottom: "0",
                    }}
                  ></i>
                </div>
              )}
            </div>
            {/* </div> */}

            {/* <div className="px-2 d-flex flex-column align-items-start justify-content-between child1 w-100 ml-lg-4 ml-xl-0">
              <div>
               
                <div style={{ display: "inline" }} className="mt-0">
                  {artist.genres.map((el, ind) => {
                    if (ind <= 3) {
                      return (
                        <Badge
                          pill
                          bg="warning"
                          text="white"
                          className="mr-1"
                          key={ind}
                        >
                          {el}
                        </Badge>
                      );
                    } else if (ind === 4) {
                      return (
                        <Badge
                          pill
                          bg="warning"
                          text="white"
                          className="mr-1"
                          key={ind}
                        >
                          ...
                        </Badge>
                      );
                    }
                  })}
                </div>

                <div style={{ display: "inline" }} className="mt-0">
                  {artist.instruments.map((el, ind) => {
                    if (ind <= 3) {
                      return (
                        <Badge
                          pill
                          bg="danger"
                          text="white"
                          className="mr-1"
                          key={ind}
                        >
                          {el}
                        </Badge>
                      );
                    } else if (ind === 4) {
                      return (
                        <Badge
                          pill
                          bg="danger"
                          text="white"
                          className="mr-1"
                          key={ind}
                        >
                          ...
                        </Badge>
                      );
                    }
                  })}
                </div>
              </div>

              {artist.fanCount != null &&
                artist.fanCount != undefined &&
                artist.fanCount > 0 && (
                  <p className="text-secondary pb-0 mb-2 mt-1">
                    Favorito de{" "}
                    <span className="text-danger">{artist.fanCount}</span>{" "}
                    usuarios
                  </p>
                )}
            </div> */}
          </Card>
        </Link>
      </Col>
    );
  }
}

export default ArtistDisp;
