import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useEffect, useRef, useState } from "react";
import { Badge, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../App.css";
import ArtistCardVert from "../../Components/ArtistCardVert";

function GenreSection({ loc, genre }) {
  const [loading, setLoading] = useState(true);
  const [popularArtists, setPopulars] = useState([]);

  const [errorr, setErr] = useState("");
  const ref = useRef(null);
  useEffect(() => {
    const getPopulars = async (location) => {
      console.log("loc; ", location);
      var favArr = [];
      await firebase
        .firestore()
        .collection("artists")
        .where("blocked", "!=", true)
        .where("country", "==", location.country)
        .where("state", "==", location.state)
        .where("status", "==", true)
        .where("genres", "array-contains-any", genre)
        .orderBy("blocked", "desc")
        .orderBy("fanCount", "desc")
        .limit(10)
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            favArr.push(doc.data());
          });
        })
        .then(() => {
          setPopulars(favArr);
          setLoading(false);
        })
        .catch((er) => {
          setLoading(false);
          console.log(er);
          setErr(er);
        });
    };
    if (loc != undefined && loc != null) {
      getPopulars(loc);
    }
  }, [loc]);

  return (
    <div>
      <div className="d-xs-none d-sm-none d-md-block">
        <div
          className="w-100 d-flex mb-3"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <Button
            variant="warning"
            style={{
              height: "2rem",
              width: "2rem",
              borderRadius: "50%",
              marginRight: "1.5rem",
              marginLeft: "0.5rem",
            }}
            onClick={() => {
              ref.current.scrollLeft += 130;
            }}
          >
            <i className="fas fa-chevron-right d-flex align-items-center justify-content-center"></i>
          </Button>
          <Button
            variant="warning"
            style={{
              height: "2rem",
              width: "2rem",
              borderRadius: "50%",
            }}
            onClick={() => {
              ref.current.scrollLeft += -130;
            }}
          >
            <i className="fas fa-chevron-left d-flex align-items-center justify-content-center"></i>
          </Button>
          <Link
            className="text-warning font-weight-semi-bold mr-4"
            to="/search"
            style={{ fontSize: "1rem" }}
          >
            Ver más
          </Link>
        </div>
      </div>
      {loading ? (
        <div className=" d-flex align-items-center justify-content-center py-5">
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <div
          className="w-100 d-flex align-items-center justify-content-center sbar"
          style={{ height: "24rem" }}
        >
          <div className="w-100 smooth" style={{ overflowX: "scroll" }} ref={ref}>
            <div
              className="d-flex flex-row align-items-center justify-content-center pb-4 px-3"
              style={{
                width: "max-content",
                minWidth: "100%",
                overflowY: "hidden",
              }}
            >
              {errorr != "" ? (
                <p>{errorr}</p>
              ) : popularArtists.length > 0 ? (
                popularArtists.map((artiist, ind) => {
                  return (
                    <div className="d-flex flex-row" key={ind}>
                      <p
                        className="mr-1 font-weight-semi-bold"
                        // style={{ zIndex: 9 }}
                      >
                        <Badge
                          bg="warning"
                          className="text-white rounded-circle d-flex align-items-center justify-content-center"
                          style={{
                            height: "1.2rem",
                            width: "1.2rem",
                            zIndex: 0,
                          }}
                        >
                          {ind + 1}
                        </Badge>
                      </p>
                      <ArtistCardVert artist={artiist} />
                    </div>
                  );
                })
              ) : (
                <p className="px-2 w-100 text-center font-weight-semi-bold">
                  ¡Oh mira! No hay músicos para en tu zona, aquí se muestran los más favoritos.
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default GenreSection;
