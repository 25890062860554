import React, { useEffect, useState } from "react";
import "../../App.css";
import "../../assets/css/theme.css";

import "animate.css/animate.min.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { Button, ButtonGroup, Card, Form, Spinner } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
function AuthComponent() {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [regis, setRegis] = useState();
  const [forgot, setForgot] = useState(false);

  const [receiveNews, setNews] = useState(true);

  const [email, setEmail] = useState("");
  const [surname, setSurname] = useState("");
  const [name, setName] = useState("");
  const [pw, setPw] = useState("");
  const [pw2, setPw2] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [errmsg, setErr] = useState("");

  const submitLogin = (event) => {
    event.preventDefault();
    setLoading(true);
    console.log("Logging in user");
    firebase
      .auth()
      .signInWithEmailAndPassword(email, pw)
      .then(() => {
        console.log("success login");
        navigate("/");
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);

        var errorCode = error.code;
        var errorMessage = error.message;

        var msg = "";
        switch (errorCode) {
          case "auth/wrong-password":
            alert("Correo electrónico o contraseña incorrecta.");
            msg = "Correo electrónico o contraseña incorrecta.";
            break;
          case "auth/user-not-found":
            alert("No se ha encontrado el usuario.");
            msg = "No se ha encontrado el usuario.";
            break;
          case "auth/too-many-requests":
            alert("Has excedido el límite de intentos. Favor de intentar de nuevo más tarde.");
            msg = "Has excedido el límite de intentos. Favor de intentar de nuevo más tarde.";
            break;
          default:
            alert("Ha ocurrido un error desconocido.");
            msg = "Ha ocurrido un error desconocido.";
            break;
        }
        alert(msg);
        console.log(errorCode, errorMessage);
      });
  };

  const submitRegistro = () => {
    setLoading(true);

    if (pw == pw2) {
      console.log(name, surname, email);
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, pw)
        .then((udata) => {
          // console.log(udata.user);
          var uid = udata.user.uid;
          firebase.firestore().collection("users").doc(uid).set({
            email: email,
            nombre: name,
            uid: uid,
            news: receiveNews,
            apellido: surname,
          });
        })
        .then(() => {
          console.log("navigate", email, name, receiveNews, surname);
          navigate("/verify-email");
        })
        // .catch((error) => {
        //   console.error(error);
        //   setLoading(false);
        // });
        .catch(function (error) {
          var errorCode = error.code;
          // var errorMessage = error.message;
          switch (errorCode) {
            case "auth/email-already-in-use":
              alert("Ya existe un usuario con este correo electrónico.");
              break;
            case "auth/email-already-exists":
              alert("Ya existe un usuario con este correo electrónico.");
              break;
            case "auth/weak-password":
              alert("Contraseña invalida. Usa al menos 6 caracteres.");
              break;
            default:
              alert("Ha ocurrido un error desconocido.");
              break;
          }
          // console.log(errorCode, errorMessage);
          setLoading(false);
          alert(errmsg);
        });
    } else {
      setLoading(false);
      alert("Las contraseñas no son iguales");
    }
  };

  const submitForgot = (event) => {
    event.preventDefault();

    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        alert(
          "Enviamos un correo para restablecer tu contraseña. Puede tardar un poco en llegar. Recuerda revisar en el folder de spam."
        );
      })
      .catch(function (error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        switch (errorCode) {
          case "auth/wrong-password":
            alert("Correo electrónico o contraseña incorrecta.");
            break;
          case "auth/user-not-found":
            alert("No se ha encontrado el usuario.");
            break;
          case "auth/too-many-requests":
            alert("Has excedido el límite de intentos. Favor de intentar de nuevo más tarde.");
            break;
          default:
            alert("Ha ocurrido un error desconocido.");
            break;
        }
        console.log(errorCode, errorMessage);
      });
  };

  var url = window.location.href;
  // console.log("uu: ", url);
  // console.log("regis: ", url.includes("auth#regis"));

  useEffect(() => {
    console.log(document.querySelector("title"));
    document.querySelector("title").innerHTML = "Mariiachi.com | Regístrate / Inicia Sesión";
    console.log(document.querySelector("title"));
    var regisVal = url.includes("auth#regis");
    setRegis(regisVal);

    if (regis) {
      if (email != "" && name != "" && surname != "" && pw != "" && pw2 != "") {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      if (forgot) {
        if (email != "") {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      } else {
        if (email != "" && pw != "") {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      }
    }
  }, [regis, window.location.href, name, surname, pw, pw2, email, disabled, forgot, receiveNews]);

  return (
    <div className="w-100 px-3 my-4 mt-5 pb-3">
      <Card.Header className="d-flex align-items-center justify-content-center">
        {forgot == true ? (
          <Button
            variant="link"
            className="text-warning mb-3 mx-4"
            style={{
              textDecoration: "none",
              fontWeight: "600",
              textAlign: "left",
              fontSize: "12pt",
            }}
            onClick={(e) => {
              e.preventDefault();
              setForgot(false);
            }}
          >
            {"< Regresar"}
          </Button>
        ) : (
          <ButtonGroup aria-label="Basic example" className="w-100">
            <Button
              variant={regis ? "outline-warning" : "warning"}
              className="btn px-2"
              onClick={() => {
                setLoading(false);
                setRegis(false);
                window.location.assign("/auth#login");
                console.log("Regis: ", regis);
              }}
            >
              Iniciar Sesión
            </Button>
            <Button
              variant={regis ? "warning" : "outline-warning"}
              className="btn px-2"
              onClick={() => {
                setLoading(false);
                setRegis(true);
                window.location.assign("/auth#regis");
                console.log("Regis: ", regis);
              }}
            >
              Registro
            </Button>
          </ButtonGroup>
        )}
      </Card.Header>

      {loading ? (
        <div className="p-5 d-flex align-items-center justify-content-center">
          <Spinner animation="border" variant="warning" />
        </div>
      ) : regis === false ? (
        <div>
          <Form onSubmit={submitLogin} autocomplete="off">
            <Form.Group controlId="formBasicEmail" className="mb-4 mt-4">
              <Form.Label className="text-secondary">Correo Electrónico</Form.Label>

              <Form.Control
                onChange={(thiss) => {
                  setEmail(thiss.target.value);
                }}
                name="email"
                value={email}
                type="email"
                placeholder="Ingresa tu correo electrónico"
              />
            </Form.Group>

            {!forgot && (
              <Form.Group controlId="formBasicPassword" className="mb-4">
                <Form.Label className="text-secondary">Contraseña</Form.Label>
                <Form.Control
                  onChange={(thiss) => {
                    setPw(thiss.target.value);
                  }}
                  name="password"
                  value={pw}
                  type="password"
                  placeholder="Ingresa tu contraseña"
                />
              </Form.Group>
            )}

            {forgot ? (
              <div className="d-flex flex-column">
                <Button className="btn btn-warning" onClick={submitForgot} disabled={disabled}>
                  Siguiente
                </Button>
              </div>
            ) : (
              <div className="d-flex flex-column">
                <Button
                  variant="link"
                  className="text-warning mb-3 "
                  style={{ textDecoration: "none", fontWeight: "500" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setForgot(true);
                    console.log("hola", forgot);
                  }}
                >
                  ¿Olvidáste tu contraseña?
                </Button>
                <Button
                  type="submit"
                  className="btn btn-warning mb-3"
                  onClick={submitLogin}
                  disabled={disabled}
                >
                  Iniciar Sesión
                </Button>
              </div>
            )}
          </Form>
        </div>
      ) : (
        <div>
          <Form autoComplete="off">
            <Form.Group controlId="formName" className="mb-2 mt-2">
              <Form.Label className="text-secondary">Nombre</Form.Label>
              <Form.Control
                onChange={(val) => {
                  setName(val.target.value);
                }}
                name="name"
                value={name}
                type="text"
                placeholder="Ingresa tu nombre"
                required
              />
            </Form.Group>

            <Form.Group controlId="formSurname" className="mb-2">
              <Form.Label className="text-secondary">Apellido</Form.Label>
              <Form.Control
                onChange={(thiss) => {
                  setSurname(thiss.target.value);
                  // console.log(newUser);
                }}
                name="apellido"
                value={surname}
                type="text"
                placeholder="Ingresa tu apellido"
                required
              />
            </Form.Group>

            <Form.Group controlId="formEmail" className="mb-2">
              <Form.Label className="text-secondary">Correo Electrónico</Form.Label>
              <Form.Control
                onChange={(thiss) => {
                  setEmail(thiss.target.value);
                }}
                name="email"
                value={email}
                type="email"
                placeholder="Ingresa tu correo electrónico"
                required
              />
            </Form.Group>

            <Form.Group controlId="formPassword" className="mb-2">
              <Form.Label className="text-secondary">Crea Contraseña</Form.Label>
              <Form.Control
                onChange={(thiss) => {
                  setPw(thiss.target.value);
                }}
                name="password"
                value={pw}
                type="password"
                placeholder="Crea tu contraseña"
                required
              />
            </Form.Group>

            <Form.Group controlId="formSecondPassword" className="mb-1">
              <Form.Label className="text-secondary">Confirmar Contraseña</Form.Label>
              <Form.Control
                onChange={(thiss) => {
                  setPw2(thiss.target.value);
                }}
                name="secondPassword"
                value={pw2}
                type="password"
                placeholder="Confirma tu contraseña"
                required
              />
            </Form.Group>

            {/* <Form.Group controlId="formBasicCheckbox" className="mb-3">
              <Form.Check
                type="checkbox"
                className="text-secondary"
                name="receive"
                label="Me gustaría recibir noticias"
                defaultChecked
                checked={receiveNews}
                onChange={(e) => {e.preventDefault(); setNews(e.target.checked); console.log(receiveNews) }}
              />
            </Form.Group> */}
            <div className="d-flex align-items-center justify-content-center py-3">
              {/* <Container className="d-flex align-items-center"> */}
              <div className="d-flex flex-row align-items-center justify-content-center">
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setNews(!receiveNews);
                  }}
                  variant="warning"
                  style={{
                    width: "2rem",
                    height: "1rem",
                    backgroundColor: receiveNews ? "#f5803e" : "gray",
                    borderRadius: "15px",
                    padding: "0px",
                    display: "flex",
                    direction: "row",
                    justifyContent: receiveNews ? "flex-end" : "flex-start",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      height: "100%",
                      backgroundColor: "white",
                      borderRadius: "15px",
                      padding: "0px",
                      margin: "0px",
                    }}
                  />
                </Button>
                <div className="ml-2 d-flex flex-row align-items-center text-center text-secondary">
                  {/* {receiveNews ? "activa" : "inactiva"}   */}
                  Me gustaría recibir noticias y novedades
                </div>
              </div>

              {/* </Container> */}
            </div>

            {/* <p className="error-display mb-0">{this.state.errorMessage}</p> */}
            <div className="text-center w-100">
              <Button
                // type="submit"
                variant="warning"
                className="btn btn-orange"
                onClick={(e) => {
                  e.preventDefault();
                  submitRegistro();
                }}
                disabled={disabled}
              >
                Registrarme
              </Button>
            </div>
          </Form>
          <div className="text-center w-100">
            <div className="mt-3">
              <Form.Label style={{ fontSize: "0.7rem" }} className="text-secondary">
                Al registrarte, indicas que has leído y <br />
                aceptas los{" "}
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/mariiachi-dca12.appspot.com/o/legal%2FTerminos%20y%20Condiciones%20mariiachi.docx.pdf?alt=media&token=b83e5dcd-f552-46fc-b428-7098a174d26c"
                  target="_blank"
                  className="text-warning"
                  rel="noopener noreferrer"
                >
                  Términos y Condiciones
                </a>{" "}
                y <br />
                la{" "}
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/mariiachi-dca12.appspot.com/o/legal%2FAviso%20de%20Privacidad%20Integral%20Mariiachi.docx.pdf?alt=media&token=ed7f1403-97ed-480d-8aac-af4ed2151a05"
                  target="_blank"
                  className="text-warning"
                  rel="noopener noreferrer"
                >
                  Política de Privacidad
                </a>
              </Form.Label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AuthComponent;
