import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import "../App.css";

import { Card } from "react-bootstrap";
const SuggestionsCard = ({ auid, country, state, instFilter }) => {
  const [queryLoading, setQueryLoading] = useState(true);
  const [artistQuery, setArtistQuery] = useState([]);
  console.log("auid", auid);
  var newFilter = [];

  if (instFilter.length >= 10) {
    for (var i = 0; i < 10; i++) {
      newFilter[i] = instFilter[i];
    }
  } else {
    newFilter = instFilter;
  }
  const getQuery = async (orr) => {
    var query = firebase
      .firestore()
      .collection("artists")
      .where("blocked", "!=", true)
      .where("status", "==", true)
      .where("country", "==", country)
      .where("state", "==", state)
      .orderBy("blocked", "desc");

    query = query.where("genres", "array-contains-any", newFilter);
    var results = [];
    await query
      .limit(5)
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          if (doc.data().uid != auid) {
            // console.log(doc.data());
            results.push(doc.data());
          }
        });
      })
      .then(() => {
        setQueryLoading(false);
        setArtistQuery(results);
      })
      .catch((er) => {
        setQueryLoading(false);
        console.log(er);
      });
  };

  useEffect(() => {
    getQuery();
  }, [auid]);
  return (
    <div>
      {artistQuery.length > 0 && (
        <Card className="br-15 mb-2 border-none shadow-lg px-0 pt-2 pb-0">
          <h5 className="text-warning px-3 mt-1 mb-2">Te puede gustar...</h5>

          {artistQuery.map((ar, ind) => {
            return (
              <Link
                className="h-100 w-100 d-flex flex-column align-items-center justify-content-center hvr-light px-2"
                style={{ textDecoration: "none", position: "relative" }}
                to={`/artist/${ar.uid}`}
                key={ind}
              >
                <div className="w-100 d-flex flex-row align-items-center mt-2">
                  <div className="px-2">
                    <LazyLoadImage
                      alt={ar.name}
                      src={
                        ar.pp
                          ? ar.pp
                          : "https://firebasestorage.googleapis.com/v0/b/mariiachi-dca12.appspot.com/o/mariiachi_profileimg%20(1).png?alt=media&token=a38c0591-b595-489d-ba94-4c9108f1d36e"
                      }
                      className="br-15 mb-2"
                      style={{
                        height: "4.5rem",
                        width: "4.5rem",
                        objectFit: "cover",
                      }}
                    />
                  </div>

                  <div>
                    <p className="text-black font-weight-semi-bold mt-0 pt-0 mb-0">
                      {ar.verified && (
                        <i className="fas fa-check-circle text-primary text-center mb-0"></i>
                      )}{" "}
                      {ar.name}
                    </p>

                    <div className="row px-3 pb-0 mb-0">
                      <p className="text-secondary pb-0 mb-1">
                        <i className="far fa-money-bill mr-2 " style={{ fontSize: "1rem" }}></i>${" "}
                        {ar.arrange ? (
                          "A convenir"
                        ) : (
                          <span>
                            {ar.price} {ar.currency} / hr
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    position: "absolute",
                    bottom: "5px",
                    right: "15px",
                  }}
                >
                  {ar.fanCount != null && ar.fanCount != undefined && ar.fanCount > 0 && (
                    <div>
                      <span className="text-danger mr-1">{ar.fanCount}</span>
                      <i className="fas fa-circle-star text-danger text-center mb-0"></i>
                    </div>
                  )}
                </div>
              </Link>
            );
          })}

          <Card.Footer className="p-0 brb-15 ">
            <div className="d-flex ccenter justify-content-around p-0 h-100 w-100">
              <Link
                to="/"
                className="text-warning py-2 hvr-light text-jakarta w-100 h-100 text-center"
              >
                Ir a Inicio
              </Link>

              <Link
                to="/search"
                className="text-warning hvr-light  py-2 text-jakarta w-100 h-100 text-center"
              >
                Buscar
              </Link>
            </div>
          </Card.Footer>
        </Card>
      )}
    </div>
  );
};

export default SuggestionsCard;
