import React, { useEffect, useState } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.png";

// import { HashLink as Link } from "react-router-hash-link";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "../App.css";
import "../assets/css/theme.css";
import "../index.css";

function NavLanding({ verify }) {
  //   const { scrollYProgress } = useViewportScroll();
  //   const [transp, setTransp] = useState(0.1);

  const [home, setHome] = useState(false);
  //   const [scrolling, setScrolling] = useState(false);
  const [uurl, seturl] = useState("");
  const [openn, setOpen] = useState(false);

  const logOut = () => {
    // if (
    //   firebase.messaging.isSupported() &&
    //   Notification.permission === "granted"
    // ) {
    //   const messaging = firebase.messaging();

    //   return this.getUserNotificationTokens()
    //     .then((doc) => {
    //       const { notificationTokens } = doc.data();

    //       this.setState({ notificationTokens: notificationTokens });

    //       return messaging.getToken({
    //         vapidKey:
    //           "BKvyHjDAbsN0A8rdrS30Xxt14lV6izv1igxhhcXvXrk06DxbHQZki97FTL_WpWiQnz8pZU522iN7Nh-rYhKwSr4",
    //       });
    //     })
    //     .then((token) => {
    //       if (token) {
    //         return this.deleteTokenFromUser(token);
    //       }
    //       return;
    //     })
    //     .then(() => {
    //       return firebase.auth().signOut();
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // } else {
    firebase.auth().signOut();
    window.location = "/landing";
    // }
  };

  useEffect(() => {
    var url = window.location.href;
    seturl(url);
    if (url.includes("/landing")) {
      setHome(true);
    } else {
      setHome(false);
    }
  }, [window.location.href]);

  return (
    <Navbar
      expand="lg"
      //   onToggle={(resp) => {
      //     if (resp === true) {
      //       setTransp(0.92);
      //     } else {
      //       if (scrollYProgress.current < 0.039) {
      //         setTransp(0.1);
      //       } else {
      //         setTransp(0.92);
      //       }
      //     }
      //   }}
      style={{
        backgroundColor: `rgba(255, 255, 255)`,
        transition: "ease-in-out, 0.5s",
        paddingBottom: "0.5rem",
        paddingTop: "0.5rem",
        // zIndex: 9999999999999999,
        //  height: "4rem"
      }}
      className="navbar-light fixed-top topp-nav pt-0"
    >
      <div className="container pr-3 pl-1">
        <Navbar.Brand>
          <Link className="navBrand navbar-brand font-weight-normal mt-0 pt-0" to="/">
            <img
              src={logo}
              alt="mariiachi"
              title="mariiachi"
              style={{
                height: "2.2rem",
                width: "auto",
                // marginRight: "-0.7rem",
                // marginTop: "-0.5rem",
              }}
            />
            {/* <span className="navbar-brand-i"></span> */}
            {/* mariiachi */}
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={(e) => {
            e.preventDefault();
            setOpen(!openn);
          }}
          className="outline-none"
        >
          <div className="h-100 d-flex align-items-center justify-content-center text-center cubic">
            {openn ? (
              <i className="fa-solid fa-xmark-large text-warning text-center fs-2"></i>
            ) : (
              <i className="fa-solid fa-bars text-center text-warning fs-2 "></i>
            )}
          </div>
        </Navbar.Toggle>

        <Navbar.Collapse id="basic-navbar-nav" className="mb-2">
          <Nav className="navbar-nav ml-auto pt-3">
            <div className="d-flex justify-content-start mt-3 pl-2">
              <a
                className="mr-3"
                target="__blank"
                href="https://www.tiktok.com/@mariiachi.com?lang=es"
              >
                {/* <img
                        //   className="process-icon"
                        href=""
                        src={fb}
                        alt=""
                        style={{ height: "2rem", width: "auto" }}
                      /> */}
                <i
                  className="fab fa-tiktok"
                  style={{
                    fontSize: "1.5rem",
                    width: "auto",
                    color: "#f5803e",
                  }}
                ></i>
              </a>
              <a
                className="mr-3"
                target="__blank"
                href="https://www.facebook.com/Mariiachi-109446741632513"
              >
                {/* <img
                        //   className="process-icon"
                        href=""
                        src={fb}
                        alt=""
                        style={{ height: "2rem", width: "auto" }}
                      /> */}
                <i
                  className="fab fa-facebook-square"
                  style={{
                    fontSize: "1.5rem",
                    width: "auto",
                    color: "#f5803e",
                  }}
                ></i>
              </a>

              <a
                className="mr-3"
                target="__blank"
                href="https://www.instagram.com/_mariiachi.com_/"
              >
                {/* <img
                        //   className="process-icon"
                        src={ig}
                        alt=""
                        style={{ height: "2rem", width: "auto" }}
                      /> */}
                <i
                  className="fab fa-instagram"
                  style={{
                    fontSize: "1.5rem",
                    width: "auto",
                    color: "#f5803e",
                  }}
                ></i>
              </a>

              {/* <a>
                      <img
                        //   className="process-icon"
                        src={yt}
                        alt=""
                        style={{ height: "2rem", width: "auto" }}
                      />
                    </a> */}
            </div>

            {home === false && (
              <Nav.Link className="nav-link-m my-2" href="https://mariiachi.com">
                <a
                  href="https://mariiachi.com"
                  className="nav-link-m"
                  style={{
                    fontSize: 13,
                    fontWeight: "600",
                  }}
                >
                  ¿Quienes somos?
                </a>
              </Nav.Link>
            )}
            {home === false && (
              <Nav.Link className="nav-link-m my-2">
                <Link
                  to="/"
                  className="nav-link-m"
                  style={{
                    fontSize: 13,
                    fontWeight: "600",
                    transition: "ease-in-out 0.3s",
                  }}
                >
                  Inicio
                </Link>
              </Nav.Link>
            )}

            {!uurl.includes("/search") && (
              <Nav.Link className="nav-link-m my-2">
                <Link
                  to="/search"
                  className="nav-link-m"
                  style={{
                    fontSize: 13,
                    fontWeight: "600",
                    transition: "ease-in-out 0.3s",
                  }}
                >
                  Buscar
                </Link>
              </Nav.Link>
            )}

            {verify != true && (
              <Nav.Link className="my-2 p-0 px-2">
                <Link
                  to="/auth#login"
                  className="btn btn-outline-warning lbtn"
                  style={{
                    fontSize: 13,
                    fontWeight: "600",
                    transition: "ease-in-out 0.3s",
                  }}
                >
                  {/* <p className="nav-link-text">App</p> */}
                  Inicia Sesión
                </Link>
              </Nav.Link>
            )}

            {verify != true && (
              <Nav.Link className="my-2 p-0 px-2">
                <Link
                  to="/auth#regis"
                  className="btn btn-warning rbtn"
                  style={{
                    fontSize: 13,
                    fontWeight: "600",
                    transition: "ease-in-out 0.3s",
                  }}
                >
                  {/* <p className="nav-link-text">App</p> */}
                  Regístrate
                </Link>
              </Nav.Link>
            )}

            {verify == true && (
              <Nav.Link className="my-2">
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    logOut();
                  }}
                  variant="outline-warning"
                  className="btn btn-outline-warning lbtn"
                  style={{
                    fontSize: 12,
                    fontWeight: "600",
                    transition: "ease-in-out 0.3s",
                  }}
                >
                  {/* <p className="nav-link-text">App</p> */}
                  Cerrar Sesión
                </Button>
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default NavLanding;
