import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useEffect } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import YoutubeBackground from "react-youtube-background";
import "../../src/App.css";
import "../App.css";
// import Footer from "../Components/Footer";
import { useNavigate } from "react-router-dom";
import { UseUser } from "../Context/userContext";

function EarlyPage() {
  const navigate = useNavigate();
  const { loc, setLocContext, user, setUserContext, setShowLoc, validLocs } = UseUser();

  useEffect(() => {
    console.log("pre", loc);
    if (loc.country != null && loc.country != "") {
      if (validLocs.countries.includes(loc.country) && validLocs.states.includes(loc.state)) {
        setShowLoc(false);
        console.log("SII NAV");

        navigate("/search");
      }
    }
  }, [loc, validLocs]);

  return (
    <div>
      {/* {user.email ? <NavApp /> : <NavbarLanding />} */}
      <YoutubeBackground
        videoId={"9Cswvab63ro"} /* default -> null */
        // className="overflow-hidden"
        className="w-100 d-stretch"
      >
        <div className="text-white blurBgWel">
          <Row className="px-4 h-100 d-flex align-items-center justify-content-center">
            <Col
              xs={12}
              md={6}
              className="d-flex align-items-center scale-down justify-content-center"
            >
              <h3 className="mt-4 text-white headerTitle lh-2 llegas">
                ¡Llegas temprano! <br />
                <br />
                {loc.state} está en etapa de pre-registro de músicos.
              </h3>
            </Col>

            <Col
              xs={12}
              md={6}
              className="d-flex align-items-start justify-content-center"
              style={{ height: "fit-content" }}
            >
              <Card className="br-30 px-0">
                <div className="scale-down h-100 d-flex flex-column align-items-center justify-content-center ">
                  {loc.country != null && loc.country != "" && (
                    <div className="px-2 text-center mt-1">
                      <Button
                        variant="link"
                        className="text-warning whitesmoke-hvr py-05 ml-2 m-0 link fs-1 pulse-lg"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowLoc(true);
                        }}
                      >
                        <p className="text-center text-location h-100 p-0 m-0">
                          <i className="fa-solid fa-location-dot mr-2"></i>
                          {loc.state}, {loc.country}
                        </p>
                      </Button>
                    </div>
                  )}
                  <div className="px-4 text-center mt-1 text-black">
                    <p className="font-weight-semi-bold text-jakarta fs-1">
                      Actualmente estamos buscando músicos en esta zona.
                      <br />
                      <p className="mt-4">¿Eres músico?</p>
                      {user.email ? (
                        <Link
                          to="/account#artista"
                          size="xl"
                          className="btn btn-outline-warning br-10 fs-1"
                          // style={{
                          //   fontSize: 12,
                          //   fontWeight: "600",
                          //   transition: "ease-in-out 0.3s",
                          // }}
                        >
                          Crea tu página de artista aquí
                        </Link>
                      ) : (
                        <Link
                          to="/auth#regis"
                          size="xl"
                          className="btn btn-outline-warning br-10 fs-1"
                          // style={{
                          //   fontSize: 12,
                          //   fontWeight: "600",
                          //   transition: "ease-in-out 0.3s",
                          // }}
                        >
                          Regístrate para crear tu página
                        </Link>
                      )}
                    </p>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </YoutubeBackground>
      {/* <Footer /> */}
    </div>
  );
}

export default EarlyPage;
