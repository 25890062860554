import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import YoutubeBackground from "react-youtube-background";
import "../App.css";
import NavbarLanding from "../Components/NavbarLanding";
import "../assets/css/theme.css";
import clarinet from "../assets/img/icons/clarinet.svg";
import steel from "../assets/img/icons/drum-steelpan.svg";
import guitarElectric from "../assets/img/icons/guitar-electric.svg";
import guitar from "../assets/img/icons/guitar.svg";
import piano from "../assets/img/icons/piano.svg";
import saxophone from "../assets/img/icons/saxophone.svg";
import violin from "../assets/img/icons/violin.svg";
import drums from "../assets/img/instruments/drums1.png";
import guitar2 from "../assets/img/instruments/guitar2.png";
import jazzist from "../assets/img/jazz.jpg";
import mariachi1 from "../assets/img/mariachi1.jpg";
import pro from "../assets/img/pronetwork.png";
import rockband from "../assets/img/rockband.jpg";
import sombrero from "../assets/img/sombrero2.png";
import ggs from "../assets/img/sponsors/logo-ggs.png";
import swirvle from "../assets/img/sponsors/logo_swirvle.png";

import ReactPlayer from "react-player";
import mac from "../assets/img/frontMac.png";
import imgF from "../assets/img/sponsors/logo-img.png";
// import artist from "../assets/img/icons/user-music.svg";
// import idcard from "../assets/img/icons/id-card.svg";
// import info from "../assets/img/icons/circle-info.svg";
// import grow from "../assets/img/icons/chart-user.svg";
import "animate.css/animate.min.css";
import { Accordion, Button, Card, Col, Container, Modal, ModalBody, Row } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Fade from "react-reveal/Fade";
import { scroller } from "react-scroll";
import Footer from "../Components/Footer";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () => console.log("!"));
  return (
    <Button
      className="title active text-400 font-weight-bold text-white"
      style={{
        backgroundColor: "rgba(0,0,0,0)",
        border: "0px",
        width: "100%",
        textAlign: "left",
        boxShadow: "none",
      }}
      onClick={decoratedOnClick}
    >
      {children}
    </Button>
  );
}

function Landing() {
  var anio = new Date();

  const [modal, setModal] = useState(false);

  useEffect(() => {
    document.querySelector("title").innerHTML = "Mariiachi.com | Sin música, no hay fiesta";
    scroller.scrollTo("top", {
      smooth: true,
    });
  }, []);

  return (
    <div style={{ overflowX: "hidden" }}>
      <NavbarLanding />

      <Modal
        show={modal}
        size="lg"
        className="my-modal"
        centered
        onHide={() => {
          setModal(false);
        }}
      >
        <ModalBody style={{ backgroundColor: "rgba(0,0,0,0) !important" }}>
          <div
            className="shadow-lg w-100 px-1 py-1 br-15 mb-3"
            style={{
              height: "50vw",
              maxHeight: "27rem",
              backgroundColor: "rgba(0,0,0,0)",
            }}
          >
            <ReactPlayer
              controls={true}
              url="https://youtu.be/f7xZgtVwzao"
              className="w-100 h-100 br-15"
            />
          </div>
        </ModalBody>
      </Modal>
      <YoutubeBackground videoId={"9Cswvab63ro"} className="w-100">
        <div
          style={{ height: "52rem", width: "100%", paddingBottom: "0.5rem" }}
          className="d-flex flex-column justify-content-end mask-dark "
        >
          <div
            className="d-flex flex-column px-3 align-items-center justify-content-center "
            style={{ width: "100%", height: "100%" }}
          >
            <div className="d-flex flex-column align-items-start justify-content-center ">
              <h1 className="text-white fs-3 px-1">
                mar<span className="text-warning">ii</span>achi.com
              </h1>
              <h2 className="text-white px-1 headerTitle welTit" style={{ fontSize: "5rem" }}>
                Sin <span className="text-warning">música</span>, <br /> no hay fiesta.
              </h2>
              <div className="w-100 px-1">
                <Link className="btn btn-orange br-10" to="/auth#regis">
                  Regístrate
                </Link>
              </div>
            </div>
          </div>

          <Container fluid className="mt-0 pt-0 p-0 mb-0 pb-0 ">
            <div className="w-100 " style={{ height: "13vw" }}>
              <div className="bgCurveTop w-100 flex-column" style={{ height: "100%" }}></div>
            </div>
          </Container>
        </div>
      </YoutubeBackground>

      <Container fluid style={{ borderTop: "none" }}>
        <div
          style={{
            backgroundColor: "white",
            height: "1.5rem",
            position: "absolute",
            top: "-1rem",
            left: "0px",
            width: "100%",
          }}
        ></div>
        <Container
          className=" mb-4 d-flex flex-column align-items-center justify-content-start pt-0 overflow-visible"
          style={{ borderTop: "none" }}
        >
          <Row className="pb-4 mb-4 pt-0 overflow-visible">
            <Col md={7} xs={12} sm={12}>
              <div style={{ position: "relative" }}>
                <div className="sombreroLanding bubble">
                  <LazyLoadImage
                    src={sombrero}
                    title="mariachi sombrero"
                    alt="sombrero"
                    className="animate__animated animate__pulse sLan"
                  />
                </div>
                <h3 className="text-warning mb-2 headerTitle fs-2">¿Qué es mariiachi?</h3>
              </div>
              <Fade up delay={900}>
                <p
                  className="text-sans-serif ls font-weight-normal pr-3 pl-3"
                  style={{ fontSize: "13pt" }}
                >
                  Inspirados por el tradicional mariachi mexicano, aquí podrás encontrar músicos de
                  cualquier genero, mariachi, pianistas, dj's, guitarristas, bateristas,
                  saxofonístas y más, para el evento o necesidad que tengas.
                  <br /> <br /> Ya sea para Serenatas, cumpleaños, fiestas, aniversarios, bodas,
                  evento de tu negocio, lo que quieras, hay mucho talento de donde escojer.
                </p>
              </Fade>

              <Fade up delay={1300}>
                <div className="pr-3 pl-3">
                  <p
                    className="text-sans-serif ls font-weight-bold text-danger"
                    style={{ fontSize: "13pt" }}
                  >
                    ¡Es gratis para todos! Para tí que buscas músicos y para los músicos.
                  </p>
                  <Link className="btn btn-block btn-pink br-10" to="/auth#regis">
                    Crea tu cuenta
                  </Link>
                  <br />
                </div>
              </Fade>
              <Fade up delay={1600}>
                <Button
                  variant="link"
                  className="link d-flex text-white td-none text-center align-items-baseline justify-content-center px-0 ml-0"
                  onClick={(e) => {
                    e.preventDefault();
                    setModal(true);
                  }}
                >
                  <i className="fa-solid fa-play playBtn d-flex align-items-center justify-content-center fs-1 text-center shadow-hvr mr-2"></i>
                  <p className="text-warning pt-0 mt-0 pb-1">
                    <strong>Ver video</strong>
                  </p>
                </Button>
              </Fade>
            </Col>

            <Col
              md={5}
              xs={12}
              sm={12}
              className="d-flex align-items-center justify-content-center  overflow-visible"
            >
              <Fade delay={900}>
                <div className="w-small px-4  pt-sm-lg" style={{ zIndex: 4 }}>
                  <div
                    className="w-100 d-flex align-items-center justify-content-center overflow-visible"
                    style={{ position: "relative", maxWidth: "90vw" }}
                  >
                    <Card
                      className="border-none shadow-lg bubble d-flex flex-column align-items-center "
                      style={{
                        height: "25rem",
                        width: "17rem",
                        borderRadius: "25px",
                      }}
                    >
                      <br />
                      <div
                        className="d-flex flex-column align-items-center justify-content-center text-center mt-2 mb-2"
                        style={{
                          height: "7rem",
                          width: "7rem",
                        }}
                      >
                        <LazyLoadImage
                          alt="mariachi"
                          src={mariachi1}
                          className="artist-img"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "20px",
                          }}
                        />
                      </div>
                      <p className="title-artist fs-1 mb-1">Mariachi Gonzalez</p>

                      <p>Nuevo León, México</p>

                      <div className="px-2 w-100 mt-2">
                        <div className="d-flex align-items-center justify-content-center">
                          <div
                            className="d-flex flex-column align-items-center justify-content-center text-center mt-2 mb-2"
                            style={{
                              height: "4rem",
                              width: "4rem",
                            }}
                          >
                            <LazyLoadImage
                              alt="banda de rock"
                              src={rockband}
                              className="artist-img"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "20px",
                              }}
                            />
                          </div>

                          <div className="h-100 d-flex flex-column align-items-start  justify-content-center ml-2">
                            <p className="title-artist mb-1 ">Banda de Rock</p>
                            <p>Nuevo León, México</p>
                          </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-center">
                          <div
                            className="d-flex flex-column align-items-center justify-content-center text-center mt-2 mb-2"
                            style={{
                              height: "4rem",
                              width: "4rem",
                            }}
                          >
                            <LazyLoadImage
                              alt="jazz"
                              src={jazzist}
                              className="artist-img"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "20px",
                              }}
                            />
                          </div>

                          <div className="h-100 d-flex flex-column align-items-start justify-content-center ml-2">
                            <p className="title-artist mb-1 ">Saxofonista</p>
                            <p>Chihuahua, México</p>
                          </div>
                        </div>
                      </div>
                    </Card>
                    <div className="blurBg3 bubble">
                      <i className="fa-solid fa-album-collection-circle-user mb-1 mt-1 waveIcon"></i>
                      <p className="text-white haz">Encuentra músicos y bandas</p>
                    </div>

                    <div className="blurBg22 bubble">
                      <i className="fa-solid fa-waveform-lines mb-1 mt-1 waveIcon"></i>
                      <p className="text-white haz">Regístrate como artista</p>
                    </div>

                    <div className="guitarArtist2 bubble">
                      <LazyLoadImage alt="guitarra" src={guitar2} className="guitar2" />
                    </div>

                    <div className="drumsArtist2 bubble">
                      <LazyLoadImage alt="batería" src={drums} className="drums2" />
                    </div>
                  </div>
                </div>
              </Fade>
            </Col>
          </Row>
        </Container>
        <div
          style={{
            backgroundColor: "white",
            height: "1.5rem",
            position: "absolute",
            bottom: "0rem",
            left: "0px",
            width: "100%",
            zIndex: 1,
          }}
        ></div>
      </Container>

      <div className="bannergeneros flex-column">
        <div className="mask-purp">
          <Container fluid className="mt-0 pt-0">
            <div className="w-100 mt-0 pt-0" style={{ height: "7vw" }}>
              <div
                className="bgCurveBottom w-100 mt-0 pt-0 flex-column"
                style={{ height: "100%" }}
              ></div>
            </div>
          </Container>
          <div className="scale-down">
            <Container className="pt-3">
              <Fade up delay={1000}>
                <h3 className="text-success mb-4 headerTitle fs-2">
                  Descubre el talento disponible
                </h3>
              </Fade>

              <Container className="">
                <Link to="/auth#regis" className="td-none">
                  <Row className="">
                    <Col
                      xs={12}
                      sm={12}
                      md={4}
                      lg={3}
                      className="d-flex flex-column align-items-center justify-content-center"
                    >
                      <div className="card mb-3 bg-transparent border-white-20 hvr-success">
                        <div className="card-body">
                          <div className="d-flex justify-content-between pb-1 border-wb-20">
                            <h2 className="fs-0 text-white mb-1">Mariachi</h2>
                          </div>
                          <div className="row py-4 justify-content-center px-5">
                            <LazyLoadImage
                              src={guitar}
                              className="iconGenre"
                              title="guitarrista"
                              alt="guitarra"
                            />
                          </div>
                          <hr className="border-wb-20 mt-0" />
                        </div>
                      </div>
                    </Col>

                    <Col
                      xs={12}
                      sm={12}
                      md={4}
                      lg={3}
                      className="d-flex flex-column align-items-center justify-content-center"
                    >
                      <div className="card mb-3 bg-transparent border-white-20 hvr-success">
                        <div className="card-body">
                          <div className="d-flex justify-content-between pb-1 border-wb-20">
                            <h2 className="fs-0 text-white mb-1">Pianista</h2>
                          </div>
                          <div className="row py-4 justify-content-center px-5">
                            <LazyLoadImage
                              src={piano}
                              className="iconGenre"
                              title="pianista"
                              alt="piano"
                            />
                          </div>
                          <hr className="border-wb-20 mt-0" />
                        </div>
                      </div>
                    </Col>

                    <Col
                      xs={12}
                      sm={12}
                      md={4}
                      lg={3}
                      className="d-flex flex-column align-items-center justify-content-center"
                    >
                      <div className="card mb-3 bg-transparent border-white-20 hvr-success">
                        <div className="card-body">
                          <div className="d-flex justify-content-between pb-1 border-wb-20">
                            <h2 className="fs-0 text-white mb-1">Guitarrista</h2>
                          </div>
                          <div className="row py-4 justify-content-center px-5">
                            <LazyLoadImage
                              src={guitarElectric}
                              className="iconGenre"
                              title="guitarrista"
                              alt="guitarra eléctrica"
                            />
                          </div>
                          <hr className="border-wb-20 mt-0" />
                        </div>
                      </div>
                    </Col>

                    <Col
                      xs={12}
                      sm={12}
                      md={4}
                      lg={3}
                      className="d-flex flex-column align-items-center justify-content-center"
                    >
                      <div className="card mb-3 bg-transparent border-white-20 hvr-success">
                        <div className="card-body">
                          <div className="d-flex justify-content-between pb-1 border-wb-20">
                            <h2 className="fs-0 text-white mb-1">Saxofonista</h2>
                          </div>
                          <div className="row py-4 justify-content-center px-5">
                            <LazyLoadImage
                              src={saxophone}
                              className="iconGenre"
                              title="saxofonísta"
                              alt="saxofón"
                            />
                          </div>
                          <hr className="border-wb-20 mt-0" />
                        </div>
                      </div>
                    </Col>

                    <Col
                      xs={12}
                      sm={12}
                      md={4}
                      lg={3}
                      className="d-flex flex-column align-items-center justify-content-center"
                    >
                      <div className="card mb-3 bg-transparent border-white-20 hvr-success">
                        <div className="card-body">
                          <div className="d-flex justify-content-between pb-1 border-wb-20">
                            <h2 className="fs-0 text-white mb-1">Marimba</h2>
                          </div>
                          <div className="row py-4 justify-content-center px-5">
                            <LazyLoadImage
                              src={steel}
                              className="iconGenre"
                              title="música tropical"
                              alt="steelpan"
                            />
                          </div>
                          <hr className="border-wb-20 mt-0" />
                        </div>
                      </div>
                    </Col>

                    <Col
                      xs={12}
                      sm={12}
                      md={4}
                      lg={3}
                      className="d-flex flex-column align-items-center justify-content-center"
                    >
                      <div className="card mb-3 bg-transparent border-white-20 hvr-success">
                        <div className="card-body">
                          <div className="d-flex justify-content-between pb-1 border-wb-20">
                            <h2 className="fs-0 text-white mb-1">Violinista</h2>
                          </div>
                          <div className="row py-4 justify-content-center px-5">
                            <LazyLoadImage
                              src={violin}
                              className="iconGenre"
                              title="violinista"
                              alt="violín"
                            />
                          </div>
                          <hr className="border-wb-20 mt-0" />
                        </div>
                      </div>
                    </Col>

                    <Col
                      xs={12}
                      sm={12}
                      md={4}
                      lg={3}
                      className="d-flex flex-column align-items-center justify-content-center"
                    >
                      <div className="card mb-3 bg-transparent border-white-20 hvr-success">
                        <div className="card-body">
                          <div className="d-flex justify-content-between pb-1 border-wb-20">
                            <h2 className="fs-0 text-white mb-1">Clarinetista</h2>
                          </div>
                          <div className="row py-4 justify-content-center px-5">
                            <LazyLoadImage
                              src={clarinet}
                              className="iconGenre"
                              title="clarinetísta"
                              alt="clarinete"
                            />
                          </div>
                          <hr className="border-wb-20 mt-0" />
                        </div>
                      </div>
                    </Col>

                    <Col
                      xs={12}
                      sm={12}
                      md={4}
                      lg={3}
                      className="d-flex flex-column align-items-center justify-content-center"
                    >
                      <div className="card mb-3 bg-transparent border-white-20 hvr-success">
                        <div className="card-body">
                          <div className="d-flex justify-content-between pb-1 border-wb-20">
                            <h5 className="fs-0 text-white mb-1">Descubre más</h5>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Link>
              </Container>
            </Container>
          </div>
          <Container fluid className="mt-0 pt-0 p-0 mb-0 pb-0 ">
            <div className="w-100 " style={{ height: "9vw" }}>
              <div className="bgCurveTop w-100 flex-column" style={{ height: "100%" }}></div>
            </div>
          </Container>
        </div>
      </div>

      <div style={{ position: "relative" }}>
        <div
          className="d-sm-block d-md-none"
          style={{
            backgroundColor: "white",
            height: "2rem",
            position: "absolute",
            top: "-0.2rem",
            left: "0px",
            width: "100%",
          }}
        ></div>

        <div
          className="d-sm-none d-md-block"
          style={{
            backgroundColor: "white",
            height: "5rem",
            position: "absolute",
            top: "2rem",
            left: "0px",
            width: "100%",
          }}
        ></div>
        <Container className="pt-1 mb-5 ">
          <div className="text-center mb-4 pt-2">
            <Fade up delay={1000}>
              <h3 className="text-warning mb-4 headerTitle ">¿Quieres publicarte como músico?</h3>
            </Fade>
          </div>
          <Row className="d-flex align-items-center justify-content-center pt-3">
            <Col
              sm={12}
              md={12}
              lg={5}
              className="d-flex flex-column align-items-center justify-content-center overflow-visible"
            >
              <Fade left delay={500}>
                <img src={mac} alt="mariiachi.com" title="mariiachi" className="w-sm-100-140" />
              </Fade>
            </Col>

            <Col
              sm={12}
              md={12}
              lg={7}
              className="d-flex px-5 flex-column align-items-center justify-content-center"
              // style={{  padding: "5rem",}}
            >
              {/* <Fade delay={1100}>
                <div className="row justify-content-center">
                  <div className="col-lg-9 px-5">
                    <div
                      className="media process-item border-dashed-left border-600 pb-5"
                    >
                      <div className="process-icon-circle">
                        <LazyLoadImage className="process-icon" src={idcard} alt="" />
                      </div>

                      <div className="media-body ml-4 ml-sm-5">
                        <h5 className="ls-1">
                          <span className="bg-white pr-3 text-danger">
                            Regístrate
                          </span>
                        </h5>
                        <p className="mb-0">
                          Crea tu cuenta con nosotros, esta cuenta te servirá
                          tanto para buscar músicos, como para crear tu página
                          de artista.
                        </p>
                      </div>
                    </div>

                    <div
                      className="
              media
              process-item
              border-dashed-left
              border-600
              border-md-left-0
              border-md-dashed-right
              pb-5    
            ">
                      <div
                        className="
                media-body
                position-relative
                ml-4 ml-sm-5 ml-md-0
                mr-md-5
              "
                      >
                        <h5 className="text-md-right">
                          <span className="bg-white pl-md-3 text-success">
                            Crea tu página de artista
                          </span>
                          <span className="process-devider border-right-0 l-0"></span>
                        </h5>
                        <p
                          className="mb-0 text-md-right"
                          data-zanim-xs='{"from":{"opacity":0},"to":{"opacity":1},"duration":1,"delay":0.3}'
                        >
                          Una vez registrad@, crea tu página de artista.
                        </p>
                      </div>
                      <div
                        className="process-icon-circle"
                      >
                        <LazyLoadImage
                          className="process-icon"
                          src={artist}
                          alt="artista"
                        />
                      </div>
                    </div>
                    <div
                      className="media process-item border-dashed-left border-600 pb-5"
                    >
                      <div
                        className="process-icon-circle"
                      >
                        <LazyLoadImage className="process-icon" src={info} alt="" />
                      </div>
                      <div className="media-body position-relative ml-4 ml-sm-5">
                        <span className="process-devider border-left-0 r-0"></span>
                        <h5 className="ls-1">
                          <span className="bg-white pr-3">
                            Ingresa tu información
                          </span>
                        </h5>
                        <p
                          className="mb-0"
                          data-zanim-xs='{"from":{"opacity":0},"to":{"opacity":1},"duration":1,"delay":0.4}'
                        >
                          Ingresar toda la información posible como artista,
                          esto ayudará a tu exposición y posibilidades de que te
                          contacten.
                        </p>
                      </div>
                    </div>
                    <div
                      className="media process-item"
                    >
                      <div
                        className="
                media-body
                position-relative
                ml-4 ml-sm-5 ml-md-0
                mr-md-5
              "
                      >
                        <h5 className="text-md-right">
                          <span className="bg-white pl-md-3 text-warning">
                            Comparte &amp; crece
                          </span>
                          <span className="process-devider border-right-0 l-0"></span>
                        </h5>
                        <p
                          className="mb-0 text-md-right"
                        >
                          ¡Listo! Comparte tu página para que las personas te
                          guarden como favorito.
                        </p>
                      </div>
                      <div
                        className="process-icon-circle"
                      >
                        <LazyLoadImage className="process-icon" src={grow} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>

              <div className="d-flex flex-column align-items-center justify-content-center mt-4">
                <h5 className="text-secondary text-sans-serif font-weight-bold mt-3">
                  ¡No tiene costo!
                </h5>
                <Link
                  className="btn rounded-capsule btn-outline-warning btn-lg mt-2"
                  to="/auth#regis"
                >
                  Crea tu cuenta ya
                </Link>
              </div> */}
              <Fade right delay={500}>
                <div
                  className="shadow-lg w-100 br-15 mb-3"
                  style={{
                    height: "50vw",
                    maxHeight: "27rem",
                    backgroundColor: "rgba(0,0,0,0)",
                  }}
                >
                  <ReactPlayer
                    controls={true}
                    url="https://youtu.be/kajz1pRCQVs"
                    className="w-100 h-100 br-15"
                  />
                </div>
              </Fade>
            </Col>
          </Row>

          <div className="d-flex flex-column align-items-center justify-content-center mt-4 pt-3">
            <br />
            <h5 className="text-secondary headerTitle ls-1 text-center">
              AGRADECEMOS A NUESTROS COLABORADORES
            </h5>

            <Row className="d-flex align-items-center justify-content-center">
              <Col className="d-flex flex-column align-items-center justify-content-center">
                <a href="https://grupogasolinerosolidaridad.com" target="_blank">
                  <LazyLoadImage alt="gasolinera solidaridad" src={ggs} style={{ width: "9rem" }} />
                </a>
              </Col>
              <Col className="d-flex flex-column align-items-center justify-content-center">
                <a href="https://imagefactorymty.com/" target="_blank">
                  <LazyLoadImage
                    alt="image factory monterrey"
                    src={imgF}
                    style={{ width: "9rem" }}
                  />
                </a>
              </Col>
              <Col className="d-flex flex-column align-items-center justify-content-center">
                <a href="https://swirvle.com/" target="_blank">
                  <LazyLoadImage alt="Swirvle" src={swirvle} style={{ width: "7rem" }} />
                </a>
              </Col>
            </Row>

            <br />
            <h5 className="text-secondary headerTitle ls-1">Medios</h5>
            <br />
            <Row className="d-flex align-items-center justify-content-center">
              <Col className="d-flex flex-column align-items-center justify-content-center">
                <a
                  href="https://www.pronetwork.mx/mariiachi-com-descubre-la-nueva-forma-para-buscar-musicos-y-bandas/"
                  target="_blank"
                >
                  <LazyLoadImage alt="Pro network" src={pro} style={{ width: "7.5rem" }} />
                </a>
              </Col>
            </Row>

            <br />
          </div>
        </Container>
      </div>

      <Container fluid className="mt-0 pt-0" style={{ backgroundColor: "#d66b00", border: "none" }}>
        <div className="w-100 mt-0 pt-0" style={{ height: "9vw" }}>
          <div
            className="bgCurveBottom w-100 mt-0 pt-0 flex-column"
            style={{ height: "100%" }}
          ></div>
        </div>
      </Container>

      <Container
        fluid
        className="py-4"
        style={{
          backgroundColor: "#d66b00",
        }}
      >
        <Container className="px-4">
          <Fade delay={900}>
            <h3 className="text-white mb-4 headerTitle">FAQ</h3>
          </Fade>

          <Accordion defaultActiveKey="0">
            <Fade up delay={1000}>
              <CustomToggle eventKey="0">
                <i className="far fa-chevron-right mr-1"></i>¿Tiene costo?
              </CustomToggle>

              <Accordion.Collapse eventKey="0">
                <div className="content active ml-4 text-200 mt-0">
                  <p
                    className="transition visible"
                    style={{ display: "block", fontSize: "1.1rem" }}
                  >
                    ¡No tiene ningún costo! Siempre habrá una versión gratuita para todos, para los
                    músicos y para los que buscan músicos.
                  </p>
                </div>
              </Accordion.Collapse>
            </Fade>

            <Fade up delay={1200}>
              <CustomToggle eventKey="1">
                <i className="far fa-chevron-right mr-1"></i>¿Cuáles son las áreas de servicio?
              </CustomToggle>

              <Accordion.Collapse eventKey="1">
                <div className="content active ml-4 text-200 mt-0">
                  <p
                    className="transition visible"
                    style={{ display: "block", fontSize: "1.1rem" }}
                  >
                    Las áreas de servicio dependen 100% de los músicos que se inscriban. No estamos
                    limitados por la plataforma. Si no encuentras músicos en tu zona es porque
                    todavía no se han inscrito aquí. ¡Invítalos!
                  </p>
                </div>
              </Accordion.Collapse>
            </Fade>

            <Fade up delay={1500}>
              <CustomToggle eventKey="2">
                <i className="far fa-chevron-right mr-1"></i>¿Me puedo registrar aunque no sea de
                México?
              </CustomToggle>

              <Accordion.Collapse eventKey="2">
                <div className="content active ml-4 text-200 mt-0">
                  <p
                    className="transition visible"
                    style={{ display: "block", fontSize: "1.1rem" }}
                  >
                    ¡Claro! Esta es una plataforma global. ¡Eres bienvenido! Tu eliges donde es que
                    buscas o publicas tu servicio, no importa que país sea, puedes buscar músicos o
                    publicarte como músico en tu zona.
                  </p>
                </div>
              </Accordion.Collapse>
            </Fade>

            <Fade up delay={1600}>
              <CustomToggle eventKey="3">
                <i className="far fa-chevron-right mr-1"></i>¿Qué generos musicales pueden
                inscribirse?
              </CustomToggle>

              <Accordion.Collapse eventKey="3">
                <div className="content active ml-4 text-200 mt-0">
                  <p
                    className="transition visible"
                    style={{ display: "block", fontSize: "1.1rem" }}
                  >
                    Todos los géneros musicales son bienvenidos, aunque nos inspiramos en el
                    mariachi para nombrar la plataforma, cualquier músico y género es bienvenido,
                    aquí podrás encontrar de todo.
                  </p>
                </div>
              </Accordion.Collapse>
            </Fade>
          </Accordion>
        </Container>
      </Container>

      <Footer />
    </div>
  );
}

export default Landing;
