import "../../App.css";
import NavApp from "../../Components/NavApp";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Container,
  Carousel,
  Accordion,
  Spinner,
} from "react-bootstrap";

function Soporte() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});

  useEffect(() => {
    firebase.auth().onAuthStateChanged((authUser) => {
      if (!authUser) {
        console.log("sin usuario");
        window.location = "/landing";
      } else {
        firebase
          .firestore()
          .collection("users")
          .doc(authUser.uid)
          .get()
          .then((doc) => {
            setUser(doc.data());
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      }
    });
  }, []);

  const submitForgot = (event) => {
    event.preventDefault();
    setLoading(true);

    firebase
      .auth()
      .sendPasswordResetEmail(user.email)
      .then(() => {
        setLoading(false);
        alert(
          "Enviamos un correo para restablecer tu contraseña. Puede tardar un poco en llegar. Recuerda revisar en el folder de spam."
        );
      })
      .catch(function (error) {
        setLoading(false);
        var errorCode = error.code;
        var errorMessage = error.message;
        switch (errorCode) {
          case "auth/wrong-password":
            alert("Correo electrónico o contraseña incorrecta.");
            break;
          case "auth/user-not-found":
            alert("No se ha encontrado el usuario.");
            break;
          case "auth/too-many-requests":
            alert(
              "Has excedido el límite de intentos. Favor de intentar de nuevo más tarde."
            );
            break;
          default:
            alert("Ha ocurrido un error desconocido.");
            break;
        }
        console.log(errorCode, errorMessage);
      });
  };

  return (
    <div>
      {loading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <div>
          <Container>
            <h4 className="font-weight-bold text-black mt-2">
              Soporte</h4>

            <div className="mt-4">
              <h4 className="text-danger">¡Hola {user.nombre}!</h4>

              <p className="font-weight-semi-bold">¿Tienes algún problema o duda? <br />Contáctanos y te atenderemos lo más pronto posible.</p>

              <div className="font-weight-semi-bold">
                <span>Correo: </span>
                <a
                  href="mailto:contacto.mariiachi@gmail.com"
                  className="text-warning"
                >
                  contacto.mariiachi@gmail.com
                </a>

                <p>Redes sociales:</p>

                <div>
                  <a
                    className="mr-3 text-warning"
                    target="__blank"
                    href="https://www.facebook.com/Mariiachi-109446741632513"
                  >
                    <i
                      className="fab fa-facebook-square mr-1"
                      style={{
                        fontSize: "2rem",
                        width: "auto",
                        color: "#f5803e",
                      }}
                    ></i>Facebook
                  </a>

                  <a
                    className="mr-3 text-warning"
                    target="__blank"
                    href="https://www.instagram.com/mariiachi_music/?hl=es-la"
                  >
                    <i
                      className="fab fa-instagram mr-1"
                      style={{
                        fontSize: "2rem",
                        width: "auto",
                        color: "#f5803e",
                      }}
                    ></i>Instagram
                  </a>

                  <a
                    className="mr-3 text-warning"
                    target="__blank"
                    href="https://www.tiktok.com/@mariiachi.com?lang=es"
                  >
                    <i
                      className="fab fa-tiktok mr-1"
                      style={{
                        fontSize: "2rem",
                        width: "auto",
                        color: "#f5803e",
                      }}
                    ></i>Tiktok
                  </a>

                </div>
              </div>
            </div>

          </Container>
        </div>
      )}
    </div>
  );
}

export default Soporte;
